<template>
    <div class="">
       <div class="card-header pr-3 pt-1 pb-1 pr-3">
            <h5 class="m-0">Deleted Vaccination Records (Previous)</h5>
        </div>
        <div class="card-body table-responsive p-0">
            <table class="table table-hover">
                <tbody>
                <tr>
                    <th style="width:15%">Dose</th>
                    <th style="width:25%">Vaccine Name</th>
                    <th style="width:15%">Batch No.</th>
                    <th style="width:15%">Date</th>
                    <th>Vaccine Center</th>
                </tr>
                <tr v-for="vaccine_record in vaccination_records" :key="vaccine_record.id">
                    <td v-if="vaccine_record.vaccine_period.trim() == '1M'">First Dose</td>
                    <td v-else-if="vaccine_record.vaccine_period.trim() == '3M'">Second Dose</td>
                    <td v-else-if="vaccine_record.vaccine_period.trim() == '9M'">Additional Dose</td>
                    <td>{{vaccine_record.vaccine_name}}</td>
                    <td>{{vaccine_record.batch_no}}</td>
                    <td>{{vaccine_record.vaccinated_date_np}}</td>
                    <td>{{vaccine_record.vaccinated_address}}</td>
                </tr>
                </tbody></table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props:['vaccination_records','images'],
    data(){
        return{
           response_data:'',
        }
    },
    methods:{
    },

}
</script>

