<template>
    <div class="modal fade" :id="modal_name" tabindex="-1" role="dialog" :aria-labelledby="modal_name+'Label'" aria-hidden="true">
        <div :class="modal_data.modal_size" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content rounded-0">
                <div class="modal-header ">
                    <h3 class="modal-title"  :id="modal_name+'Label'">{{header}}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['modal_data','form','api_url','header','modal_name'],
    data(){
        return{
           
        }
    },
    methods:{
       
    },
    created(){
    }
}
</script>
>