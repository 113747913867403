<template>
    <div style="padding-bottom: 10px;">
        <h1 class="h1">{{ $t('search_title') }}</h1>

        <div class="d-flex p-2 justify-content-center ">
            <div>
            <input class="radio-group" type="radio" id="dob-search" name="search_type" value="registration"
                    v-model="searchType"><b>&nbsp;
            {{ $t('registration_no') }}</b> &nbsp; &nbsp;

            </div>
            <div>
            <input class="radio-group" type="radio" id="identity-search" name="search_type" value="identity"
                    v-model="searchType">&nbsp;
            <b>{{ $t('identity_type') }}, {{ $t('mobile')}}</b>

            </div>
        </div>

        <div class="d-flex flex-column">
            <div class="form-group" :class="{ 'has-error': $v.reg_no.$error }" v-show="isRegistrationSearch">
                <input type="number" min="0" id="reg_no" name="reg_no" required="required" v-model="reg_no">
                <label for="reg_no" class="control-label">{{ $t('registration_no') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.reg_no.required">{{ $t('field_required') }}</div>
            </div>

            <div class="date-type" v-show="isRegistrationSearch" >
                <label class="control-label">{{ $t('dat_type') }}</label>
                <input class="radio-group" type="radio" id="bs" name="bs" value="1" v-model="dob_type"
                    v-on:change="toggleDob()"   > {{ $t('bs') }}&nbsp;&nbsp;
                <input class="radio-group" type="radio" id="ad" name="ad" value="2" v-model="dob_type"
                     v-on:change="toggleDob()"  > {{ $t('ad') }}
            </div>

            <div class="form-group mt-3" :class="{ 'has-error': $v.date_of_birth.$error }" v-show="isRegistrationSearch">
                <input type="text" id="date_of_birth" name="date_of_birth" required="required"
                      v-mask="'####-##-##'" v-model="date_of_birth">
                <label for="date_of_birth" class="control-label">{{ $t('dob') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.date_of_birth.required">{{ $t('field_required') }}</div>
            </div>

            
            <div class="form-group" v-show="!isRegistrationSearch">
                <select id="identity_type" name="identity_type" v-model="identity_type">
                    <option value="" disabled>{{ $t('select_options') }}</option>
                    <option value="Citizenship">{{ $t('citizenship') }}</option>
                    <option value="National ID">{{ $t('national_id') }}</option>
                    <option value="Driving License">{{ $t('driving_license') }}</option>
                    <option value="Election card">{{ $t('voters_card') }}</option>
                    <option value="Passport">{{ $t('passport') }}</option>
                    <option value="Disability ID Card">{{ $t('disability_id') }}</option>
                    <option value="Others">{{ $t('other') }}</option>
                </select>
                <label for="identity_type" class="control-label">{{ $t('identity_type') }}</label><i
                class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.identity_type.required">{{
                        $t('field_required')
                    }}
                </div>
            </div>

            <div class="form-group" v-show="!isRegistrationSearch">
                <input type="text" id="identity_no" name="identity_no"
                       v-model="identity_no" required="required"/>
                <label for="identity_no" class="control-label">{{ $t('identification_no') }} ( e.g. 1234-56)</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.identity_no.required">{{
                        $t('field_required')
                    }}
                </div>
            </div>

            <div class="form-group" v-show="!isRegistrationSearch">
                <input type="text" id="mobile" name="mobile"
                       v-model="mobile" required="required"/>
                <label for="mobile" class="control-label">{{ $t('mobile') }} </label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.mobile.required">{{
                        $t('field_required')
                    }}
                </div>
            </div>
        </div>

        <div class="button-container">
            <button type="button" class="btn btn-info btn-block" v-on:click="searchData">{{ $t('search_title') }}</button>
        </div>

        <div style="margin-top: 3rem" :hidden="hidden">
            <p class="card-header bg-info text-white">{{ $t('data_found') }}</p>
            <div class="card-body">
                <table class="table table-striped" id="detailTable">
                    <tbody>
                    <tr>
                        <td>{{ $t('registration_no') }}</td>
                        <td>{{ reg_no }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('name') }}</td>
                        <td>{{ name }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('age') }}</td>
                        <td>{{ age }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('mobile') }}</td>
                        <td>{{ phone }}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="row justify-content-center align-self-center">
                    <!--                    <button class="btn btn-edit"-->
                    <!--                            @click.prevent="editData">-->
                    <!--                        {{ $t('edit') }}-->
                    <!--                    </button>-->
                    <button class="btn btn-success"
                            @click.prevent="printCard">
                        {{ $t('print_card') }}
                    </button>
                    <button class="btn btn-success mr-3"
                            @click.prevent="printVaccinationCard">
                        {{ $t('print_vaccination_report') }}
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import {required, minLength} from "vuelidate/lib/validators";
import DataConverter from "ad-bs-converter";
import {EventBus} from '../event-bus.js';

export default {
    data() {
        return {
            'date_of_birth': '',
            'dob_type': '1',
            'reg_no': '',
            'name': '',
            'age': '',
            'phone':'',
            'mobile': '',
            'encrypt_id' : '',
            'hidden': true,
            'isQrCode': false,
            'identity_type': '',
            'identity_no': '',
            'searchType': 'registration'
        }
    },
    validations() {
        if(this.searchType==='registration') {
          return {
              reg_no: {
              required,
              // minLength: minLength(8)
            },
              date_of_birth: {
              required,
            },
            identity_no: {

            },
            identity_type: {

            },
              mobile: {}
          }
        } else {
          return {
            reg_no: {

            },
              date_of_birth:{},
            identity_no: {
              required
            },
            identity_type: {
              required
            },
              mobile:{
                required
              }
          }
        }
    },
    methods: {
        toggleDob() {
            if (this.dob_type === '1') {
                this.date_of_birth = this.getTodayNpDate()
            } else if (this.dob_type === '2') {
                this.date_of_birth = this.getTodayEnDate()
            }
        },
        getTodayNpDate() {
            var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
            var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
            var ad2bs = DataConverter.ad2bs(todayDate);

            return ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day
        },
        getTodayEnDate() {
            var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

            return today.getFullYear() + '-' + (today.getMonth() + 1) + "-" + today.getDate()
        },
        scanQRCode() {
            this.isQrCode = true;
        },
        onDecode(decodedString) {
            this.reg_no = decodedString
            axios.get('/show?id=' + this.reg_no)
                .then((response) => {
                    this.handleResponse(response)
                })
        },
        printCard() {
            window.open('/report?id=' + this.encrypt_id)
        },
        printVaccinationCard() {
            window.open('/vaccinated-report?id=' + this.encrypt_id)
        },

        handleResponse(response) {
            this.isQrCode = false
            if (response.data.message === 'Data not found') {
                this.hidden = true
                this.dob_type = '1'
                this.$fire({
                    text: this.$t('data_not_found'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
            } else {
                this.$fire({
                    text: this.$t('data_found_successfully'),
                    type: "success",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                this.hidden = false
                EventBus.$emit('id-selected', this.reg_no)
                // this.$store.dispatch('id', this.reg_no);
                this.name = response.data.name
                this.age = response.data.age
                this.phone = response.data.phone
                this.encrypt_id = response.data.encrypt_id
            }
        },
        searchData() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$fire({
                    text: this.$t('error_msg'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                return false;
            }
            if(this.searchType === 'registration') {

                if (this.dob_type === '2') {
                var dob = this.date_of_birth.split("-")
                var ad2bs = DataConverter.ad2bs(dob[0] + "/" + dob[1] + "/" + dob[2]);

                var date = ad2bs.en.year + "-" +ad2bs.en.month + "-" + ad2bs.en.day;

                var np_dob = date.split("-");
                var month = np_dob[1].length === 1 ? '0'+np_dob[1] : np_dob[1];
                var day = np_dob[2].length === 1 ? '0'+np_dob[2] : np_dob[2];
                dob  = np_dob[0]+ "-" + month + "-" + day

                }
              else {
                dob = this.date_of_birth
              }
              axios.get('/api/public-client-search?search_type=registration&reg_no=' + this.reg_no + '&date_of_birth=' + dob)
                .then((response) => {
                    this.handleResponse(response)
                })
            } else {
              axios.get('/api/public-client-search?search_type=identity&identity_type=' + this.identity_type + '&identity_no=' + this.identity_no + '&mobile=' + this.mobile)
                .then((response) => {
                    this.handleResponse(response)
                })
            }

        }
    },
    computed: {
      isRegistrationSearch: function () {
          return this.searchType === 'registration'
      },
    }
}
</script>
