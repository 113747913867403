<template>
    <div class="">
       <div class="card-header pr-3 pt-1 pb-1 pr-3">
            <h5 class="m-0">Deleted Informations</h5>
        </div>
        <div class="card-body table-responsive p-0">
            <table class="table table-hover">
                <tbody>
                <tr>
                    <th style="width:15%">Identity Type</th>
                    <th style="width:15%">Identity No</th>
                    <th style="width:25%">Date of Birth</th>
                    <th style="width:15%">Remarks</th>
                    <th style="width:15%">Created At</th>

                </tr>
                <tr v-for="info in deleted_infos" :key="info.id">
                    <td>{{info.identity_type}}</td>
                    <td>{{info.identity_no}}</td>
                    <td>{{info.date_of_birth}}</td>
                    <td>{{info.remarks}}</td>
                    <td>{{new Date(info.created_at).toISOString().substr(0, 10) }}</td>
                </tr>
                </tbody></table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props:['deleted_infos'],
    data(){
        return{
           response_data:'',
        }
    },
    methods:{
    },

}
</script>

