<template>
  <div>
    <vue-good-table
      :columns="columns" :rows="rows.data"
      mode="remote"
      :sort-options="{
            enabled: false,
        }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Search Client by Registaration No., Identity No., Mobile No. Press Enter to search.'
      }"
      @on-search="onSearch"
      :isLoading.sync="isLoading"
      >
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
                  <button class="fancy-btn" @click="viewVaccineRecord(props.row)"><i class="p-1 orange fas fa-syringe"></i></button>
                  <button class="fancy-btn" @click="view(props.row)"> <i class=" p-1 blue fas fa-file-image"></i></button>
          </span>
          <span v-else-if="props.column.field == 'is_verified'">
                  <button class="fancy-btn" v-if="props.row.is_verified == 0"><i class="p-1 orange  fas fa-spinner fa-pulse"></i>Pending</button>
                  <button class="fancy-btn" v-else-if="props.row.is_verified == 1" @click="viewReport(props.row)"><i class="green p-1  fas fa-check-circle"></i>Verified</button>
                  <button class="fancy-btn" v-else-if="props.row.is_verified == 2"><i class="p-1  red  fas fa-times"></i>Disapproved</button>
                  <button class="fancy-btn" v-else><i class="p-1  blue  fas fa-sync fa-spin"></i>Reverification</button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>

      </template>

      </vue-good-table>
        <view-modal  :modal_data="this.modal_data" :form="this.form"  header="Client Details">
              <div class="card  rounded-0 border-0 shadow-0 m-0">
                <div class="card-header pr-3 pt-1 pb-1 pr-3">
                      <h5 class="m-0">Client Information</h5>
                    </div>
                    <div class="card-body table-responsive p-0">
                      <table class="table table-hover">
                        <tbody>

                        <tr>
                            <td class="w-35">{{ $t('name') }}</td>
                            <td>
                              <input type="text" class="invisible-input" v-model="form.name">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('caste') }}</td>
                            <td v-if="form.caste == '0'">{{ $t('dalit') }}</td>
                            <td v-else-if="form.caste == '1'">{{ $t('janajati') }}</td>
                            <td v-else-if="form.caste == '2'">{{ $t('madhesi') }}</td>
                            <td v-else-if="form.caste == '3'">{{ $t('muslim') }}</td>
                            <td v-else-if="form.caste == '4'">{{ $t('brahmn_chettri') }}</td>
                            <td v-else-if="form.caste == '5'">{{ $t('other') }}</td>
                            <td v-else-if="form.caste == '6'">{{ $t('dont_mention') }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('nationality') }}</td>
                            <td>{{ form.nationality }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('gender') }}</td>

                            <td v-if="form.gender === 1">{{this.$t('male')}}</td>
                            <td v-else-if="form.gender === 2">{{this.$t('female')}}</td>
                            <td v-else>{{this.$t('other')}}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('dob') }}</td>
                            <td>{{ form.date_of_birth }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('age') }}</td>
                            <td>{{ form.age }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('mobile') }}</td>
                            <td>{{ form.phone }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('occupation') }}</td>
                            <td v-if="form.occupation == '0'">{{ $t('other') }}</td>
                            <td v-else-if="form.occupation == '33'">{{ $t('foreign_employment') }}</td>
                            <td v-else-if="form.occupation == '1'">{{ $t('airlines') }}</td>
                            <td v-else-if="form.occupation == '2'">{{ $t('army') }}</td>
                            <td v-else-if="form.occupation == '3'">{{ $t('bank') }}</td>
                            <td v-else-if="form.occupation == '4'">{{ $t('commercial_facilities') }}</td>
                            <td v-else-if="form.occupation == '5'">{{ $t('daily_wage_worker') }}</td>
                            <td v-else-if="form.occupation == '6'">{{ $t('farmer') }}</td>
                            <td v-else-if="form.occupation == '7'">{{ $t('government_employee') }}</td>
                            <td v-else-if="form.occupation == '8'">{{ $t('grocery') }}</td>
                            <td v-else-if="form.occupation == '9'">{{ $t('health_care') }}</td>
                            <td v-else-if="form.occupation == '10'">{{ $t('health_professional') }}</td>
                            <td v-else-if="form.occupation == '11'">{{ $t('it_and_communication') }}</td>
                            <td v-else-if="form.occupation == '12'">{{ $t('journalist_media') }}</td>
                            <td v-else-if="form.occupation == '13'">{{ $t('manufacturing') }}</td>
                            <td v-else-if="form.occupation == '14'">{{ $t('ngo_ingo') }}</td>
                            <td v-else-if="form.occupation == '15'">{{ $t('online_business') }}</td>
                            <td v-else-if="form.occupation == '16'">{{ $t('police') }}</td>
                            <td v-else-if="form.occupation == '17'">{{ $t('Security_person') }}</td>
                            <td v-else-if="form.occupation == '18'">{{ $t('social_service') }}</td>
                            <td v-else-if="form.occupation == '19'">{{ $t('student') }}</td>
                            <td v-else-if="form.occupation == '20'">{{ $t('teacher_professor') }}</td>
                            <td v-else-if="form.occupation == '21'">{{ $t('tourist') }}</td>
                            <td v-else-if="form.occupation == '22'">{{ $t('transport_sector') }}</td>
                            <td v-else-if="form.occupation == '23'">{{ $t('unemployed') }}</td>
                            <td v-else-if="form.occupation == '31'">{{ $t('diplomat') }}</td>
                            <td v-else-if="form.occupation == '32'">{{ $t('veterinarians') }}</td>


                        </tr>
                        <tr>
                            <td>{{ $t('identity_type') }}</td>
                            <td>{{ form.identity_type }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('identification_no') }}</td>
                            <td>{{ form.identity_no }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('issue_org') }}</td>
                            <td>{{ form.issue_org }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('issue_date') }}</td>
                            <td>{{ form.issue_date }}</td>
                        </tr>
                        <tr v-if="form.municipality">
                            <td>{{ $t('address') }}</td>
                            <td >
                                Province :{{ form.municipality.province_id }} <br>
                                District {{ form.municipality.district_name }} <br>
                                Municipality {{ form.municipality.municipality_name }} <br>
                                Ward No :{{ form.ward }} <br>
                            </td>
                        </tr>
                        <tr>

                            <td>{{ $t('medical_condition') }}</td>
                            <td> <p v-if="medical_condition.includes(1) || medical_condition.includes('1')">{{ $t('cancer') }} </p>
                            <p v-if="medical_condition.includes(2) || medical_condition.includes('2')">{{ $t('chronic_kidney_disease') }}</p>
                            <p v-if="medical_condition.includes(3) || medical_condition.includes('3')">{{ $t('chronic_resp_disease') }}</p>
                            <p v-if="medical_condition.includes(4) || medical_condition.includes('4')">{{ $t('cardiovascular_disease') }}</p>
                            <p v-if="medical_condition.includes(5) || medical_condition.includes('5')">{{ $t('diabetes') }}</p>
                            <p v-if="medical_condition.includes(7) || medical_condition.includes('7')">{{ $t('pregnant') }}</p>
                            <p v-if="medical_condition.includes(8) || medical_condition.includes('8')">{{ $t('blood_pressure') }}</p>
                            <p v-if="medical_condition.includes(9) || medical_condition.includes('9')">{{ $t('nerve_disease') }}</p>
                            <p v-if="medical_condition.includes(10) || medical_condition.includes('10')">{{ $t('none_of_above') }}</p>
                            <p v-if="medical_condition.includes(11) || medical_condition.includes('11')">{{ $t('tb') }}</p>
                            <p v-if="medical_condition.includes(21) || medical_condition.includes('21')">{{ $t('normal_health') }}</p>
                            <p v-if="medical_condition.includes(22) || medical_condition.includes('22')">{{ $t('mental_disease') }}</p>
                            <p v-if="medical_condition.includes(23) || medical_condition.includes('23')">{{ $t('covid') }}</p>
                            <p v-if="medical_condition.includes(31) || medical_condition.includes('31')">{{ $t('hiv_aids') }} </p>
                            </td>
                        </tr>


                        </tbody>
                      </table>
                    </div>

                    <div class="card-header pr-3 pt-1 pb-1 pr-3">
                      <h5 class="m-0">Vaccination Records</h5>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover">
                            <tbody>
                            <tr>
                                <th style="width:15%">Dose</th>
                                <th style="width:25%">Vaccine Name</th>
                                <th style="width:15%">Batch No.</th>
                                <th style="width:15%">Date</th>
                                <th>Vaccine Center</th>
                            </tr>
                            <tr v-for="vaccine_record in form.vaccination_record" :key="vaccine_record.id">
                              <td v-if="vaccine_record.vaccine_period.trim() == '1M'">First Dose</td>
                              <td v-else-if="vaccine_record.vaccine_period.trim() == '3M'">Second Dose</td>
                              <td v-else-if="vaccine_record.vaccine_period.trim() == '9M'">Additional Dose</td>
                            <td v-else-if="vaccine_record.vaccine_period.trim() == '12M'">Second Additional Dose</td>

                                <td>{{vaccine_record.vaccine_name}}</td>
                                <td>{{vaccine_record.batch_no}}</td>
                                <td>{{vaccine_record.vaccinated_date_np}}</td>
                                <td>{{vaccine_record.vaccinated_address}}</td>
                            </tr>
                            </tbody></table>
                    </div>
                    <!-- /.card-body -->

                </div>
        </view-modal>
        <view-modal-slide   :modal_data="this.modal_data" :form="this.form" :refresh='refresh'   header="Document Slider">
        </view-modal-slide>
  </div>
</template>

<script>
import PaginationWrapper from './Pagination/PaginationWrapper.vue';

import ViewModal from './modal/view-modal.vue';
import ViewModalSlide from './modal/slider.vue';

import axios from 'axios';
export default {
  components:{
    PaginationWrapper,
        ViewModal,
        ViewModalSlide
    },
  data(){
    return {
      isLoading: false,
        paginate_param : '',
        citizen_param : '',
      totalRecords: 0,
      refresh: 0,
      images:[],
      medical_condition:[],
      names:'',
      modal_data:{
        modal_size:'modal-lg',
      },
      form: new Form({
              id:'',
              name:'',
              caste: '',
              gender: '',
              nationality: '',
              occupation: '',
              province_id: '',
              district_i: '',
              municipality_id: '',
              medical_condition: [],
              district_name: '',
              municipality_name: '',
              ward:'',
              phone:'',
              identity_no:'',
              identity_type:'',
              issue_org:'',
              issue_date:'',
              vaccine_type: '',
              disability_type: "",
              vaccinated_id:'',
              is_verified:'',
              remarks:'',
              vaccination_record:'',
              age:'',
              date_of_birth:'',
              identity_image:'',
              profile_image:'',
              self_decleration_image: '',
              vaccine_card_image:'',
              municipality:'',
              data_from:'',

            }),

      columns: [
        {
           label:'Registration No',
           field:'vaccinated_id'
        },
        {
          label: 'Name',
          field: 'name',
        },

        {
            label: 'Date of Birth',
            field:'date_of_birth'

        },
        {
            label: 'District',
            field:'municipality.district_name'

        },

        {
            label: 'Municipality',
            field:'municipality.municipality_name'

        },
        {
            label: 'Status',
            field:'is_verified',
            filterOptions: {
              customFilter: true
            }
        },
        {
            label: 'Vaccinated Municipality',
            field:'vaccinated_municipality',
        },
        {
            label: 'Action',
            field: 'actions',
            filterOptions: {
              customFilter: true
            }
        },

      ],
      rows: {
        data:[
        ]
      },
      serverParams: {
      columnFilters: {
      },
      sort: {
        field: '',
        type: '',
      },
      page: 1,
      perPage: 10,
      searchTerm:'',

    }


    }
  },
  methods:{

      viewReport(params){
          axios.post('https://vaccine.mohp.gov.np/api/get-access', {
              token: params.vaccinated_id
          })
              .then(function (response) {
                  window.open(
                      ' https://vaccine.mohp.gov.np/vaccination-detail?id=' + response.data,
                      '_blank'
                  );
              })
              .catch(function (error) {

              });
      },
    view(params){
      this.form.fill(params);
      this.refresh++
      $('#viewSlider').modal('show');
    },
    viewVaccineRecord(params){
      $('#viewContent').modal('show');
      this.form.fill(params);
      this.medical_condition = JSON.parse(this.form.medical_condition)
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onSearch(param){
        this.updateParams(param);
        this.loadFromFilter();
    },
    loadFromFilter(){
      let param = this.serverParams
      axios.get('/public-list-search-update?filter='+param.searchTerm).then(({data})=>{
            this.rows = data.pagination_data
        }).catch(()=>{
        })
    }
  },
  created(){

    Fire.$on('AfterUpdate',()=>{
    })


  }
};
</script>
<style >

.fancy-btn{
  color: black;
  background: blanchedalmond;
  border: 1px solid #000;
}
.invisible-input{
    background-color: transparent;
  border: none;
  box-sizing: border-box;
  width: 100%;
}
input:focus{
     outline: none;
}
.invisible-input:focus {
  outline: none;
}
.vgt-table th {
      font-size: 15px !important;

 }
 .vgt-table td{
      font-size: 14px !important;

 }
</style>

