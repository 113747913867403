<template>
    <div class="card">
        <div class="card-header">
            Add Vaccination Record
        </div>
        <div class="card-body">
            <div class="form-group mar-t" :class="{ 'has-error': $v.data.id.$error }">
                <input type="text" name="id" required="required" v-model.trim="data.id">
                <label class="control-label">Enter Registered ID</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.data.id.required">Field is required.</div>
                <div>
                    <button class="btn btn-primary" v-on:click="checkRegNo">Check Reg No.</button>
                </div>
                <div v-show="isShow">
                    <div v-show="!isDataShow" class="alert-css alert alert-danger" style="margin: 5px 0 -10px 0;">
                        {{ message }}
                    </div>
                    <div class="card" v-show="isDataShow" style="margin-bottom: -5px;">
                        <table class="table card-css table-sm">
                                <tr>
                                    <td colspan="2" class="align-content-center" style="margin-bottom: 10px; text-align: center; background-color: #50b450;">
                                        {{ message }}
                                    </td>
                                </tr>
                            <tr>
                                <td>Name : {{ name }}</td>
                                <td>Age : {{ age }}</td>
                            </tr>
                            <tr>
                                <td>Municipality : {{ municipality }}</td>
                                <td>
                                    <p>
                                        Vaccination Status : {{ vaccination_status }}
                                    </p>
                                    <p v-if="date_of_first_vaccinated">First Dose Vaccinated Date: {{date_of_first_vaccinated}}</p>
                                    <p v-if="date_of_second_vaccinated">Second Dose Vaccinated Date: {{date_of_second_vaccinated}}</p>
                                    <p v-if="date_of_third_vaccinated">Additional Dose Vaccinated Date: {{date_of_third_vaccinated}}</p>
                                    <p v-if="date_of_fourth_vaccinated">Second Additional Dose Vaccinated Date: {{date_of_fourth_vaccinated}}</p>

                                 </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="wrapper" v-if="!fourth_dose_completed">

            <div class="vaccine_detail">
                <label >Please Choose Dose...</label>
                <input class="radio-group"  v-if="!first_dose_completed " type="radio" id="first" name="first" value="1M"
                       v-model="data.vaccine_period"><b v-if="!first_dose_completed">First</b>&nbsp; &nbsp;

                <input class="radio-group" v-if="first_dose_completed && !second_dose_completed" type="radio" id="second" name="second" value="3M"
                       v-model="data.vaccine_period"><b v-if="first_dose_completed && !second_dose_completed">Second</b>

                <input class="radio-group" v-if="second_dose_completed && !third_dose_completed" type="radio" id="third" name="third" value='9M'
                       v-model="data.vaccine_period"><b v-if="second_dose_completed && !third_dose_completed">Additional</b>

                <input class="radio-group"  type="radio" id="fourth" name="fourth" value='12M'
                       v-model="data.vaccine_period" v-if="third_dose_completed"><b v-if="third_dose_completed">Second Additional</b>

                <div class="help-block text-danger" v-if="!$v.data.vaccine_period.required">Field is required.</div>
            </div>

            <div class="vaccine_name form-group ">
                <label for="vaccine_name">Vaccine Name</label>
                <select v-model="data.vaccine_name" id="vaccine_name" :disabled="first_dose_completed" @change="fillVaccineType()">
                <option value="" >{{ $t('select_options') }}</option>
                <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                </select>
                <label class="control-label"></label><i class="bar"></i>

                <div class="help-block text-danger" v-if="!$v.data.vaccine_name.required">Field is required.</div>


            </div>
            <div class="form-group">
            <select v-model="data.batch_no" id="batch_no_first">
                    <option value="">{{ $t('select_options') }}</option>
                    <option v-for="batch_no in batch_numbers" :key="batch_no.id" :value="batch_no.batch_no">{{batch_no.batch_no}}</option>
                </select>
                <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                <!-- <div class="help-block text-danger" v-if="!$v.data.first_vaccine_batch_no.required">{{ $t('field_required') }}</div> -->
            </div>

            <div class="form-group" :class="{ 'has-error': $v.data.vaccination_date.$error }">
                <input type="text"  id="vaccination_date" name="vaccination_date" required="required"  class="has-value" :readonly="data.vaccination_date"
                v-mask="'####-##-##'" :value="data.vaccination_date">
                <label class="control-label">Vaccination Date</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.data.vaccination_date.required">Field is required.</div>
            </div>


            <div class="form-group vacc-add">
                <input type="text" name="vaccinated_address" :readonly="data.vaccinated_address" class="has-value" required="required"
                       v-model="data.vaccinated_address">
                <label class="control-label">Vaccinated Address</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.data.vaccinated_address.required">Field is required.</div>
            </div>
        </div>

        </div>
        <button class="btn btn-primary btn-sm btn-block" v-if="!fourth_dose_completed"
                @click.prevent="addRecord">
            Submit
        </button>
    </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import DataConverter from "ad-bs-converter";
import axios from 'axios';

export default {
    data() {
        return {
            data: {
                'vaccination_date': '',
                'vaccinated_address': '',
                'vaccine_period': '',
                'vaccine_name' : '',
                vaccine_id:'',
                batch_no:'',
            },
            'isShow': false,
            'isDataShow': false,
            'name': '',
            'age': '',
            'message': '',
            'municipality': '',
            'vaccination_status': '',
            'vaccination_center_id': '',
            'role': '',
            first_dose_completed: false,
            second_dose_completed:false,
            third_dose_completed: false,
            fourth_dose_completed: false,
            date_of_first_vaccinated:'',
            date_of_second_vaccinated:'',
            date_of_third_vaccinated:'',
            date_of_fourth_vaccinated:'',
            second_dose_enabler:false,
            searchmode:true,
            vaccines:{},
            batch_numbers:{}
        }
    },
    validations() {
        if(this.searchmode){
            return{
                data: {
                    id: {
                        required
                    },
                    vaccination_date: {
                        required:false
                    },
                    vaccine_name: {
                        required:false
                    },
                    vaccine_period: {
                        required:false
                    },
                    vaccinated_address: {
                        required:false
                    }
                }

            }
        }
        else{
            return{
                data: {
                    id: {
                        required:false
                    },
                    vaccination_date: {
                        required
                    },
                    vaccine_name: {
                        required
                    },
                    vaccine_period: {
                        required
                    },
                    vaccinated_address: {
                        required
                    }
                }

            }
        }

    },
    methods: {

         fillVaccineType(){
           this.vaccines.filter(({id,name})=>(
                name == this.data.vaccine_name ? this.data.vaccine_id = id : ''
                ))
            },
        getNepaliDateToday(){
            var today =  new Date().toISOString().substr(0, 10);
            var splited_date = today.split("-");
            var ad2bs = DataConverter.ad2bs(splited_date[0] + "/" + splited_date[1] + "/" + splited_date[2]);
            var npdate = ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day;
            var np_d = npdate.split("-");
            var first_month = np_d[1].length === 1 ? '0'+np_d[1] : np_d[1];
            var first_day = np_d[2].length === 1 ? '0'+np_d[2] : np_d[2];
            return np_d[0]+ "-" + first_month + "-" + first_day;
            },
        days_between() {
            const oneDay = 24 * 60 * 60 * 1000;
            let fv_date = this.date_of_first_vaccinated.split('-');
            let now = this.getNepaliDateToday();
            var today = now.split('-');
            const firstDate = new Date(fv_date[0], fv_date[1], fv_date[2]);
            const secondDate = new Date(today[0], today[1], today[2]);

            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

        },
        renderDatePickerNp(){
        var self = this;
        if($('#vaccination_date').length){
            $('#vaccination_date').nepaliDatePicker({
            language: 'english',
            ndpYear: true,
            ndpMonth: true,
            disableAfter: this.getNepaliDateToday(),
            onChange: function() {
                self.data.vaccination_date = $('#vaccination_date').val()
            }
        });
        }
        },
        fetchVaccinationCenterInfo() {
            axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
                .then((response) => {
                    this.data.vaccinated_address = response.data.vaccination_center
                    if(response.data.vaccine_name){
                        this.data.vaccine_name = response.data.vaccine_name;
                    }
                    this.fillVaccineType();
                });
        },
        getMunicipalityName(id) {
            axios.get('/municipality-name?municipality_id=' + id)
                .then((response) => {
                    this.municipality = response.data
                })
        },
        checkRegNo() {
            this.$v.$touch()
            console.log(this.$v.$invalid)

            this.first_dose_completed = false
                        this.second_dose_completed =false
                        this.third_dose_completed = false
                        this.fourth_dose_completed = false
                        this.date_of_first_vaccinated =''
                        this.date_of_second_vaccinated =''
                        this.date_of_third_vaccinated =''
                        this.date_of_fourth_vaccinated =''
            axios.get('/show?id=' + this.data.id)
                .then((response) => {
                    this.first_dose_completed =false,
                    this.second_dose_completed = false
                    if (response.data.message === 'Data not found') {
                        this.searchmode = true
                        this.message = response.data.message
                        this.isShow = true
                        this.isDataShow = false
                    } else {
                        this.searchmode = false
                        this.isDataShow = true
                        this.isShow = true
                        this.message = 'Data Found Successfully'
                        this.name = response.data.name
                        this.age = response.data.age
                        this.getMunicipalityName(response.data.municipality_id)
                        if(this.batch_numbers.length < 2){
                            this.data.batch_no = this.batch_numbers[0].batch_no
                        }
                        response.data.vaccination_record.forEach(({vaccine_period,vaccinated_date_np})=>{
                        var period_vaccine = vaccine_period.trim();
                        if(period_vaccine == '1M'){
                            this.vaccination_status = 'Vaccinated(First Dose)'
                            this.first_dose_completed = true
                            this.date_of_first_vaccinated = vaccinated_date_np
                        }
                        if(period_vaccine == '3M'){
                            this.second_dose_completed = true
                            this.first_dose_completed = true
                            this.vaccination_status = 'Vaccinated(First Dose, Second Dose)'
                            this.date_of_second_vaccinated = vaccinated_date_np
                        }
                        if(period_vaccine == '9M'){
                            this.third_dose_completed = true
                            this.vaccination_status = 'Vaccinated(First Dose, Second Dose, Booster Dose)'
                            this.date_of_third_vaccinated = vaccinated_date_np
                        }
                        if(period_vaccine == '12M'){
                            this.fourth_dose_completed = true
                            this.vaccination_status = 'Vaccinated(First Dose, Second Dose, Booster Dose , Second Booster Dose)'
                            this.date_of_fourth_vaccinated = vaccinated_date_np
                        }
                        });
                        if(response.data.vaccination_record.length == 0){
                            this.vaccination_status = 'Not Vaccinated'
                        }

                    }
                })
        },
        addRecord() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$fire({
                    text: this.$t('error_msg'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                return false;
            }
            // var dateNp = DataConverter.ad2bs(this.data.vaccination_date)
            if(this.data.vaccination_date){
                var first_date_np = this.data.vaccination_date.split("-");
                var first_ad2bs = DataConverter.bs2ad(first_date_np[0] + "/" + first_date_np[1] + "/" + first_date_np[2]);
                var date_of_first_vaccinated_np = first_ad2bs.year + "-" + first_ad2bs.month + "-" + first_ad2bs.day;
                var first_np_d = date_of_first_vaccinated_np.split("-");
                var first_month = first_np_d[1].length === 1 ? '0'+first_np_d[1] : first_np_d[1];
                var first_day = first_np_d[2].length === 1 ? '0'+first_np_d[2] : first_np_d[2];
                this.data.vaccination_date_en = first_np_d[0]+ "-" + first_month + "-" + first_day
            }
            const datas = {
                'vaccinated_id': this.data.id,
                'vaccinated_date_np': this.data.vaccination_date,
                'vaccinated_date_en': this.data.vaccination_date_en,
                'vaccinated_address': this.data.vaccinated_address,
                'vaccine_period': this.data.vaccine_period,
                'vaccine_name': this.data.vaccine_name,
                'vaccine_id': this.data.vaccine_id,
                'batch_no': this.data.batch_no,
            }
            if(!this.searchmode){
                 axios.post('/vaccination-store', datas)
                .then((response) => {
                    if (response.data.message === 'success') {
                        this.$fire({
                            type: "success",
                            title: response.data.id,
                            text: "Data Successfully Added",
                        });
                        this.$v.$reset();
                        this.data.id = '';
                        this.data.vaccination_date = '';
                        this.data.vaccine_period = '';
                        this.isShow = false;
                        this.isDataShow = false;
                        this.name = '';
                        this.age = '';
                        this.message = '';

                        this.vaccination_status = '';
                        this.searchmode = true
                    } else {
                        this.$fire({
                            text: 'Something Went Wrong!',
                            type: "error",
                            timer: 1000,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                        })
                        this.searchmode = true

                    }
                })

            }


        },
        getNepaliDateToday(){
            var today =  new Date().toISOString().substr(0, 10);
            var splited_date = today.split("-");
            var ad2bs = DataConverter.ad2bs(splited_date[0] + "/" + splited_date[1] + "/" + splited_date[2]);
            var npdate = ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day;
            var np_d = npdate.split("-");
            var first_month = np_d[1].length === 1 ? '0'+np_d[1] : np_d[1];
            var first_day = np_d[2].length === 1 ? '0'+np_d[2] : np_d[2];
            return np_d[0]+ "-" + first_month + "-" + first_day;
        },
        loadVaccine(){
            axios.get('/api/vaccines').then(({data})=>{
                this.vaccines = data.vaccines
            })
        },
        loadBatchNo(){
            axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
            .then((response) => {
                this.batch_numbers = response.data.batch_no
            });
        },
    },
    created() {
        this.loadVaccine();
        this.loadBatchNo();
        if(this.$user.role === 'healthpost' || this.$user.role === 'healthworker'){
            this.fetchVaccinationCenterInfo();
        }
        this.data.vaccination_date = this.getNepaliDateToday()

    },
     beforeUpdate(){
      this.renderDatePickerNp();
    },
    updated(){
      this.days_between();
   }
}
</script>
