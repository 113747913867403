<template>
    <div>
<!--        <table id="example" class="table table-striped table-bordered"-->
<!--               style="width:100%; margin-top: 10px;">-->
<!--            <thead>-->
<!--            <tr>-->
<!--                <th>Reg No.</th>-->
<!--                <th>Name</th>-->
<!--                <th>Caste</th>-->
<!--                <th>Gender</th>-->
<!--                <th>Age</th>-->
<!--                <th>Phone</th>-->
<!--                <th>Vaccinated Date</th>-->
<!--                <th>Vaccination Center</th>-->
<!--                <th>Created At</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr v-for="d in data">-->
<!--                <td>{{ d.id }}</td>-->
<!--                <td>{{ d.first_name }} {{ d.middle_name }} {{ d.last_name }}</td>-->
<!--                <td>{{ this.getCaste() }}-->
<!--                    {{ caste }}-->
<!--                    @if(d.caste == '0')-->
<!--                    Dalit-->
<!--                    @elseif(d.caste == '1')-->
<!--                    Janajati-->
<!--                    @elseif(d.caste == '2')-->
<!--                    Madhesi-->
<!--                    @elseif(d.caste == '3')-->
<!--                    Muslim-->
<!--                    @elseif(d.caste == '4')-->
<!--                    Brahmin / Chettri-->
<!--                    @elseif(d.caste == '5')-->
<!--                    Other-->
<!--                    @elseif(d.caste == '6')-->
<!--                    Don't want to mention-->
<!--                    @endif-->
<!--                </td>-->
<!--                <td>-->
<!--                    @if(d.gender == '1')-->
<!--                    Male-->
<!--                    @elseif(d.gender == '2')-->
<!--                    Female-->
<!--                    @elseif(d.gender == '3')-->
<!--                    Other-->
<!--                    @endif-->
<!--                </td>-->
<!--                <td>{{ d.age }}</td>-->
<!--                <td>{{ d.phone }}</td>-->
<!--                <td>-->
<!--                    @if(!empty(d.date_of_first_vaccinated))-->
<!--                    First: {{ d.date_of_first_vaccinated }}-->
<!--                    @endif-->
<!--                    @if(!empty(d.date_of_second_vaccinated))-->
<!--                    <br>Second: {{ d.date_of_second_vaccinated }}-->
<!--                    @endif-->
<!--                </td>-->
<!--                <td>-->
<!--                    @php($vaccine_center = d.vaccination_center_id ?? '')-->
<!--                    @if(!empty($vaccine_center))-->
<!--                    &lt;!&ndash;                    {{ App\Models\VaccinationCenter::where('id', $vaccine_center)->first()->vaccination_center }}&ndash;&gt;-->
<!--                    @else-->
<!--                    Default-->
<!--                    @endif-->
<!--                </td>-->
<!--                <td>{{ d.created_at }}</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--        </table>-->
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            data: {},

        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            axios.get('/all-clients')
                .then((response) => {
                    this.data = response.data
                });
        },
        getCaste(){
            this.caste =  'ok'
        }
    }
}
</script>
