<template>
<div class="row">


<div class="container">

    <table class="table table-hover table-bordered text-center">

        <tbody>
            <tr>
                <th>Vaccine Name</th>
                <!-- <th>Total Provided Doses</th> -->
                <th>Total Verified Dose</th>
            </tr>
            <tr v-for="item in data" :key="item.id" >
                <th>{{item.vaccine_info.name}}</th>
                <!-- <th>{{item.total_doses_from_dhis || 0}}</th> -->
                <th>{{item.total_doses}}</th>

            </tr>

        </tbody>
    </table>

    <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"></loading>
</div>
</div>

</template>
<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components:{
        Loading
    },
    data(){
        return{

            data:{

            },
            isLoading:false,
            fullPage:true,

            records:[]


        }
    },
    methods:{
        loadData(){
            this.isLoading = true
            axios.get('/api/municipality-vaccine-data/'+this.$user.municipality_id).then(({data})=>{
            this.data  = data
            this.isLoading = false
            })
        },
    },

    created(){
        this.loadData();
    }
}
</script>
<style scoped>
.report{
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}
</style>
