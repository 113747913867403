<template>
    <div class="modal fade" id="addNewContent" tabindex="-1" role="dialog" aria-labelledby="addNewContentLabel" data-keyboard="false" data-backdrop="static">
        <div :class="modal_data.modal_size" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="card-body">
                    <table class="table table-striped shadow-sm" id="detailTable">
                        <thead>
                        <tr class="text-center">
                            <td colspan="2"><h4>{{ $t('personal_detail') }}</h4></td>

                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td class="w-35">{{ $t('name') }}</td>
                            <td>
                                {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                            </td>
                        </tr>
                        <tr>

                            <td>{{ $t('caste') }}</td>
                            <td v-if="item.caste == '0'">{{ $t('dalit') }}</td>
                            <td v-else-if="item.caste == '1'">{{ $t('janajati') }}</td>
                            <td v-else-if="item.caste == '2'">{{ $t('madhesi') }}</td>
                            <td v-else-if="item.caste == '3'">{{ $t('muslim') }}</td>
                            <td v-else-if="item.caste == '4'">{{ $t('brahmn_chettri') }}</td>
                            <td v-else-if="item.caste == '5'">{{ $t('other') }}</td>
                            <td v-else-if="item.caste == '6'">{{ $t('dont_mention') }}</td>


                        </tr>
                        <tr>
                            <td>{{ $t('nationality') }}</td>
                            <td>{{ item.nationality }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('gender') }}</td>

                            <td v-if="item.gender == '1'">{{this.$t('male')}}</td>
                            <td v-else-if="item.gender == '2'">{{this.$t('female')}}</td>
                            <td v-else>{{this.$t('third_gender')}}</td>


                        </tr>
                        <tr>
                            <td>{{ $t('dob') }}</td>
                            <td>{{ date_of_birth }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('age') }}</td>
                            <td>{{ age }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('mobile') }}</td>
                            <td>{{ item.phone }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('occupation') }}</td>
                            <td v-if="item.occupation == '0'">{{ $t('other') }}</td>
                            <td v-else-if="item.occupation == '33'">{{ $t('foreign_employment') }}</td>
                            <td v-else-if="item.occupation == '1'">{{ $t('airlines') }}</td>
                            <td v-else-if="item.occupation == '2'">{{ $t('army') }}</td>
                            <td v-else-if="item.occupation == '3'">{{ $t('bank') }}</td>
                            <td v-else-if="item.occupation == '4'">{{ $t('commercial_facilities') }}</td>
                            <td v-else-if="item.occupation == '5'">{{ $t('daily_wage_worker') }}</td>
                            <td v-else-if="item.occupation == '6'">{{ $t('farmer') }}</td>
                            <td v-else-if="item.occupation == '7'">{{ $t('government_employee') }}</td>
                            <td v-else-if="item.occupation == '8'">{{ $t('grocery') }}</td>
                            <td v-else-if="item.occupation == '9'">{{ $t('health_care') }}</td>
                            <td v-else-if="item.occupation == '10'">{{ $t('health_professional') }}</td>
                            <td v-else-if="item.occupation == '11'">{{ $t('it_and_communication') }}</td>
                            <td v-else-if="item.occupation == '12'">{{ $t('journalist_media') }}</td>
                            <td v-else-if="item.occupation == '13'">{{ $t('manufacturing') }}</td>
                            <td v-else-if="item.occupation == '14'">{{ $t('ngo_ingo') }}</td>
                            <td v-else-if="item.occupation == '15'">{{ $t('online_business') }}</td>
                            <td v-else-if="item.occupation == '16'">{{ $t('police') }}</td>
                            <td v-else-if="item.occupation == '17'">{{ $t('Security_person') }}</td>
                            <td v-else-if="item.occupation == '18'">{{ $t('social_service') }}</td>
                            <td v-else-if="item.occupation == '19'">{{ $t('student') }}</td>
                            <td v-else-if="item.occupation == '20'">{{ $t('teacher_professor') }}</td>
                            <td v-else-if="item.occupation == '21'">{{ $t('tourist') }}</td>
                            <td v-else-if="item.occupation == '22'">{{ $t('transport_sector') }}</td>
                            <td v-else-if="item.occupation == '23'">{{ $t('unemployed') }}</td>
                            <td v-else-if="item.occupation == '31'">{{ $t('diplomat') }}</td>
                            <td v-else-if="item.occupation == '32'">{{ $t('veterinarians') }}</td>


                        </tr>
                        <tr>
                            <td>{{ $t('identity_type') }}</td>
                            <td>{{ item.identity_type }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('identification_no') }}</td>
                            <td>{{ item.identity_no }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('current_address') }}</td>
                            <td>
                                Province :{{ item.province_name }} <br>
                                District {{ item.district_name }} <br>
                                Municipality {{ item.municipality_name }} <br>
                                Ward No :{{ item.ward }} <br>
                                Tole : {{ item.tole }}
                            </td>
                        </tr>
                        <tr>

                            <td>{{ $t('medical_condition') }}</td>
                            <td> <p v-if="item.medical_condition.includes(1) || item.medical_condition.includes('1')">{{ $t('cancer') }} </p>
                            <p v-if="item.medical_condition.includes(2) || item.medical_condition.includes('2')">{{ $t('chronic_kidney_disease') }}</p>
                            <p v-if="item.medical_condition.includes(3) || item.medical_condition.includes('3')">{{ $t('chronic_resp_disease') }}</p>
                            <p v-if="item.medical_condition.includes(4) || item.medical_condition.includes('4')">{{ $t('cardiovascular_disease') }}</p>
                            <p v-if="item.medical_condition.includes(5) || item.medical_condition.includes('5')">{{ $t('diabetes') }}</p>
                            <p v-if="item.medical_condition.includes(7) || item.medical_condition.includes('7')">{{ $t('pregnant') }}</p>
                            <p v-if="item.medical_condition.includes(8) || item.medical_condition.includes('8')">{{ $t('blood_pressure') }}</p>
                            <p v-if="item.medical_condition.includes(9) || item.medical_condition.includes('9')">{{ $t('nerve_disease') }}</p>
                            <p v-if="item.medical_condition.includes(10) || item.medical_condition.includes('10')">{{ $t('none_of_above') }}</p>
                            <p v-if="item.medical_condition.includes(11) || item.medical_condition.includes('11')">{{ $t('tb') }}</p>
                            <p v-if="item.medical_condition.includes(21) || item.medical_condition.includes('21')">{{ $t('normal_health') }}</p>
                            <p v-if="item.medical_condition.includes(22) || item.medical_condition.includes('22')">{{ $t('mental_disease') }}</p>
                            <p v-if="item.medical_condition.includes(23) || item.medical_condition.includes('23')">{{ $t('covid') }}</p>
                            <p v-if="item.medical_condition.includes(31) || item.medical_condition.includes('31')">{{ $t('hiv_aids') }} </p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <table v-if="item.date_of_first_vaccinated" class="table table-striped 4 mt-5  shadow-sm" id="detailTable">
                        <thead>
                        <tr  class="text-center">
                            <td colspan="2"><h4>{{ $t('vaccination_status') }}</h4></td>
                        </tr>
                        </thead>
                        <!-- <tbody v-if="vaccination_records !== null">
                        <tr   v-for="vaccinated_record in vaccination_records" :key="vaccinated_record.id" >
                            <td v-if="vaccinated_record.vaccine_period == '1M'" class="w-35">{{ $t('first_dose') }}</td>
                            <td v-if="vaccinated_record.vaccine_period == '3M'" class="w-35">{{ $t('second_dose') }}</td>
                            <td>
                                {{$t('vaccinated_date')}} : {{vaccinated_record.vaccinated_date_np}} <br>
                                {{$t('vaccine_type')}} : {{vaccinated_record.vaccine_name}} <br>
                                {{$t('vaccinated_address')}} : {{vaccinated_record.vaccinated_address}} <br>
                            </td>
                        </tr>
                        </tbody> -->
                        <tbody  >
                            <tr v-if="item.first_vaccine_period">
                            <td class="w-35">{{ $t('first_dose') }}</td>
                            <td>
                                {{$t('vaccinated_date')}} : {{item.date_of_first_vaccinated}} <br>
                                {{$t('vaccine_type')}} : {{item.first_vaccine_name}} <br>
                                {{$t('vaccinated_address')}} : {{item.first_vaccinated_address}} <br>
                                {{$t('batch_no')}} : {{item.first_vaccine_batch_no}} <br>

                            </td>
                        </tr>
                        <tr v-if="item.second_vaccine_period">
                            <td class="w-35">{{ $t('second_dose') }}</td>
                            <td>
                                {{$t('vaccinated_date')}} : {{item.date_of_second_vaccinated}} <br>
                                {{$t('vaccine_type')}} : {{item.second_vaccine_name}} <br>
                                {{$t('vaccinated_address')}} : {{item.second_vaccinated_address}} <br>
                                {{$t('batch_no')}} : {{item.second_vaccine_batch_no}} <br>
                            </td>
                        </tr>

                        <tr v-if="item.third_vaccine_period">
                            <td class="w-35">{{ $t('third_dose') }}</td>
                            <td>
                                {{$t('vaccinated_date')}} : {{item.date_of_third_vaccinated}} <br>
                                {{$t('vaccine_type')}} : {{item.third_vaccine_name}} <br>
                                {{$t('vaccinated_address')}} : {{item.third_vaccinated_address}} <br>
                                {{$t('batch_no')}} : {{item.third_vaccine_batch_no}} <br>
                            </td>
                        </tr>
                        <tr v-if="item.fourth_vaccine_period">
                            <td class="w-35">{{ $t('fourth_dose') }}</td>
                            <td>
                                {{$t('vaccinated_date')}} : {{item.date_of_fourth_vaccinated}} <br>
                                {{$t('vaccine_type')}} : {{item.fourth_vaccine_name}} <br>
                                {{$t('vaccinated_address')}} : {{item.fourth_vaccinated_address}} <br>
                                {{$t('batch_no')}} : {{item.fourth_vaccine_batch_no}} <br>
                            </td>
                        </tr>

                        </tbody>
                    </table>

                     <table v-show="(item.aefi_type)" class="table table-striped mt-5 mb-3 shadow-sm" id="detailTable">
                        <thead>
                        <tr  class="text-center">
                            <td colspan="2"><h4>{{ $t('aefi_status') }}</h4></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td  class="w-35">{{ $t('aefi_type') }}</td>
                            <td>
                                {{item.aefi_type}}
                            </td>
                        </tr>
                        <tr>
                            <td  class="w-35">{{ $t('aefi_date') }}</td>
                            <td>
                                {{item.aefi_date}}
                            </td>
                        </tr>
                        <tr>
                            <td  class="w-35">{{ $t('remarks') }}</td>
                            <td>
                                {{item.aefi_remarks}}
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <div>
                        <button class="btn btn-edit"
                           @click="editInfo" >
                            {{ $t('edit') }}
                        </button>
                        <button class="btn btn-save float-right" v-if="!editmode" @click="createDetail" :disabled="disabled">
                            {{ $t('save') }}
                        </button>
                        <button class="btn btn-save float-right" v-if="editmode" @click="updateDetail" :disabled="disabled">
                            {{ $t('update') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    props:['modal_data', 'item' ,'age','date_of_birth','vaccination_records'],
    data(){
        return{
            disabled:false,
            editmode:false,
        }
    },
    methods:{
        editInfo(){
            $('#addNewContent').modal('hide');
        },
        createDetail(){
            Event.$emit('create-data',this.item);
        },
        updateDetail(){
            Event.$emit('update-data',this.item);
        },

    },
    created(){
        Event.$on('creating',(editmode)=>{
            this.editmode = editmode
        });
        Event.$on('updating',(editmode)=>{
           this.editmode = editmode
        });
        Event.$on('disabled',(disabled)=>{
           this.disabled = disabled
        });



    }


}
</script>
