<template>
    <div class="modal fade" id="addNewContent" tabindex="-1" role="dialog" aria-labelledby="addNewContentLabel" aria-hidden="true">
        <div :class="modal_data.modal_size" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode" id="addNewContentLabel">Create</h5>
                    <h5 class="modal-title" v-show="editmode" id="addNewContentLabel">Update</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form  @submit.prevent="editmode ? updateInfo() : createInfo()">
                    <slot></slot>
                
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fas fa-times fa-fw"></i>Close</button>
                        <button v-show="editmode" :disabled="disabled" type="submit" class="btn btn-success"><i class="fas fa-plus fa-fw"></i>Update</button>
                        <button v-show="!editmode" :disabled="disabled" type="submit" class="btn btn-primary"><i class="fas fa-plus fa-fw"></i>Create</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['modal_data','form','api_url'],
    data(){
        return{
            disabled:false,
            editmode:false,
            invalid:true,
        }
    },
    methods:{
        createInfo(){
            Fire.$emit('validate'); 
            if(this.invalid){
                this.$fire({
                text: this.$t('error_msg'),
                type: "error",
                timer: 4000,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            })
            return false;
            }
            this.form.post('/'+this.api_url).then((res)=>{
            this.disabled = true 

            if(res.data.type === 'success'){
                this.$fire({
                type:res.data.type,
                title: this.$t('created'),
                text: this.$t('create_success'),
                })
                $('#addNewContent').modal('hide');
                this.disabled=false;
                Fire.$emit('AfterCreate'); //Fire an reload event
                this.form.reset();
            }else{
             this.disabled=false
            }
            })
            .catch((res)=>{
                this.$fire({
                    type: res.data.type,
                    title: this.$t('error'),
                    text: this.$t('create_error'),
                    } 
                )
                this.disabled=false
            })
        },
        updateInfo(){
            Fire.$emit('validate'); 
            if(this.invalid){
                this.$fire({
                text: this.$t('error_msg'),
                type: "error",
                timer: 4000,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            })
            return false;
            }
            this.disabled=true,
            this.form.put('/'+this.api_url+'/'+this.form.id).then((res)=>{
                if(res.data.type === 'success'){
                    this.$fire({
                    type:res.data.type,
                    title: this.$t('updated'),
                    text: this.$t('update_success'),
                    })
                    this.disabled=false;
                    $('#addNewContent').modal('hide');
                    Fire.$emit('AfterCreate'); //Fire an reload event
                    this.form.reset();
                }
            })
            .catch((res)=>{
                this.$fire({
                    type: res.data.type,
                    title: this.$t('error'),
                    text: this.$t('update_error'),
                    } 
                )
                this.disabled=false
            })
        },
    },
    created(){
        Fire.$on('editmode',(editmode)=>{  
             this.form.reset();
             this.editmode = editmode
            $('#addNewContent').modal('show');
        })
        Fire.$on('editing',(item)=>{   
            this.editmode = true;
            this.form.reset();
            $('#addNewContent').modal('show');
            this.form.fill(item);
        })
         Fire.$on('validation',(validate)=>{   
            this.invalid = validate;
        })
    }
}
</script>