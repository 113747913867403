<template>
    <div class="container">
        <div class="row mt-5" >

            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Client Management</h3>

                        <div class="card-tools">
                            <button type="" class="btn btn-primary" @click="newClient"><i class="fas fa-user-plus fa-fw"></i> Add New </button>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover">
                            <tbody>
                            <tr>
                                <th style="width:25%">Name</th>
                                <th>Registration No</th>
                                <th >Date of Birth</th>
                                <th >Phone</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="client in clients.data" :key="client.id">
                                <td>{{client.first_name}} {{client.middle_name}} {{client.last_name}}</td>
                                <td>{{client.vaccinated_id}}</td>
                                <td>{{client.date_of_birth}}</td>
                                <td>{{client.phone}}</td>

                                <td>
                                    <a href="#" @click="showCard(client.encrypt_id)" class="btn btn-sm p-1 m-1 btn-success">Card 
                                        <i class="fa fa-address-card"></i>
                                    </a>
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                    <div class="card-footer">
                        <pagination-wrapper class="mt-3" :citizen_param="citizen_param" :param="paginate_param" :pagination_data="this.clients" api_url="organization-data" pagination_title="Clients"></pagination-wrapper>
                    </div>
                    
                </div>
                <!-- /.card -->
            </div>

            
        </div>
    </div>
</template>
<script>
import axios from "axios";
import PaginationWrapper from './Pagination/PaginationWrapper.vue';

    export default {
        components:{
            PaginationWrapper
        },
        data(){
            return{
                paginate_param : '',
                citizen_param : '',
                totalclient:0,
                clients : {},
            }
        },
        methods: {
            newClient(){
                window.location = '/admin/organization-create'
            },
            
            loadclients(){
                    axios.get("/api/organization-data")
                    .then(({ data }) => (
                    this.clients = data.pagination_data,
                    this.totalclient = data.pagination_data.total
                    ));
            },
            showCard(encrypt_id) {
                window.open('/report?id=' + encrypt_id)
            },
        },
        created() {
            

            this.loadclients(); 
            Fire.$on("AfterCreate",()=>{
                this.loadclients();
            })
        }
    }
</script>
<style  scoped>
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.invalid-feedback{
    display: block;
}
</style>
