<template>

    <div class="modal fade" id="viewSlider" tabindex="-1" role="dialog" aria-labelledby="viewSliderLabel" aria-hidden="true">
        <h1>Click here to see document</h1>
        <div :class="modal_data.modal_size" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content rounded-0">
                <div class="modal-header ">
                    <h3 class="modal-h4"  id="viewSliderLabel">{{header}} <br> <span v-if="form.data_from == 'api-vaccine-token'">(This record is uploaded form the Nagarik App and doesnot requireme identity image.)</span>  </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body mr-3 ml-3">
            <carousel 
            ref="carousel"
             :key="refresh"
              :perPage="1"
              :loop="true"
              :navigationEnabled="true"
              :paginationEnabled="false"
              :autoplayTimeout="2000"
            >
              <slide>
                  <h4>Identity Card Image</h4>
                  <img class="border" :src=" 'https://vaccine.mohp.gov.np/storage/public_document/'+form.vaccinated_id+'/' + form.identity_image " alt=""/>
              </slide>
                <slide >
                    <h4>Profile Picture</h4>

                  <img class="border" :src=" 'https://vaccine.mohp.gov.np/storage/public_document/'+form.vaccinated_id+'/' + form.profile_image " alt=""/>
              </slide>
                <slide v-if="form.vaccine_card_image != 'vaccine_card_default_image.jpg' && form.self_decleration_image">
                      <h4>Self Decleration Image</h4>
                  <img class="border" :src=" 'https://vaccine.mohp.gov.np/storage/public_document/'+form.vaccinated_id+'/' + form.self_decleration_image " alt=""/>
              </slide>
                  <slide v-if="form.vaccine_card_image != 'vaccine_card_default_image.jpg'" >
                    <h4>Vaccine Card Image</h4>
                  <img class="border" :src=" 'https://vaccine.mohp.gov.np/storage/public_document/'+form.vaccinated_id+'/' + form.vaccine_card_image " alt=""/>
              </slide>
              
             <slide v-for="extra in extra_images" :key="extra.id" >
                <h4>Deleted Image</h4>
                  <img class="border" :src=" 'https://vaccine.mohp.gov.np/storage/public_document/'+form.vaccinated_id+'/' + extra" alt=""/>
              </slide>
            </carousel>
          </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['modal_data','form','api_url','header','refresh','extra_images'],
    data(){
        return{
        }
    },
    method:{
    },
    updated(){
        var resizeEvent = window.document.createEvent('UIEvents'); 
        resizeEvent.initUIEvent('resize'); 
        window.dispatchEvent(resizeEvent);
    },
   
}
</script>
<style scoped>
.VueCarousel-slide {
  visibility: visible !important;
  flex-basis: 100%;
  width: 100%;
}
</style>


