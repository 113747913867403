<template>
    <div class="row">
        
        <div class="col-6 col-sm-12 margin-b col-lg-6" v-if="view_report">
            <div class="small-box rounded-0  bg-white cm-vaccinated-box">
                <div class="icon icon-bg-vaccine"><span class="icon-vaccine"></span></div>
                <div class="inner innerbox"><p class="small text-right mb-0">
                    <div class="d-flex justify-content-between">
                        <div class="item ">
                            <p class="m-0">Total Registrations </p>
                            <h4 >{{ report.total_registration }} </h4>

                        </div>
                        <div class="item">
                            <p class="m-0">Today Registered</p>
                            <h4 >{{report.total_registration_today}}</h4>
                        </div>
                    </div>
                    <div class="row m-0 r-0 w-100">
                        <div class="col pl-0"><small class="text-muted accessibility-plugin-ac">Male</small><p class="m-0">{{report.male}}</p>
                        </div>
                        <div class="col pr-0"><small class="text-muted">Female</small>
                            <p class="m-0">{{report.female}}</p>
                        </div>
                        <div class="col pl-0"><small class="text-muted accessibility-plugin-ac">Other</small>
                            <p class="m-0">{{report.other}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-12 margin-b col-lg-6"  v-if="view_report">
            <div class="small-box rounded-0   bg-white cm-vaccinated-box">
                <div class="icon icon-bg-vaccine"><span class="icon-vaccine"></span></div>
                <div class="inner innerbox"><p class="small text-right mb-0">
                    <div class="d-flex justify-content-between">
                
                        <div class="item">

                            <p class="m-0">Today Verified</p>
                            <h4 >{{report.total_verified_today}}</h4>
                        </div>
                        <div class="item ">
                            <p class="m-0">Today Disaaproved</p>
                            <h4 >{{report.total_notverified_today}}</h4>

                        </div>
                    </div>
                    <div class="row m-0 r-0 w-100">
                        <div class="col pl-0">
                            <small class="text-muted accessibility-plugin-ac">Verified</small>
                            <p class="m-0">{{report.total_verified}}</p>
                        </div>
                        <div class="col pr-0">
                            <small class="text-muted">Pending</small>
                            <p class="m-0">{{report.total_pending}}</p>
                        </div>
                        <div class="col pr-0">
                            <small class="text-muted">Re-verification</small>
                            <p class="m-0">{{ report.total_registration - (report.total_verified + report.total_pending + report.total_not_verified)}}</p>
                        </div>
                        <div class="col pl-0"><small class="text-muted accessibility-plugin-ac">Disapproved</small>
                            <p class="m-0">{{report.total_not_verified}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</template>
<script>


export default {
    props:['view_report', 'report'],
    data(){
        return{
            
        }
    },
    methods:{
        

    },
    created(){

    }


}
</script>

