<template>
    <div class="">
        <div class="row">
            <div class="col-md-6 pull-left">
                <span>Showing {{ pagination_data.data && pagination_data.data.length}} of {{this.pagination_data.total}} {{pagination_title}}.</span>
            </div>
            <div class="col-md-6 pagination-main">
                <pagination :limit="10" :data="pagination_data" @pagination-change-page="getResults"></pagination>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props:['pagination_data','api_url','pagination_title','has_param'],
    data(){
        return{
           response_data:'',
        }
    },
    methods:{
        getResults(page = 1) {
            console.log(page);
            if(this.has_param){
                axios.get('../'+this.api_url+'&page=' + page)
                    .then(response => {
                        Fire.$emit('paginating', response.data.pagination_data);
                    });
            }
            else{
                axios.get('../'+this.api_url+'?page=' + page)
                    .then(response => {
                        Fire.$emit('paginating', response.data.pagination_data);
                    });
            }
        },

    },

}
</script>
<style scoped>
.pagination-main ul{
float: right;
}
</style>
