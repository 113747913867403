import Vue from 'vue';
import axios from 'axios';

export default class Functions{
      constructor(vue){
        this.vue = vue;
    }
    async getImages(id){
        const config = {
            headers: { Authorization: 'Bearer '+ this.vue.$token }
        };
        try {
            let response = await axios.get( 
                'https://vaccine.mohp.gov.np/api/images/'+ id,
                config
              );
              return response.data;
        } catch (e) {
            return e;
        }
    }
    
   
}





    