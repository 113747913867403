<template>
    <div class="container">
        <div class="row pt-5 justify-content-center" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="header d-inline">Category </h4>
                        <div class="card-tools">
                            <button @click="newContent" class="btn bg-blue btn-sm m-0"><i class="fas fa-plus fa-fw"></i> Add New </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6" v-for="(category, index)  in categories" :key="category.id">
                                <div class="card collapsed-card shadow">
                                    <div class="card-header p-1">
                                        <h4 class="mb-0 d-inline"><small>{{category.name}}</small></h4>

                                        <div class=" float-right ">
                                            <button type="button" class="btn p-1 btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-chevron-down"></i>
                                            </button>
                                            <button type="button"   @click="editInfo(category)" class=" green p-1 btn btn-tool">
                                                <i class="fas fa-edit green"></i>
                                            </button>
                                            <button type="button"  @click="deleteInfo(category.id)" class="red p-1 btn btn-tool">
                                                <i class="fas fa-trash red"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body p-0">
                                        <div class="p-3">
                                            <p v-html="category.excerpt"></p>
                                        </div>
                                        <!-- /.table-responsive -->
                                    </div>
                                    <!-- /.card-body -->
                                    <div class="card-footer p-0 clearfix">
                                        <a href="javascript:void(0)" data-card-widget="collapse" class="p-1 btn btn-sm btn-secondary float-right">Cose</a>
                                    </div>
                                    
                                    <!-- /.card-footer -->
                                </div>
                                <!-- /.card -->
                            </div>
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>  
        <modal ref="category" :modal_data="this.modal_data" :form="this.form" api_url="item-category">
            <div class="card-body">
                <div class="row small-form-group">
                    
                    <div class="form-group col-md-12" :class="{ 'has-error': $v.form.name.$error }">
                        <input type="text" id="name" name="name"
                                v-model="form.name" >
                        <label for="name" class="control-label ml-3">{{ $t('name') }}</label><i class="bar"></i>
                        <!-- <div class="help-block text-danger">{{form.errors.has('name') ? $t('field_required') :'' }}</div> -->
                        <div class="help-block text-danger" v-if="!$v.form.name.required">{{ $t('field_required') }}</div>
                    </div>
                    
                    
                    
                    <div class="form-group col-md-12">
                        <label for="inputName" >Description</label>
                        <vue-editor  v-model="form.excerpt"  placeholder="Description"></vue-editor>
                        <has-error :form="form" field="excerpt"></has-error>
                    </div>
                    
                </div>
            </div>
        </modal> 
        
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { VueEditor} from "vue2-editor";
import Modal from './modal/modal.vue';
import axios from "axios";
import {required, between, minLength, maxLength, not, sameAs,requiredIf} from "vuelidate/lib/validators";

export default {
    components:{
        Modal,
        VueEditor,
        Multiselect,
    },
    data(){
        return{
            categories:{},
            modal_data:{
                modal_size:'modal-lg',
            },
            form: new Form({
                id:'',
                name:'',
                excerpt :'',
            })
        }
    },
    validations() {
        return{
            form: new Form({
                name:{required},
                originalData:{$invalid:false}
            })
        }
    },
    methods:{
        newContent(){
            this.form.reset();
            $('#addNewContent').modal('show');
        },
        editInfo(category){
            Fire.$emit('editing', category);
        },
        deleteInfo(id){
            this.$fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                //send an ajax request to the server
                if (result.value) {
                    this.form.delete('/api/item-category/' + id).then((res) => {
                        if(res.data.type === 'success'){
                            this.$fire({
                            type:res.data.type,
                            title: this.$t('deleted'),
                            text: this.$t('delete_msg'),
                            })
                        }
                        Fire.$emit('AfterCreate'); //Fire an reload event
                    }).catch(() => {
                        this.$fire({
                            type:this.$('error'),
                            title: this.$t('deleted'),
                            text: this.$t('delete_msg'),
                            })
                    })
                }
            })

        },
        loadData(){
            axios.get('/api/item-category').then(({data})=>{
                this.categories = data.categories
               
            }).catch(()=>{
            })
        }
    },
    created(){
        Fire.$on("AfterCreate",()=>{
           this.loadData();
        })
        Fire.$on("validate",()=>{
            if (this.$v.form.$invalid) {
            this.$fire({
                text: this.$t('error_msg'),
                type: "error",
                timer: 4000,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            })
            return false;
            }
        })
        this.loadData();
       

    }
}
</script>
<style scoped>
.btn{
color:#000;
}
.btn:hover{
    box-shadow: unset;
}
.small-form-group .form-group{
    margin: 10px 0 ;
}

.form-control {
  border-radius: unset;
    padding: 16px;
    border: 1px solid #909090;

}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height:unset;
}

.form-group label {
  color: #616161;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
