<template>
    <div class="container">
        <div class="row pt-5 justify-content-center" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                                <h3>{{vaccine_center.vaccine_name}}</h3>
                                <div v-for="(batch_no,index) in vaccine_center.batch_no" :key="batch_no.id">
                                    <h4>Batch No {{index+1}}: {{batch_no.batch_no}}</h4>
                                </div>
                        </div>
                        <div class="card-tools">
                            <button @click="updateVaccine" class="btn bg-blue btn-sm m-0"><i class="fas fa-plus fa-fw"></i>Add Default Vaccine </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <div class="modal fade" id="addNewVaccine" tabindex="-1" role="dialog" aria-labelledby="addNewVaccineLabel" aria-hidden="true">
            <div class="modal-dialog modal modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addNewVaccine">Update</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="  form-group">
                            <select v-model="form.vaccine_name" id="vaccine_name"  >
                                <option value="">{{ $t('select_options') }}</option>
                                <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                            </select>
                            <label for="vaccine_name" class="control-label">{{ $t('vaccinte_type') }}</label><i class="bar"></i>
                        </div>
                        <button type='button' class=" btn btn-sm btn-secondary text-white shadow" @click="addNewRow">
                            <i class="fas fa-plus-circle"></i>Add Batch Number
                        </button> 
                        <div v-for="(batch, index) in form.batch_no" :key="index">
                            <div class="form-group mt-4 mb-0">
                                <input type="text" v-model="batch.batch_no">
                                <label for="vaccine_name" class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                                <button class="float-right btn btn-sm btn-danger">
                                <i class="fas fa-trash-alt" @click="deleteRow(index, batch)"></i>
                            </button>
                            </div>

                            
                            
                        </div>
                    </div>
                        
                    <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fas fa-times fa-fw"></i>Close</button>
                            <button  type="submit" class="btn btn-primary" @click="editInfo"><i class="fas fa-plus fa-fw"></i>Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from './modal/modal.vue';
import axios from "axios";
import {required,} from "vuelidate/lib/validators";

export default {
    components:{
        Modal,
    },
    data(){
        return{
            vaccine_center:{},
            vaccines:{},
            form: new Form({
                id:'',
                vaccine_name:'',
                batch_no:[{batch_no:''}],
            })
        }
    },
    validations() {
        return{
            form: new Form({
                vaccine_name:{required},
                 originalData:{$invalid:false}
            })
        }
    },
    methods:{
         addNewRow() {
                if(this.form.batch_no.length < 7 ){
                    this.form.batch_no.push({
                        batch_no: '',
                });

                }
            },
            deleteRow(index, detail) {
            var idx = this.form.batch_no.indexOf(detail);
            console.log(idx, index);
                if (idx > 0) {
                        this.form.batch_no.splice(idx, 1);
                    }
                else{
                    this.$fire({
                    type:'warning',
                    title: 'warning!',
                    text: 'you cannot delete default field',
                    })
                    
                }
            },
        updateVaccine(){
            $('#addNewVaccine').modal('show');  
            this.form.fill(this.vaccine_center)
        },
        editInfo(){
            this.form.put('/vaccination-setting/'+ this.form.id).then((res)=>{
                if(res.data.type === 'success'){
                    this.$fire({
                    type:res.data.type,
                    title: this.$t('updated'),
                    text: this.$t('update_success'),
                    })
                    this.disabled=false;
                    $('#addNewVaccine').modal('hide');
                    Fire.$emit('AfterCreate'); //Fire an reload event
                    this.form.reset();
                }
            })
            
        },
        
        loadData(){
            axios.get('/vaccination-setting').then(({data})=>{
                this.vaccine_center = data.vaccine_center

                if(!this.vaccine_center.batch_no){
                    this.vaccine_center.batch_no = [
                            {batch_no:''},
                    ];
                }
            }).catch(()=>{
            })
        },
        loadVaccine(){
            axios.get('/api/vaccines').then(({data})=>{
                this.vaccines = data.vaccines
            })
        }
    },
    
    created(){
        this.loadVaccine();
        Fire.$on("AfterCreate",()=>{
           this.loadData();
        })
        Fire.$on("validate",()=>{
            Fire.$emit('validation', this.$v.form.$invalid)
        })
        this.loadData();
       

    }
}
</script>
<style scoped>
.btn{
color:#000;
}
.btn:hover{
    box-shadow: unset;
}
.small-form-group .form-group{
    margin: 10px 0 ;
}

.form-control {
  border-radius: unset;
    padding: 16px;
    border: 1px solid #909090;

}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height:unset;
}

.form-group label {
  color: #616161;
}
</style>