export const translations = {
    'en': {
        // registration page
        'basic_form' : 'Basic Informations',

        'registration_form': 'Voluntary application form for the first dose of Covid-19 vaccine',

        'self_registration': 'Self Registration',
        'registration_for_other': 'Registration for other',
        'registration_for_organization_member': 'Registration through organization ',
        'organization_name': 'Organization Name',

        'first_name': 'First Name',
        'middle_name': 'Middle Name',
        'last_name': 'Last Name',

        'caste': 'Ethnicity',
        'dont_mention': 'Don\'t want to mention',
        'dalit': 'Dalit',
        'janajati': 'Janajati',
        'madhesi': 'Madheshi',
        'muslim': 'Muslim',
        'brahmn_chettri': 'Brahmin/Chhetri',

        'field_required': 'Field is required*',
        'field_numeric': 'Field should be numeric',
        'field_max': 'Field exceed max length',
        'field_min': 'Field must have atleast 10 characters',


        'dob_format_error_message': 'DOB must be (YYYY-MM-DD) format *',
        'dob_format_error': 'Invalid DOB format *',
        'invalid_email_format': 'Invalid email format',



        'select_options': '- Select -',

        'gender': 'Gender',
        'male': 'Male',
        'female': 'Female',
        'third_gender': 'Third Gender',

        'other': 'Others',
        'dob': 'Date of Birth',
        'dat_type': 'Date Type',
        'ad': 'A.D',
        'bs': 'B.S',
        'age': 'Age ( in years )',
        'age_search': 'Age',

        'province': 'Province',
        'select_province': 'Select Province',
        'district': 'District',
        'select_district': 'Select District',
        'municipality': 'Local Level Government',
        'select_municipality': 'Select Municipality',
        'ward': 'Ward No.',
        'tole': 'Tole',
        'email': 'E-mail Address',
        'mobile': 'Mobile No.',
        'nationality': 'Nationality',

        'identity_type': 'Identity Type',
        'identification_no' : 'ID No.',
        'issue_org': 'Issue Office',
        'issue_date': 'Issue Date',

        'select_identity_type': 'Select your Identity Type',
        'citizenship': 'Citizenship',
        'driving_license': 'Driving License',
        'disability_id': 'Disability ID Card',
        'voters_card': 'Election card',
        'passport': 'Passport',
        'national_id': 'National ID',

        'occupation': 'Occupation',
        'airlines' : 'Airlines / Agent',
        'army' : 'Army',
        'bank' : 'Bank / Finance',
        'commercial_facilities' : 'Commercial facilities(Wholesale, Retail, Business, Entertainment)',
        'daily_wage_worker' : 'Daily Wages worker',
        'farmer' : 'Farmer / Food and Agriculture',
        'government_employee' : 'Government Employee',
        'grocery' : 'Grocery Store',
        'health_care' : 'Health Care Provider, Medical, Pharmacy etc',
        'health_professional' : 'Health Professional',
        'it_and_communication' : 'IT and Communication',
        'journalist_media' : 'Journalist / Media',
        'manufacturing' : 'Manufacturing',
        'ngo_ingo' : 'NGO/INGO Employee',
        'online_business' : 'Online Business / Delivery',
        'police' : 'Police',
        'Security_person' : 'Security person',
        'social_service' : 'Social Service',
        'student' : 'Student',
        'teacher_professor' : 'Teacher / Professor',
        'tourist' : 'Tourism Industry',
        'transport_sector' : 'Transport Sector / Logistic',
        'unemployed' : 'Unemployed',
        'diplomat' : 'Diplomat',
        'veterinarians' : 'Veterinarians / Veterinary Professionals',
        'foreign_employment' : 'Foreign Employment',

        'select_medical_condition': 'Select Medical Conditions',
        'normal_health': 'Normal Health Condition',
        'blood_pressure': 'Blood Pressure (High/ Low)',
        'cancer': 'Cancer',
        'chronic_kidney_disease': 'Chronic kidney disease',
        'chronic_resp_disease': 'Chronic respiratory diseases / Lung Diseases',
        'cardiovascular_disease': 'Cardio related disease',
        'diabetes': 'Diabetes',
        'nerve_disease': 'Neuro related diseases',
        'pregnant': 'Pregnant',
        'tb': 'Tuberculosis (TB)',
        'mental_disease': 'Mental Disease',
        'covid': 'Already Covid-19 infected',
        'hiv_aids' : 'HIV / AIDS',
        'none_of_above': 'Other',

        'disability' : 'Disability',
        'blindfolded' : 'Blindfolded',
        'without_both_hands' : 'Without Both Hands',
        'speech_disability' : 'Speech Disability',
        'both_ears_are_deaf' : 'Both ears are deaf',
        'unable_to_walk' : 'Unable to walk',

        'vaccination_detail': 'Vaccination Detail',
        'first': 'First',
        'second': 'Second',
        'first_or_second_vaccine': 'Is this your first or second vaccine?',
        'disability_type': 'Choose if person is disabled',
        'vaccinated_current_address': 'I want to be vaccinated in my current address',
        'vaccinated_other': 'No, I want to be vaccinated in another place',
        'dont_know' : 'Don\'t Know',

        'mentioned_detail_correct': 'All the above mentioned details are correct. Please apply (☑️). *',
        'submit': 'Submit',
        'update': 'Update',
        'date_first_vaccinated': 'Date of First Vaccinated(B.S.)',
        'vaccinated_address': 'Vaccinated Address',
        'vaccination_center': 'Vaccination Center',
        'choose_vaccine_center': 'Choose where you want to be vaccinated',
        'confirmation_title': 'Do you want to save this details ?',
        'error_msg': 'Please fill all the required fields.',
        'vaccinte_type': 'Vaccine Type',

        'form_note' : 'After submitting the form, the registered person is requested to come to the vaccination center during the vaccination campaign with the registration card or registration number along with the identity card that verifies the identity of the person.',
        'advanced_option' : 'Show Advanced Fields',
        'vaccination_status' :'Show Vaccination Status',
        'aefi_status' : 'AEFI Status',
        'age_min' : "Minimum Age",
        'age_max' : "Maximum Age",
        'invalid_age' : 'Minimum required age is 5',

        // confirmation page
        'register_for': 'Register For',
        'name': 'Name',
        'current_address': 'Current Address',
        'medical_condition': 'Medical Condition',
        'vaccine_type': 'Vaccine Type',
        'edit': 'Edit',
        'save': 'Save',
        'personal_detail': 'Personal Detail',
        'identity_no': 'Citizenship No./Driving License No./Election card No.',
        'registration_no_is': 'Your registration number is : ',
        'success_msg': 'Thank you for your personal details. Please keep your registration number and this vaccine card safe. Please bring this vaccine card for vaccination.',
        'success_msg_org' : 'Thank you here is you details. Please keep your registration number safe.',


        // search page
        'normal_search' : 'Normal Search',
        'advanced_search': "Advanced Search",
        "identity_search": 'Search By Identity Card',
        'registration_no': 'Registration No.',
        'search_title': 'Search',
        'data_found': 'Data Found Successfully',
        'data_not_found': 'Data Not Found',
        'print_card': 'Print Card',
        'print_vaccination_report' : 'Print Vaccination Report',
        'scan_qr': 'Scan through QR Code',

        //filtered list
        'vaccinated' : 'Vaccinated',
        'filtered_client' : 'Filtered Client List',
        'first_dose': 'First Dose',
        'second_dose' : 'Second Dose',
        'third_dose' : 'Additional Dose',
        'fourth_dose' : 'Second Additional Dose',

        'vaccination_status' : 'Vaccination Status',
        'new_registration' : 'New Registration',
        'update_detail': 'Update Client Detail',
        'basic_information' : 'Basic Information',
        'advanced_information' : 'Advanced Information',
        'aefi_type' : 'AEFI Type',
        'moderate' : 'Moderate',
        'severe' : 'Severe',
        'remarks' : 'Remarks',
        'add_new_client' : 'Add New Client',
        'show' : 'Show',
        'not_vaccinated' : 'Not Vaccinated',
        'vaccinated_date' : 'Vaccinated Date',
        'vaccinated_address' : 'Vaccinated Address',
        'client_search' : 'Search Client',
        'age_range' : 'Age Range',
        'action' : 'Action',
        'vaccinated_date_difference' : 'First vacciantion date and second vaccination date difference should be minmium 21 days',
        'vaccinated_date_difference_for_third_dose' : 'Second vacciantion date and third vaccination date difference should be minmium 6 months',
        'date': 'Date',
        'type' : 'Type',
        'batch_no' : "Batch No.",
        'first_vaccine_is_from_foreign': 'If first dose have been received in abroad?',

        //download data page
        'choose_municipality_download': 'Choose the municipality you want to download the data from',
        'download_data': 'Download All Data',
        'select_age_range' : 'Select Age Range',
        'age_range_validation' : 'Age range must be between 5 to 120 years',
        'vaccinated_data' : 'Vaccinated Data',
        'planning_data': "Not Vaccinated Data",

        // client create page after login
        'want_to_vaccinate_now': 'Do you want to add vaccination record now?',
        'vaccination_date': 'Vaccination Date',
        'vaccination_address': 'Vaccination Address',
        'address': 'Address',
        'yes': 'Yes',
        'no': 'No',
        'second_vaccine_is_from_foreign' : 'If second dose has been received from foreign.',
        'update_msg' : 'Data updated successfully',

    },
    'np': {
        // registration page

        'basic_form' : 'Basic Informations',
        'registration_form': 'कोभिड-१९ पहिलो डोज खोपको लागि स्वैच्छिक आवेदन फारम',

        'self_registration': 'स्वयं आवेदन दर्ता',
        'registration_for_other': 'अन्य ब्यक्ति मार्फत आवेदन दर्ता',
        'registration_for_organization_member': 'संस्थामार्फत आवेदन दर्ता ',
        'organization_name': 'संस्थाकाे नाम',

        'first_name': 'पहिलो नाम',
        'middle_name': 'बीचको नाम',
        'last_name': 'थर',

        'caste': 'जात / जाती',
        'dont_mention': 'उल्लेख गर्न चाहान्न',
        'dalit': 'दलित',
        'janajati': 'जनजाति',
        'madhesi': 'मधेसी',
        'muslim': 'मुस्लिम',
        'brahmn_chettri': 'ब्राह्मण / क्षेत्री',

        'field_required': 'अनिवार्य भर्नु पर्ने*',
        'field_numeric': 'Field should be numeric',
        'field_max': 'Field exceed max length',
        'field_min': 'Field must have atleast 10 characters',
        'dob_format_error_message': 'जन्म मिति (YYYY-MM-DD) ढाँचा हुनै पर्छ *',
        'dob_format_error': 'जन्म मिति गलत ढाँचा *',


        'select_options': '- छान्नुहोस -',

        'gender': 'लिङ्ग',
        'select_gender': 'लिङ्ग',
        'male': 'पुरुष',
        'female': 'महिला',
        'third_gender': 'तेश्रो लिङ्गी',
        'other': 'अन्य',
        'dob': 'जन्म मिति',
        'dat_type': 'मिति',
        'ad': 'ई. सं.',
        'bs': 'वि. सं.',
        'age': 'पुरा भएको उमेर ( वर्षमा )',
        'age_search' : 'उमेर',

        'province': 'प्रदेश',
        'select_province': 'प्रदेश छान्नुहोस्',
        'district': 'जिल्ला',
        'select_district': 'जिल्ला छान्नुहोस्',
        'municipality': 'पालिका',
        'select_municipality': 'नगरपालिका छान्नुहोस्',
        'ward': 'वार्ड नं',
        'tole': 'टोल',
        'email': 'ई-मेल',
        'mobile': 'मोबाइल नं',
        'nationality': 'राष्ट्रीयता',

        'identity_type': 'परिचयको प्रकार',
        'identification_no': 'परिचय नं.',
        'issue_org': 'जारी गरिएको कार्यालय',
        'issue_date': 'जारी गरिएको मिति',

        'select_identity_type': 'परिचयको प्रकार छान्नुहोस्',
        'citizenship': 'नागरिकता',
        'disability_id': 'अपाङ्गता प्रमाण पत्र',
        'driving_license': 'सवारीचालक अनुमतिपत्र',
        'voters_card': 'मतदाता परिचय पत्र',
        'passport': 'पासपोर्ट',
        'national_id': 'राष्ट्रिय परिचयपत्र',

        'occupation': 'पेशा',
        'airlines' : 'वायुसेवा / एजेन्ट',
        'army' : 'सेना',
        'bank' : 'बैंक / वित्तीय',
        'commercial_facilities' : 'व्यवसायिक सेवा (खुद्रा, थोक बिक्रेता, व्यापार, मनोरन्जन)',
        'daily_wage_worker' : 'दैनिक ज्यालादारी मजदुर',
        'farmer' : 'किसान / खाद्य र कृषि',
        'government_employee' : 'सरकारी कर्मचारी',
        'grocery' : 'किराना स्टोर / शपिंग सेन्टर मल',
        'health_care' : 'स्वास्थ्य सेवा प्रदायक, चिकित्सा, फार्मेसी',
        'health_professional' : 'स्वास्थ्य व्यावसायिक',
        'it_and_communication' : 'आईटी र संचार',
        'journalist_media' : 'पत्रकार / मिडिया',
        'manufacturing' : 'निर्माण',
        'ngo_ingo' : 'एनजीओ / आईएनजीओ कर्मचारी',
        'online_business' : 'अनलाइन व्यवसाय / डेलिभरी',
        'police' : 'प्रहरी',
        'Security_person' : 'सुरक्षासँग सम्बन्धित सेवा',
        'social_service' : 'सामाजिक सेवा',
        'student' : 'विद्यार्थी',
        'teacher_professor' : 'शिक्षक / प्राध्यापक',
        'tourist' : 'पर्यटन उद्योग',
        'transport_sector' : 'यातायात क्षेत्र / लजिस्टिक',
        'unemployed' : 'बेरोजगार',
        'diplomat' : 'कूटनीतिक',
        'veterinarians' : 'भेटेरिनरी चिकित्शक /भेटेरिनरी ब्यबशायी',
        'foreign_employment' : 'वैदेशिक रोजगार',


        'select_medical_condition': 'तपाईंको हालको स्वास्थ्य अवस्था बारे छान्नुहोस (आवश्यकता अनुसार एक भन्दा बढि बिकल्प छान्न सक्नु हुन्छ)',
        'normal_health': 'सामान्य स्वास्थ्य अवस्था',
        'blood_pressure': 'रक्तचाप (उच्च / कम)',
        'cancer': 'क्यान्सर',
        'chronic_kidney_disease': 'दीर्घकालीन मिर्गौला रोग',
        'chronic_resp_disease': 'दीर्घकालीन श्वास / फोक्सोसम्बन्धि रोगहरू',
        'cardiovascular_disease': 'मुटुसम्बन्धी रोगहरू',
        'diabetes': 'मधुमेह (चिनि) रोग',
        'nerve_disease': 'नसासम्बन्धि रोगहरु',
        'pregnant': 'गर्भवती',
        'tb': 'क्षयरोग (टीबी)',
        'mental_disease': 'मानिसक रोग',
        'covid': 'कोभिड-१९ भैसकेको',
        'hiv_aids': 'एचआईभी / एड्स',
        'none_of_above': 'अन्य',

        'disability' : 'अपाङ्गता',
        'blindfolded' : 'आँखा नदेख्ने',
        'without_both_hands' : 'दुबै हात नभएको',
        'speech_disability' : 'बोल्न नसक्ने',
        'both_ears_are_deaf' : 'दुबै कान नसुन्ने',
        'unable_to_walk' : 'हिडडुल गर्न नस्ने',
        'batch_no' : "ब्याच नं.",


        'vaccination_detail': 'खोप विवरण',
        'first': 'पहिलो',
        'second': 'दोस्रो',
        'first_or_second_vaccine': 'यो तपाईंको पहिलो वा दोस्रो कुन खोप हो?',
        'disability_type': 'अपाङ्गता भएका व्यक्ति भए छान्नुहोस्',
        'vaccinated_current_address': 'म हालसालै बसिरहेको ठेगानामा खोप लिन चाहन्छु।',
        'vaccinated_other': 'म अन्य ठाउँमा खोप लगाउन चाहन्छु।',
        'dont_know' : 'थाहा छैन',

        'mentioned_detail_correct': 'उपरोक्त सबै विवरण ठीक छ भने ( ✔) लगाउनुहोस *',
        'submit': 'पेश गर्नुहोस्',
        'update' : 'अद्यावधिक गर्नुहोस्',
        'date_first_vaccinated': 'पहिलो खोपको मिति (वि. सं.)',
        'vaccinated_address': 'खोप लगाएको ठेगाना',
        'vaccination_center': 'खोप केन्द्र',
        'choose_vaccine_center': 'तपाईं कहाँ खोप लगाउन चाहानुहुन्छ रोज्नुहोस्',
        'confirmation_title': 'के तपाइँ यस विवरणहरू बुझाउन चहानुहुन्छ ?',
        'error_msg': 'कृपया सबै आवश्यक फिल्डहरू भर्नुहोस्।',
        'vaccine_type': 'खोपको प्रकार',


        'form_note' : 'पेश गरिसकेपछि दर्ता कार्ड वा दर्ता नं र तपाईंको परिचय खुल्ने परिचयपत्र सहित खोप अभियान संचालन भएको वखत, खोप केन्द्रमा आउनुहुन अनुरोध छ',
        'advanced_option' :'अन्य विवरणहरू',
        'vaccination_status' :'खोप विवरण देखाउनुहोस्',
        'aefi_status' : 'AEFI Status',
        'type' : 'Type',
        'age_min' : "न्यूनतम उमेर",
        'age_max' : "अधिकतम उमेर",
        'first_vaccine_is_from_foreign': 'यदि पहिलो डोज बिदेशमा लगाएर आएको हो भने?',
        'invalid_age' : '५ बर्ष पुरा भएको हुनु पर्छ।',

        // confirmation page
        'register_for': 'कसको लागि दर्ता',
        'name': 'नाम',
        'current_address': 'हालसालै बसोबास गरिरहेको ठेगाना',
        'medical_condition': 'चिकित्साको स्थिति',
        'vaccine_type': 'खोप प्रकार',
        'edit': 'सम्पादन गर्नुहोस्',
        'save': 'प्रविष्ट गर्नुहोस्',
        'personal_detail': 'व्यक्तिगत विवरण',
        'identity_no': 'नागरिकता नं / ड्राइभिंग इजाजतपत्र नं / चुनाव कार्ड नम्बर',
        'registration_no_is': 'तपाईंको दर्ता नम्बर : ',
        'success_msg': 'धन्यबाद तपाईंको ब्यक्तिगत बिबरण प्राप्त भयो। कृपया आफ्नो दर्ता नम्बर र यो खोप कार्ड सुरक्षित राख्नुहोस. खोप लगाउनको लागि यो खोपकार्ड अनिवार्य लिएर आउनु होला।',
        'success_msg_org' : 'धन्यबाद तपाईंको बिबरण प्राप्त भयो। कृपया  दर्ता नम्बर  सुरक्षित राख्नुहोस।',

        //search page
        'normal_search' : 'Normal Search',
        'advanced_search': "Advanced Search",
        'registration_no': 'दर्ता नम्बर',
        'identity_search' : 'परिचयको प्रकार द्वारा खोज्नुहोस्',
        'search_title': 'खोज्नुहोस्',
        'data_found': 'डाटा सफलतापूर्वक फेला पर्‍यो',
        'data_not_found': 'डाटा भेटिएन',
        'print_card': 'कार्ड प्रिन्ट गर्नुहोस्',
        'print_vaccination_report' : 'रिपोर्ट कार्ड प्रिन्ट गर्नुहोस्',
        'scan_qr': 'QR कोड मार्फत स्क्यान गर्नुहोस्',

        //filtered list
       'vaccinated' : 'Vaccinated',
        'filtered_client' : 'Filtered Client List',
        'first_dose': 'First Dose',
        'second_dose' : 'Second Dose',
        'third_dose' : 'Additional Dose',
        'fourth_dose' : 'Second Additional Dose',


        'vaccination_status' : 'Vaccination Status',
        'new_registration' : 'New Registration',
        'update_detail': 'Update Client Detail',
        'basic_information' : 'Basic Information',
        'advanced_information' : 'Advanced Information',
        'aefi_type' : 'AEFI Type',
        'moderate' : 'Moderate',
        'severe' : 'Severe',
        'remarks' : 'Remarks',
        'add_new_client' : 'Add New Client',
        'show' : 'Show',
        'not_vaccinated' : 'Not Vaccinated',
        'vaccinated_date' : 'Vaccinated Date',


        'vaccinated_address' : 'Vaccinated Address',
        'client_search' : 'Search Client',
        'age_range' : 'Age Range',
        'action' : 'Action',
        'vaccinated_date_difference' : 'First vacciantion date and second vaccination date difference should be minmium 21 days',
        'vaccinated_date_difference_for_third_dose' : 'Second vacciantion date and third vaccination date difference should be minmium 6 months',

        'date': 'Date',
        // download page
        'choose_municipality_download': 'डाटा डाउनलोड गर्न चाहानु भएको नगरपालिका छनौट गर्नुहोस्',
        'download_data': 'डाटा डाउनलोड गर्नुहोस्',
        'select_age_range' : 'उमेर समूह छान्नुहोस',
        'age_range_validation' : 'Age range must be between 5 to 120 years',
        'vaccinated_data' : 'Vaccinated Data',
        'planning_data': "Not Vaccinated Data",


        // client create page after login
        'want_to_vaccinate_now': 'के तपाइँ अहिले खोप लगाउनु चाहानुहुन्छ?',
        'vaccination_date': 'खोपको मिति',
        'vaccination_address': 'खोपको ठेगाना',
        'address': 'ठेगाना',

        'yes': 'छ',
        'no': 'छैन',
        'invalid_email_format': 'ई-मेलको ढाँचा गलत भयो',
        'second_vaccine_is_from_foreign' : 'If second dose have been received in abroad? ',

        'update_msg' : 'बिबरण सफलतापूर्वक अपडेट भयो',
    }
}
