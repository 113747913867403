import Vue from 'vue'
import PublicForm from './components/public-register.vue'
import '../css/formulate.css'
import vueCountryRegionSelect from 'vue-country-region-select'
import Vuelidate from 'vuelidate'
import Dialog from 'v-dialogs'
import VueSimpleAlert from "vue-simple-alert";
import VueMask from 'v-mask'
import PublicSearch from './components/public-search.vue'
import VueI18n from 'vue-i18n'
import {translations} from './translations.js'
import _ from 'lodash';
import PublicEdit from './components/public-edit.vue'
import VueQrcodeReader from "vue-qrcode-reader";
import Download from './components/download.vue'
import AddVaccination from './components/add-vaccination.vue'
import PublicList from './components/public-list.vue'
import VueWait from 'vue-wait'
import ClientCreate from './components/client-create.vue'
import MunicipalitySignCreate from './components/municipality-sign-create.vue'
import vaccinationReport from './components/vaccination-report.vue'
import PreviewModal from './components/modal/preview-modal.vue'
import ItemCategory from './components/item-category.vue'
import InventroyItem from './components/inventory-item.vue'
import CreateVaccineCenter from './components/create-vaccine-center.vue'

import VaccinationSetting from './components/vaccination-setting.vue'
import PublicVerification from './components/public-verification.vue'
import ResubmittedForm from './components/resubmitted-form.vue'
import SearchUpdate from './components/search-update.vue'
import OrganizationClientList from './components/organization-public-list.vue'
import PostRegistration from './components/post-registration.vue'
import VerifiedClients from './components/verified-clients.vue'
import DisapprovedClients from './components/disapproved-clients.vue'
import SearchClients from './components/search-clients.vue'
import InvestigatorSearch from './components/investigator-search.vue'
import Monitoring from './components/monitoring.vue'

import PreRegisteredClientsVerification from './components/pre-registered-verification'
import UpdateRequest from './components/update-request'
import NoticeManagement from './components/notice'
import MunicipalityDashboard from './components/municipality/dashboard'



import { VueEditor } from "vue2-editor";

/*Moment JS to format Date*/
import moment from 'moment'; //format date in vue

Vue.filter('myDate', function(created){
    return moment(created).format('YYYY / MM  / DD'); // April 7th 2019,(h:mm:ss a) 3:34:44 pm
});

Vue.filter('myDateTime', function(created){
    return moment(created).format('YYYY / MM  / DD h:mm:ss a'); // April 7th 2019,(h:mm:ss a) 3:34:44 pm
});

Vue.filter('duration', function(created){
    return moment(created).toNow('UTC'); // April 7th 2019,(h:mm:ss a) 3:34:44 pm
});


window.Event = new Vue();
// window.axios = require('axios');

Vue.prototype.trans = string => _.get(window.i18n, string);

//json view
import JsonViewer from 'vue-json-viewer'

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)

//carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

Vue.prototype.$user = window.user

Vue.prototype.$token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNTYzMDlmYjllOGJkNTQwZjk1OTk1NjRmYTRkNjM1OTIzYjU2MjJkMzQ2OWUyOGRmODg1NzNlMGMyZGIwOTNiNzdjNzM1NDMyNzUyMDczMmUiLCJpYXQiOjE2NzI5NjA4NDUuMTM3NzAxLCJuYmYiOjE2NzI5NjA4NDUuMTM3NzA0LCJleHAiOjE3MDQ0OTY4NDUuMTMyNzY5LCJzdWIiOiIxNTU0MiIsInNjb3BlcyI6W119.kFEbj4y5T66lix-MNzfQBb5YIQe42opP8YOsJMC1gdZFwbQz0-M7xakhPBvXInhiDD6Hi4VqwqPQDbMZZh2f79fWVOz1bbfIhP8YzWYBoeg7jkXIMqIPqD4JKtu_lsgqoLRs63JCjowOO_J6wD2W0VUbmw40IOUxfjKa_yKPAtH42Fo2V9VXNiVyspRJJp4uvco4jOw7sZyB4bCTGmleI8Tl1ykNDH7OqByDzmp1hxCxiInrHIK3pnduc6I7VxrU5FwEcTImp_yTS4c2sNb5wXDyy0vgeFw00mJuUlOMboOgkw8uD_wp6yDTgqxPrYuWrMRwM-bV3FyRM3GaGiG3fnbAzRynabqN7e-F5bHDdgRctsT_NtergRPJNdSy2eGckaKpubTOTojQYnkDHeYqxVj9HVueMFIa6mUWPeS9CE8hfGNohEfvI0IhsjSX1A2rMs8VfG0pJicXAROQKxoS07RFHl3-4ynZN8P0Kk88qjLdt3OxuMWAaRhT-2nMn5gN5C9YAGqtPtZGNy-wQH-mGQS6Evd7C4WtdPN5eAmvZqKnub6txwQqoSIhmiANl4Pe0e_MaLor5uXIWfVKtsE1bum7nSIAgkS45h_Cw2zbpAzGLkC-zCsEpxK7n0QhthrPnXxmllJ5RCtUGxZWnR75i0kxRc06GD0ErtqFgHAqTEQ'
Vue.prototype.$local = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNDIyMDg0YWZlYTVhM2Q5MWE2NjRjZDVmNDYyMTQ4ZTY4OTQ5Njc3ZDU5NGNiY2U5ZWZhYmMzMzMzYjE1ZTdlNmQ4MWU1MWI3NWNhMjRmMDgiLCJpYXQiOjE2NDE0NDYzMDIuODgyMTk3LCJuYmYiOjE2NDE0NDYzMDIuODgyMjA2LCJleHAiOjE2NzI5ODIzMDIuODU4MDIzLCJzdWIiOiIxNDAzNyIsInNjb3BlcyI6W119.Pv72ESAT9LqtR1i12eldTJM-LfwlK46P5jlDxYAbhwrIlnRLlwoxlU_gdK_N17t96MT8tLZGiWnvdWEastApwZk6HoZTxx9DdMcGLOfDNCTv1bJm45SwY9dckbBeSI0wLevNrIVFTNLHJK0-ttGRLwr26JaVd13MwZMQ-84U7K1mj1XQw3JkDhFjF9lw3aU4DQuczGMet8a7cz4-U45GGJ8L19pSWRr1z0Ra7CRN4hUOX-BWFB-YcKR90b_t7iuNGNHCpEyJxQHJFfW5akp2QsdrVpp6LJH1KP6-4shNRnf3oXUih75jQ8niD76X8zLBdwmtGUDXnvjO7tao6J8PKLHS2FNoddMVpCZyB-vPBlMane4fETdvvWUvesfWX6kcwnIMMJoPkPDwtoSNOefJ7z2a20Iv2pw2Ch_7uL-VIcZYnoCxKyzX5ia33EsLFcunptigKjl2Hxm76En4l2P3IxAX4HEKauDshcRNTRDC0MHTeLzRmi_DGVPARx5GqCCyZa-e5cshuTU6Y-yeW2NIpk3nURz04UFfz3LEc4Kxl5JZTVJIir3_BDAXdadX3YvtsBgFBkyt_5yFkfqso5Uh5tMKdnOwKTLKiA0ShOy1sikEoLtugSez7IiZiW-gUsb0zZ18k7Z1eZvDLODObumrVWcQO4omh0LmndsnTpjf8pQ'


Vue.use(VueSimpleAlert);
Vue.component('public-form', PublicForm);
Vue.component('public-search', PublicSearch);
Vue.component('public-edit', PublicEdit);
Vue.component('public-list', PublicList);
Vue.component('download', Download);
Vue.component('add-vaccination', AddVaccination);
Vue.component('client-create', ClientCreate);
Vue.component('vaccination-report', vaccinationReport);
Vue.component('preview-modal', PreviewModal);
Vue.component('item-category', ItemCategory);
Vue.component('inventory-item', InventroyItem);

Vue.component('create-vaccine-center', CreateVaccineCenter);
Vue.component('vaccination-setting', VaccinationSetting);
Vue.component('public-verification', PublicVerification);
Vue.component('resubmitted-form', ResubmittedForm);
Vue.component('search-update', SearchUpdate);
Vue.component('organization-client-list', OrganizationClientList);
Vue.component('post-registration', PostRegistration);
Vue.component('verified-clients', VerifiedClients);
Vue.component('disapproved-clients', DisapprovedClients);
Vue.component('search-clients', SearchClients);
Vue.component('investigator-search', InvestigatorSearch);

Vue.component('pre-registered-cleints-verification', PreRegisteredClientsVerification);

Vue.component('update-request', UpdateRequest);
Vue.component('notice-management', NoticeManagement);
Vue.component('monitoring', Monitoring);
Vue.component('municipality-dashboard', MunicipalityDashboard);

/*Start of Progress Bar*/
// Progress bar include
import VueProgressBar from 'vue-progressbar';
/*Define Options for progress bar*/
const options = {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '8px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false
};
/*End of defination*/
/*Call the progress bar*/
Vue.use(VueProgressBar, options)
/*End of Progress Bar*/



let Fire = new Vue();
window.Fire = Fire;
Vue.component('pagination', require('laravel-vue-pagination'));

//vform
import { Form, HasError, AlertError } from 'vform'
window.Form = Form;

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
//v-calendar

import VCalendar from 'v-calendar';
 Vue.use(VCalendar, {
  });
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

// Register components in your 'main.js'
Vue.component('date-picker', DatePicker)

Vue.component('municipality-sign-create', MunicipalitySignCreate);
Vue.use(vueCountryRegionSelect)
Vue.use(Vuelidate)
Vue.use(Dialog);
Vue.use(VueMask);
Vue.use(VueI18n);
Vue.use(VueQrcodeReader);
Vue.use(VueWait)
// const _ = import('lodash');
window.Popper = require('popper.js');
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.$ = window.jQuery = require('jquery');
require('bootstrap');


let lang = sessionStorage.getItem('locale');
if (lang == null) {
    lang = 'en'
}
const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'np',
    messages: translations
});
import router from "./router/index";

const app = new Vue({
    el: '#app',
    i18n,
    router,
    methods: {
        print() {
            window.print();
        }
    },

    wait: new VueWait({
        useVuex: false,              // Uses Vuex to manage wait state
        vuexModuleName: 'wait',      // Vuex module name

        registerComponent: true,     // Registers `v-wait` component
        componentName: 'v-wait',     // <v-wait> component name, you can set `my-loader` etc.

        registerDirective: true,     // Registers `v-wait` directive
        directiveName: 'wait',       // <span v-wait /> directive name, you can set `my-loader` etc.

    }),
});

global.vm = app; //Define your app variable globally
import Functions from "./functions";

Vue.prototype.$functions = new Functions(app);
