<template>
  <div class="">
        <div class="row">
            <div class="" >
                <div class="card-body" >
                    <h3>Daily verification</h3>
                    <table class="table table-bordered ">
                        <tr>
                            <th>Username</th>
                            <th>Total Verified</th>
                        </tr>
                        <tr v-for="item in data" :key="item.id">
                            <td>{{item.user_details.username}}</td>
                            <td>{{item.total}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  data(){
    return {
      data:{}


    }
  },
  methods:{
    loadData(){
      axios.get('/monitoring').then(({data})=>{
        console.log(data);
            this.data = data
        }).catch(()=>{

        })
    },

  },
  created(){
    this.loadData()
  }
};
</script>
<style >

.fancy-btn{
  color: black;
  background: blanchedalmond;
  border: 1px solid #000;
}
.invisible-input{
    background-color: transparent;
  border: none;
  box-sizing: border-box;
  width: 100%;
}
input:focus{
     outline: none;
}
.invisible-input:focus {
  outline: none;
}
.vgt-table th {
      font-size: 15px !important;

 }
 .vgt-table td{
      font-size: 14px !important;

 }
 @media print{
    .hide-on-print{
        display: none;
    }
 }
</style>

