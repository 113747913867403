<template>
<div> 
    <div ref="form_public" v-if="!confirmed">
        <!--name-->
        <div class="container-flex">
            <div class="form-group" :class="{ 'has-error': $v.form.first_name.$error }">
                <input type="text" id="first_name" name="first_name"
                       v-model="form.first_name" required="required"/>
                <label for="first_name" class="control-label">{{ $t('first_name') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.first_name.required">{{ $t('field_required') }}</div>
            </div>
            <div class="form-group">
                <input type="text" id="middle_name" name="middle_name"
                       v-model="form.middle_name" required="required"/>
                <label for="middle_name" class="control-label">{{ $t('middle_name') }}</label><i class="bar"></i>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.form.last_name.$error }">
                <input type="text" id="last_name" name="last_name"
                       v-model="form.last_name" required="required"/>
                <label for="last_name" class="control-label">{{ $t('last_name') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.last_name.required">{{ $t('field_required') }}</div>
            </div>
        </div>

        <!--caste-->
        <div class="form-group" :class="{ 'has-error': $v.form.caste.$error }">
            <select id="caste" name="caste" v-model="form.caste" required="required">
                <option value="">{{ $t('select_options') }}</option>
                <option value="0">{{ $t('dalit') }}</option>
                <option value="1">{{ $t('janajati') }}</option>
                <option value="2">{{ $t('madhesi') }}</option>
                <option value="3">{{ $t('muslim') }}</option>
                <option value="4">{{ $t('brahmn_chettri') }}</option>
                <option value="5">{{ $t('other') }}</option>
            </select>
            <label for="caste" class="control-label">{{ $t('caste') }}</label>
            <i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.caste.required">{{ $t('field_required') }}</div>
        </div>

        <!--gender-->
        <div class="form-group" :class="{ 'has-error': $v.form.gender.$error }">
            <select id="gender" name="gender" v-model="form.gender" required="required">
                <option value="">{{ $t('select_options') }}</option>
                <option value="1">{{ $t('male') }}</option>
                <option value="2">{{ $t('female') }}</option>
                <option value="3">{{ $t('third_gender') }}</option>
            </select>
            <label for="gender" class="control-label">{{ $t('gender') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.gender.required">{{ $t('field_required') }}
            </div>
        </div>

        <!--date of brith-->
        <div class="container-flex" style="margin-top: -30px;">
            <div class="date-type">
              <label class="control-label">{{ $t('dat_type') }}</label>
              <input class="radio-group" type="radio" id="bs" name="dob" value="1" v-model="form.dob_type"
                    v-on:change="toggleDob()">
              <label for="dob" class="text-sm">{{ $t('bs') }}&nbsp;&nbsp;</label>
              <input class="radio-group" type="radio" id="ad" name="dob" value="2" v-model="form.dob_type"
                    v-on:change="toggleDob()">
              <label for="dob" class="text-sm">{{ $t('ad') }}</label>

            </div>
            <div class="form-group"   :class="{ 'has-error': $v.date_of_birth.$error }">
              <input type="text" autocomplete="off" v-if="form.dob_type == '1'" id="date_of_birth" name="date_of_birth" required="required"  class="has-value"
                    v-mask="'####-##-##'" v-model.trim="date_of_birth" value="" >

                    <v-date-picker v-else v-model="date_of_birth" :max-date='Date.now() - 18*31556952000' :model-config="modelConfig" :masks="masks">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="has-value"
                          v-mask="'####-##-##'"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                
              <label for="date_of_birth" class="control-label date-label">{{ $t('dob') }} ( e.g. 2050-02-09 )</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.date_of_birth.required">{{ $t('field_required') }}</div>
              <div class="help-block text-danger" v-if="!$v.date_of_birth.date">{{ $t('dob_format_error') }}</div>


            </div>
            <div class="form-group" :class="{ 'has-error': $v.age.$error }">
                <input type="number" id="Age" name="Age"
                       :value="age" required="required" min="0"/>
                <label for="Age" class="control-label date-label">{{ $t('age') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.age.required">{{ $t('field_required') }}</div>
              <div class="help-block text-danger" v-if="!$v.age.between">{{ $t('invalid_age') }}</div>

            </div>
        </div>

        <!--current address(province, district, municipality)-->
        <p class="vaccine_label" style="margin-top: 30px;"><b>{{ $t('current_address') }}</b></p>
        <div class="container-flex3" style="margin-top: -30px;">
            <div class="form-group" :class="{ 'has-error': $v.form.province_id.$error }">
                <select name="province_id" id="province_id"
                        v-on:change="fetchDistricts(form)" v-model="form.province_id">
                    <option value="">{{ $t('select_options') }}</option>
                    <option v-for="data in provinces" :value="data.id">{{ data.province_name }}</option>
                </select>
                <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.province_id.required">{{ $t('field_required') }}
                </div>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.form.district_id.$error }">
                <select name="district_id" id="district_id"
                        v-on:change="fetchMunicipalities(form)" v-model="form.district_id">
                    <option value="">{{ $t('select_options') }}</option>
                    <option v-for="data in districts" :value="data.id">{{ data.district_name }}</option>
                </select>
                <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.district_id.required">{{ $t('field_required') }}
                </div>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.form.municipality_id.$error }">
                <select name="municipality_id" v-model="form.municipality_id"
                        id="municipality_id" >
                    <option value="">{{ $t('select_options') }}</option>
                    <option v-for="data in municipalities" :value="data.id">{{ data.municipality_name }}
                    </option>
                </select>
                <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.municipality_id.required">{{ $t('field_required') }}
                </div>
            </div>
        </div>

        <!--ward-->
        <div class="form-group" :class="{ 'has-error': $v.form.ward.$error }">
            <input type="number" id="ward" name="ward"
                   v-model="form.ward" required="required" min="1" max="35"/>
            <label for="ward" class="control-label">{{ $t('ward') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.ward.required">{{ $t('field_required') }}</div>
            <div class="help-block text-danger" v-if="!$v.form.ward.between">Invalid ward no.</div>
        </div>

        <!--tole-->
        <div class="form-group">
            <input type="text" id="tole" name="tole"
                   v-model="form.tole"
                   required="required"/>
            <label for="tole" class="control-label">{{ $t('tole') }}</label><i class="bar"></i>
        </div>

        <!--email-->
        <div class="form-group">
            <input type="email" id="email_address" name="email_address"
                   v-model="form.email_address"
                   required="required"/>
            <label for="email_address" class="control-label">{{ $t('email') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.email_address.email">{{$t('invalid_email_format')}}</div>

        </div>

        <!--phone-->
        <div class="form-group" :class="{ 'has-error': $v.form.phone.$error }">
            <input type="tel" id="phone" name="phone" v-model="form.phone"
                   required="required"/>
            <label for="phone" class="control-label">{{ $t('mobile') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.phone.required">{{ $t('field_required') }}</div>
            <div class="help-block text-danger" v-if="!$v.form.phone.maxLength">{{ $t('field_min') }}</div>
            <div class="help-block text-danger" v-if="!$v.form.phone.minLength">{{ $t('field_max') }}</div>
            <div class="help-block text-danger" v-if="!$v.form.phone.numeric">{{ $t('field_numeric') }}</div>

        </div>

        <!--nationality-->
        <div class="form-group" :class="{ 'has-error': $v.form.nationality.$error }">
            <country-select id="nationality" name="nationality" v-model="form.nationality"
                            :country="country"
                            :countryName=true
                            :usei18n=false
                            topCountry="Nepal"/>
            <label class="control-label">{{ $t('nationality') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.form.nationality.required">{{
                    $t('field_required')
                }}
            </div>
        </div>

        <!--identity type, identity no-->
        <div class="flex-identity">
            <div class="form-group" :class="{ 'has-error': $v.form.identity_type.$error }">
                <select id="identity_type" name="identity_type" v-model="form.identity_type">
                    <option value="" disabled>{{ $t('select_options') }}</option>
                    <option value="Citizenship">{{ $t('citizenship') }}</option>
                    <option value="National ID">{{ $t('national_id') }}</option>
                    <option value="Driving License">{{ $t('driving_license') }}</option>
                    <option value="Election card">{{ $t('voters_card') }}</option>
                    <option value="Passport">{{ $t('passport') }}</option>
                    <option value="Disability ID Card">{{ $t('disability_id') }}</option>
                    <option value="Others">{{ $t('other') }}</option>
                </select>
                <label for="identity_type" class="control-label">{{ $t('identity_type') }}</label><i
                class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.identity_type.required">{{
                        $t('field_required')
                    }}
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.form.identity_no.$error }">
                <input type="text" id="identity_no" name="identity_no"
                       v-model="form.identity_no" required="required"/>
                <label for="identity_no" class="control-label">{{ $t('identification_no') }} ( e.g. 1234-56)</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.identity_no.required">{{
                        $t('field_required')
                    }}
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.form.issue_org.$error }">
                <input type="text" id="issue_org" name="issue_org"
                       v-model="form.issue_org" required="required"/>
                <label for="issue_org" class="control-label">{{ $t('issue_org') }}</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.form.issue_org.required">{{ $t('field_required') }}</div>
            </div>
        </div>
        <!--occupation-->
        <div class="container-flex1">
            <div class="form-group">
                <select id="occupation" name="occupation" v-model="form.occupation">
                    <option value="">{{ $t('select_options') }}</option>
                    <option value="1">{{ $t('airlines') }}</option>
                    <option value="2">{{ $t('army') }}</option>
                    <option value="3">{{ $t('bank') }}</option>
                    <option value="4">{{ $t('commercial_facilities') }}</option>
                    <option value="5">{{ $t('daily_wage_worker') }}</option>
                    <option value="31">{{ $t('diplomat') }}</option>
                    <option value="6">{{ $t('farmer') }}</option>
                    <option value="7">{{ $t('government_employee') }}</option>
                    <option value="8">{{ $t('grocery') }}</option>
                    <option value="9">{{ $t('health_care') }}</option>
                    <option value="10">{{ $t('health_professional') }}</option>
                    <option value="11">{{ $t('it_and_communication') }}</option>
                    <option value="12">{{ $t('journalist_media') }}</option>
                    <option value="13">{{ $t('manufacturing') }}</option>
                    <option value="14">{{ $t('ngo_ingo') }}</option>
                    <option value="15">{{ $t('online_business') }}</option>
                    <option value="16">{{ $t('police') }}</option>
                    <option value="17">{{ $t('Security_person') }}</option>
                    <option value="18">{{ $t('social_service') }}</option>
                    <option value="19">{{ $t('student') }}</option>
                    <option value="20">{{ $t('teacher_professor') }}</option>
                    <option value="21">{{ $t('tourist') }}</option>
                    <option value="22">{{ $t('transport_sector') }}</option>
                    <option value="23">{{ $t('unemployed') }}</option>
                    <option value="32">{{ $t('veterinarians') }}</option>
                    <option value="0">{{ $t('other') }}</option>
                    <!--                    <option v-for="data in occupation_list" :value="data.id">{{ data.name }}</option>-->
                </select>
                <label for="occupation" class="control-label">{{ $t('occupation') }}</label><i class="bar"></i>
            </div>
        </div>

        <!--medical condition-->
        <div class="medical_condition" :class="{ 'has-error': $v.form.medical_condition.$error }">
            <label class="medical_label" style="display: inline-block;"><b>{{ $t('select_medical_condition') }}</b>
            </label>
            <div class="help-block text-danger" v-if="!$v.form.medical_condition.required">{{ $t('field_required') }}</div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="toggleCheckbox(form, true)" v-model="form.medical_condition"
                           value="21"/><i
                    class="helper"></i>{{ $t('normal_health') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="8"/><i
                    class="helper"></i>{{ $t('blood_pressure') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="1"/><i
                    class="helper"></i>{{ $t('cancer') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="2"/><i
                    class="helper"></i>{{ $t('chronic_kidney_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="3"/><i
                    class="helper"></i>{{ $t('chronic_resp_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="4"/><i
                    class="helper"></i>{{ $t('cardiovascular_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="5"/><i
                    class="helper"></i>{{ $t('diabetes') }}
                </label>
            </div>
            <!--            <div class="checkbox">-->
            <!--                <label>-->
            <!--                    <input type="checkbox" v-model="data.medical_condition" value="6"/><i class="helper"></i>Allergies-->
            <!--                </label>-->
            <!--            </div>-->
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="9"/><i
                    class="helper"></i>{{ $t('nerve_disease') }}
                </label>
            </div>
            <div class="checkbox" hidden>
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="7"/><i
                    class="helper"></i>{{ $t('pregnant') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="11"/><i
                    class="helper"></i>{{ $t('tb') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="22"/><i
                    class="helper"></i>{{ $t('mental_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(form)" v-model="form.medical_condition"
                           value="31"/><i
                    class="helper"></i>{{ $t('hiv_aids') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="toggleCheckboxCovid(form)" v-model="form.medical_condition"
                           value="23"/><i
                    class="helper"></i>{{ $t('covid') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="toggleCheckbox(form, true)" v-model="form.medical_condition"
                           value="10"/><i class="helper"></i>{{ $t('none_of_above') }}
                </label>
            </div>
        </div>

          <div class="date-type">
            <label class="control-label"> {{ $t('disability') }}</label>
            <input class="radio-group" type="radio" value="1" v-model="is_disabled"
                    > {{ $t('yes') }} &nbsp;
            <input class="radio-group" type="radio" value="0" v-model="is_disabled"
                    > {{  $t('no') }}
          </div>

         <!--disability-->
        <div v-if="is_disabled === '1'" class="container-flex1">
            <div class="form-group">
                <select id="disability" name="disability_type" v-model="form.disability_type">
                    <option value="">{{ $t('select_options') }}</option>
                    <option value="1">{{ $t('blindfolded')}}</option>
                    <option value="2">{{ $t('without_both_hands') }}</option>
                    <option value="3">{{ $t('speech_disability') }}</option>
                    <option value="4">{{ $t('both_ears_are_deaf') }}</option>
                    <option value="5">{{ $t('unable_to_walk')}}</option>
                    <option value="6">{{ $t('other') }}</option>
                </select>
                <label for="disability" class="control-label">{{ $t('disability_type') }}</label><i class="bar"></i>
            </div>
        </div>

        <!--vaccine detail-->
        <label class="vaccine_label" hidden><b>{{ $t('vaccination_detail') }}</b></label>

        <!--vaccination center(province, district, municipality, vaccination center)-->
        <div>
            <div class="vaccination ">
                <div class="vaccinate-option">
                    <input class="radio-group" type="radio" id="yes" name="yes" value="1"
                        v-model="vaccine_current_address" v-on:change="loadCurrentAddressVaccineCenter"><b>
                    {{ $t('vaccinated_current_address') }}</b> &nbsp; &nbsp;
                </div>
                <div class="vaccinate-option">
                    <input class="radio-group" type="radio" id="no" name="no" value="0"
                        v-model="vaccine_current_address" v-on:change="checkVaccinationAddress">
                    <b>{{ $t('vaccinated_other') }}</b>
                </div>
            </div>
            <div class="container-flex3">
                <div class="form-group" v-show="isCurrentAddress">
                    <select name="vac_province_id" id="vac_province_id"
                            v-on:change="fetchVacDistricts(form)" v-model="form.vac_province_id">
                        <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in vac_provinces" :value="data.id">{{ data.province_name }}</option>
                    </select>
                    <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                </div>
                <div class="form-group" v-show="isCurrentAddress">
                    <select name="vac_district_id" id="vac_district_id"
                            v-on:change="fetchVacMunicipalities(form)" v-model="form.vac_district_id">
                        <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in vac_districts" :value="data.id">{{ data.district_name }}</option>
                    </select>
                    <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                </div>
                <div class="form-group" v-show="isCurrentAddress">
                    <select name="vac_municipality_id" v-model="form.vac_municipality_id"
                            id="vac_municipality_id" v-on:change="fetchVaccinationCenters(form)">
                        <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in vac_municipalities" :value="data.id">{{ data.municipality_name }}
                        </option>
                    </select>
                    <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                    class="bar"></i>
                </div>
                <div class="form-group">
                    <select name="vaccination_center" v-model="form.vaccination_center"
                            id="vaccination_center">
                        <option value="" disabled>{{ $t('select_options') }}</option>
                        <option v-for="data in vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                        <option value="0">{{ $t('dont_know')}}</option>
                    </select>
                    <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                    class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.form.vaccination_center.required">
                        {{ $t('field_required') }}
                    </div>
                </div>
            </div>
            <div v-show="isCurrentAddress">
                <label class="vaccine_label">{{ $t('choose_vaccine_center') }}</label>
            </div>
        </div>

        <div class="note"><i>**{{ $t('form_note') }}</i></div>


        <div class="button-container">
            <button type="button" class="button btn-block" id="button"
                    v-on:click="showConfirmationModal"><span>{{ $t('submit') }}</span>
            </button>
        </div>
        
    </div>
    <div v-if="confirmed" class="mt-3 mb-3">
            <public-register-confirmation-modal :item="form"
            :title="this.$t('confirmation_title')"
            :vaccination_center_name="vaccination_center_name"
            :date_of_birth="String(this.date_of_birth)"
            :age="String(this.age)"
            :confirmed="confirmed"
            :$t = this.$t />
    </div>
</div>


</template>
<script type="text/javascript">

import {required, between, minLength, maxLength, numeric,email} from "vuelidate/lib/validators";
import provinces from '../json/provinces.json'
import districts from '../json/districts.json'
import municipalities from '../json/municipalities.json'
import DataConverter from "ad-bs-converter";
import PublicRegisterConfirmationModal from '../components/public-register-confirmation-modal'
import axios from "axios";
import publicRegisterConfirmationModal from './public-register-confirmation-modal.vue';

const date = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(value)
}
export default {
  components: { publicRegisterConfirmationModal },
    data() {
        return {
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD'
            },
            masks: {
            input: 'YYYY-MM-DD'
            },
            disabled:false,
            confirmed:false,
            vaccination_center_name:'',
            form: new Form({
                'caste': '',
                'gender': '',
                'nationality': 'Nepal',
                'occupation': '',
                'province_id': (this.$user.province_id).toString(),
                'district_id': this.$user.district_id.toString(),
                'municipality_id': (this.$user.municipality_id).toString(),
                'identity_type': '',
                'medical_condition': [],
                'category': '16',
                'dob_type': '1',
                'vac_province_id': '',
                'vac_district_id': '',
                'vac_municipality_id': '',
                'vaccination_center': '',
                'vaccine_type': '',
                'disability_type': null,
                'organization_name' : this.$user.name
            }),
            
            // occupation_list: JSON.parse(JSON.stringify(occupations.essentials)),
            provinces: JSON.parse(JSON.stringify(provinces)),
            vac_provinces: JSON.parse(JSON.stringify(provinces)),
            districts: [],
            municipalities: [],
            vac_districts: [],
            vac_municipalities: [],
            vaccinations: [],
            country: 'Nepal',
            date_of_birth: '',
            terms: false,
            vaccine_current_address: '1',
            is_disabled: 0 ,
        }
    },
    validations() {
        return {
            date_of_birth: {
                required,
                date 
            },
            age: {
                required,
                between:between(18, 120)

            },
            form: {
                email_address:{
                    email
                },
                first_name: {
                    required
                },
                last_name: {
                    required
                },
                phone:{
                    required,
                    numeric,
                    minLength: minLength(10),
                    maxLength: maxLength(14)
                },
                caste: {
                    required
                },
                gender: {
                    required
                },
                nationality: {
                    required
                },
                province_id: {
                    required
                },
                district_id: {
                    required
                },
                municipality_id: {
                    required
                },
                ward: {
                    required,
                    between: between(1, 34)
                },
                category: {
                    required
                },
                medical_condition: {
                    required
                },
                date_of_first_vaccinated: {
                    required: false
                },
                vaccinated_address: {
                    required: false
                },
                vaccination_center: {
                    required
                },
                identity_type: {
                    required
                },
                identity_no: {
                    required
                },
                issue_org: {
                    required
                }
            }
        }
    },
    methods: {
        
        toggleDob() {
            if (this.form.dob_type == '1') {
                this.date_of_birth = this.getTodayNpDate()
            } else if (this.form.dob_type == '2') {
                this.date_of_birth = this.getTodayEnDate()
            }
        },
        toggleCheckbox(form) {
            if (form.medical_condition.includes('21')) {
                form.medical_condition = []
                form.medical_condition = ['21']
            }
        },
        removeNoneCheckbox(form) {
            if (form.medical_condition.includes('21')) {
                (form.medical_condition).splice((form.medical_condition).indexOf("21"), 1)
            }
        },
        toggleCheckboxCovid(form) {
            if(form.medical_condition.includes('23') && form.medical_condition.includes('21')){
                form.medical_condition = []
                form.medical_condition = ['23','21']
            }
        },
        showConfirmationModal() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$fire({
                    text: this.$t('error_msg'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                return false;
            }
            var e = document.getElementById("vaccination_center");
            this.vaccination_center_name =  e.options[e.selectedIndex].text;

            this.confirmed = true
        },
        fetchDistricts(form) {
            this.districts = [];
            var json = '';
            json = JSON.parse(JSON.stringify(districts));
            var provinceId = form.province_id;
            for (var i = 0; i < json.length; i++) {
                if (json[i].province_id === provinceId) {
                    this.districts.push(json[i]);
                }
            }
            return this.districts;
        },
        fetchMunicipalities(form) {
            this.municipalities = [];
            var json = '';
            json = JSON.parse(JSON.stringify(municipalities));
            var districtId = form.district_id;
            for (var i = 0; i < json.length; i++) {
                if (json[i].district_id === districtId) {
                    this.municipalities.push(json[i]);
                }
            }
            return this.municipalities;
        },
        fetchVacDistricts(form) {
            this.vac_districts = [];
            var json = '';
            json = JSON.parse(JSON.stringify(districts));
            var provinceId = form.vac_province_id;
            for (var i = 0; i < json.length; i++) {
                if (json[i].province_id === provinceId) {
                    this.vac_districts.push(json[i]);
                }
            }
            return this.vac_districts;
        },
        fetchVacMunicipalities(form) {
            this.vac_municipalities = [];
            var json = '';
            json = JSON.parse(JSON.stringify(municipalities));
            var districtId = form.vac_district_id;
            for (var i = 0; i < json.length; i++) {
                if (json[i].district_id === districtId) {
                    this.vac_municipalities.push(json[i]);
                }
            }
            return this.vac_municipalities;
        },
        fetchVaccinationCenters(form) {
            this.vaccinations = [];
            axios.get('/api/vaccination-center?municipality_id=' + form.vac_municipality_id)
                .then((response) => {
                    this.vaccinations = response.data
                    form.vaccination_center = '0'
                    return this.vaccinations;
                });
        },
        
        checkVaccinationAddress() {
            if (this.vaccine_current_address === '1') {
                this.fetchVaccinationCentersForCurrentAdd(this.form)
                this.form.vac_province_id = '';
                this.form.vac_district_id = '';
                this.form.vac_municipality_id = '';
            }
            else {
                this.vaccinations = []
                this.form.vaccination_center = ''
            }
        },
        getTodayNpDate() {
            var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
            var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
            var ad2bs = DataConverter.ad2bs(todayDate);

            return ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day
        },
        getTodayEnDate() {
            var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

            return today.getFullYear() + '-' + (today.getMonth() + 1) + "-" + today.getDate()
        },
        renderDatePickerNp(){
            var self = this;
            var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
            var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
            var ad2bs = DataConverter.ad2bs(todayDate);
            var disableAfter = ad2bs.en.year + "-" + ('0' + ad2bs.en.month).slice(-2) + "-" + ad2bs.en.day
            if($('#date_of_birth').length){
                $('#date_of_birth').nepaliDatePicker({
                    language: 'english',
                    ndpYear: true,
                    ndpMonth: true,
                    disableAfter: disableAfter,
                onChange: function() {
                    self.date_of_birth = $('#date_of_birth').val()
                }
            });

            }
        },
        authProvience(p_id){
        provinces.filter(({id,province_name})=>( id == p_id ? this.form.province_name= province_name : ''))
        },
        authDistrict(d_id){
        districts.filter(({id,district_name})=>( id == d_id ? this.form.district_name= district_name : ''))
        },
        authMunicipality(m_id){
        municipalities.filter(({id,municipality_name})=>( id == m_id ? this.form.municipality_name= municipality_name : ''))
        },
        loadCurrentAddressVaccineCenter(){
            axios.get('/api/vaccination-center?municipality_id=' + this.$user.municipality_id)
                    .then((response) => {
                        this.vaccinations = response.data
                        this.form.vaccination_center = ''
                        return this.vaccinations;
                    });
        }
    },
    computed: {
        isDisabled: function () {
            return !this.terms;
        },
        isCurrentAddress: function () {
            if (this.vaccine_current_address === '1') {
                // this.vaccinations = []
                // this.data.vaccination_center = ''
                return false;
            }
            return true;
        },
        isSecondVaccine: function () {
            if (this.form.vaccine_type === '1') {
                return false;
            }
            return true;
        },
        age() {
           // today english date
            var today = new Date();
            var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
            var date = this.date_of_birth;
            if (this.form.dob_type === '1' && date) {
                // today english date to nepali
                var ad2bs = DataConverter.ad2bs(todayDate);

                var dob = date.split('-');

                // calculate age
                age = ad2bs.en.year - dob[0];
                var m = ad2bs.en.month - dob[1];
                if (m < 0 || (m === 0 && ad2bs.en.day < dob[2])) {
                    age--;
                }
                return age;

            } else if (this.form.dob_type === '2') {
                var dob = this.date_of_birth;
                var today = new Date();
                var birthDate = new Date(dob);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
                {
                    age--;
                }
                return age;
            }
        }
    },
    beforeUpdate(){
      this.renderDatePickerNp();
    },
    mounted(){
        this.renderDatePickerNp();
        
    },
    created(){
        Event.$on("editing",()=>{
            this.confirmed = false
        })
        
        this.fetchDistricts(this.form);
        this.fetchMunicipalities(this.form);
        this.loadCurrentAddressVaccineCenter();
        
        
    }
}
;
</script>
