import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import PostRegistration from '../components/post-registration.vue'
import Dashboard from '../components/dashboard'

var admin_prefix = '/admin';

const routes = [
    {
    path: "/admin/post-registration",
    name: "post_registration",
    components: {
        vaccination : PostRegistration
      }
    },
    
  ];
  
  const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: 'active'
  });
  
  export default router;