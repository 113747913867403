<template>
  <div class="">
    <vue-good-table

    class="hide-on-print"
      :columns="columns" :rows="rows.data"
      mode="remote"
      :sort-options="{
            enabled: false,
        }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Search Client by Registaration No., Identity No., Mobile No. Press Enter to search.'
      }"
      @on-search="onSearch"
      :isLoading.sync="isLoading"
      >
      <div slot="emptystate">
        {{message}}
    </div>
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
                  <button class="fancy-btn" @click="view(props.row)"> <i class=" p-1 blue fas fa-file-image"></i>View Documents</button>
          </span>
          <span v-else-if="props.column.field == 'is_verified'">
                  <button class="fancy-btn" v-if="props.row.is_verified == 0"><i class="p-1 orange  fas fa-spinner fa-pulse"></i>Pending</button>
                  <button class="fancy-btn" v-else-if="props.row.is_verified == 1" @click="viewReport(props.row)" ><i class="green p-1  fas fa-check-circle"></i>Verified</button>
                  <button class="fancy-btn" v-else-if="props.row.is_verified == 2"><i class="p-1  red  fas fa-times"></i>Disapproved</button>
                  <button class="fancy-btn" v-else><i class="p-1  blue  fas fa-sync fa-spin"></i>Reverification</button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>

      </template>

      </vue-good-table>

        <view-modal-slide   :modal_data="this.modal_data" :form="this.form" :refresh='refresh'   header="Document Slider">
        </view-modal-slide>
         <div class="row">
            <div class="" v-for="data in rows.data" :key="data.id">
                <div class="card-body">
                    <h3>Personal Details</h3>
                        <table class="table table-bordered ">
                            <tr>
                                <th>Full Name </th>
                                <th>{{data.name}}</th>
                            </tr>
                             <tr>
                                <th>Date of Birth </th>
                                <th>{{data.date_of_birth}}</th>
                            </tr>
                             <tr>
                                <th>Mobile No. </th>
                                <th>{{data.phone}}</th>
                            </tr>
                             <tr>
                                <th>Identity Type </th>
                                <th>{{data.identity_type}}</th>
                            </tr>
                            <tr>
                                <th>Registration Date </th>
                                <th>{{data.created_at}}</th>
                            </tr>
                            <tr>
                                <th v-if="data.is_verified == 1"> Verified Date </th>
                                <th v-if="data.is_verified == 0">Registered Date</th>
                                <th v-if="data.is_verified == 2">Disapproved Date</th>
                                <th v-if="data.is_verified == 3">Reverification Date</th>
                                <th>{{data.updated_at}}</th>
                            </tr>
                            <tr v-if="data.deleted_at">
                                <th>Deleted Date </th>
                                <th>{{data.deleted_at}}</th>
                            </tr>
                            <tr v-if="data.deleted_at && data.is_verified == 2">
                                <th>Remarks </th>
                                <th>{{data.remarks}}</th>
                            </tr>



                        </table>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="" v-for="data in rows.data" :key="data.id">
                <div class="card-body" >
                    <h3>Verifier Details</h3>

                        <table class="table table-bordered " v-if="data.user_details && data.user_details.municipality_infos">
                            <tr>
                                <th>Responsible Person</th>
                                <th>Phone</th>
                                <th>Office Address</th>
                            </tr>
                            <tr>
                                <td>{{data.user_details.municipality_infos.responsible_person}}</td>
                                <td>{{data.user_details.municipality_infos.phone}}</td>
                                <td>{{data.user_details.municipality_infos.municipality.municipality_name}}</td>
                            </tr>
                        </table>
                        <table v-else-if="!data.user_details">
                            <tr>
                                <td >Onsite Registered User</td>
                            </tr>
                        </table>
                        <table v-else>
                            <tr>
                                <td v-if="data.user_details && data.user_details.municipality_infos == null">Central User</td>
                            </tr>
                        </table>
                </div>

            </div>
        </div>

  </div>
</template>

<script>
import PaginationWrapper from './Pagination/PaginationWrapper.vue';

import ViewModal from './modal/view-modal.vue';
import ViewModalSlide from './modal/slider.vue';

import axios from 'axios';
export default {
  components:{
    PaginationWrapper,
        ViewModal,
        ViewModalSlide
    },
  data(){
    return {
      isLoading: false,
        paginate_param : '',
        citizen_param : '',
      totalRecords: 0,
      refresh: 0,
      images:[],
      medical_condition:[],
      names:'',
      modal_data:{
        modal_size:'modal-lg',
      },
      form: new Form({
              id:'',
              name:'',
              caste: '',
              gender: '',
              nationality: '',
              occupation: '',
              province_id: '',
              district_i: '',
              municipality_id: '',
              medical_condition: [],
              district_name: '',
              municipality_name: '',
              ward:'',
              phone:'',
              identity_no:'',
              identity_type:'',
              issue_org:'',
              issue_date:'',
              vaccine_type: '',
              disability_type: "",
              vaccinated_id:'',
              is_verified:'',
              remarks:'',
              vaccination_record:'',
              age:'',
              date_of_birth:'',
              identity_image:'',
              profile_image:'',
              self_decleration_image: '',
              vaccine_card_image:'',
              municipality:'',
              data_from:'',

            }),

      columns: [
        {
            label: 'Status',
            field:'is_verified',
            filterOptions: {
              customFilter: true
            }
        },

        {
            label: 'Action',
            field: 'actions',
            filterOptions: {
              customFilter: true
            }
        },

      ],
      rows: {
        data:[
        ]
      },
      serverParams: {
      columnFilters: {
      },
      sort: {
        field: '',
        type: '',
      },
      page: 1,
      perPage: 10,
      searchTerm:'',

    },
    message: 'Search Client by Registaration No., Identity No., Mobile No. Press Enter to search.'


    }
  },
  methods:{

      viewReport(params){
          axios.post('https://vaccine.mohp.gov.np/api/get-access', {
              token: params.vaccinated_id
          })
              .then(function (response) {
                  window.open(
                      ' https://vaccine.mohp.gov.np/vaccination-detail?id=' + response.data,
                      '_blank'
                  );
              })
              .catch(function (error) {

              });
      },
    view(params){
      this.form.fill(params);
      this.refresh++
      $('#viewSlider').modal('show');
    },
    viewVaccineRecord(params){
      $('#viewContent').modal('show');
      this.form.fill(params);
      this.medical_condition = JSON.parse(this.form.medical_condition)
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onSearch(param){
        this.updateParams(param);
        this.loadFromFilter();
    },
    loadFromFilter(){
      let param = this.serverParams
      axios.get('/public-list-search-update-investigator?filter='+param.searchTerm).then(({data})=>{
            this.rows = data.pagination_data
            if(data.pagination_data.data.length == 0){
                this.message = 'This data does not exist on this server'
            }

        }).catch(()=>{
        })
    },

  },
  created(){

    Fire.$on('AfterUpdate',()=>{
    })


  }
};
</script>
<style >

.fancy-btn{
  color: black;
  background: blanchedalmond;
  border: 1px solid #000;
}
.invisible-input{
    background-color: transparent;
  border: none;
  box-sizing: border-box;
  width: 100%;
}
input:focus{
     outline: none;
}
.invisible-input:focus {
  outline: none;
}
.vgt-table th {
      font-size: 15px !important;

 }
 .vgt-table td{
      font-size: 14px !important;

 }
 @media print{
    .hide-on-print{
        display: none;
    }
 }
</style>

