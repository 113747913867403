export default class Gate{
    constructor(user){
        this.user = user;
    }
    isHealthPost(){
        return this.user.role === 'healthpost';
    }
    isMunicipality(){
        return this.user.role === 'municipality';
    }
   
}
   