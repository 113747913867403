import { render, staticRenderFns } from "./PaginationWrapper.vue?vue&type=template&id=45ed1810&scoped=true&"
import script from "./PaginationWrapper.vue?vue&type=script&lang=js&"
export * from "./PaginationWrapper.vue?vue&type=script&lang=js&"
import style0 from "./PaginationWrapper.vue?vue&type=style&index=0&id=45ed1810&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ed1810",
  null
  
)

export default component.exports