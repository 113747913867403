<template>
  <div ref="form_public">
    <section class="content mb-2">
        <div class="container-fluid">
          <h4 class="text-center ">{{ $t('search_title') }}</h4>
              <button v-if="searchmode || editmode" @click="newContent" class="btn btn-primary float-right"><i class="fas fa-user-plus fa-fw"></i> New Client</button>
              <div class="d-flex p-2 justify-content-center search">
                <div class="search-item">
                    <input class="radio-group" type="radio" id="mormal-search" name="search_type" value="registration" @change="toggleSearch"
                            v-model="searchType">
                      <label for="mormal-search">{{ $t('normal_search') }} &nbsp; &nbsp;</label>
                </div>
                <div class="search-item">
                  <input class="radio-group" type="radio" id="advanced-search" name="search_type" value="advanced" @change="toggleSearch"
                          v-model="searchType">
                    <label for="advanced-search">{{ $t('advanced_search') }} &nbsp; &nbsp;</label>
                </div>
                <div class="search-item">
                  <input class="radio-group" type="radio" id="identity-search" name="search_type" value="identity" @change="toggleSearch"
                          v-model="searchType">
                      <label for="identity-search">{{ $t('identity_search') }} &nbsp; &nbsp;</label>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-6 mt-2"  v-if="searchType == '' || searchType == 'registration'" >
                      <div class="input-group">
                            <input type="search" class="form-control form-control rounded-0" v-model="search.reg_no" @keyup="idSearch" @keyup.enter="searchUser($event)">
                            <div class="input-group-append bg-blue-secondary btn-search" v-if="searchmode || searchType == '' || searchType == 'registration'">
                                <button type="button" class="btn m-0  search-btn" @click="searchUser($event)">
                                  {{ $t('search') }}
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                      </div>

                    <!-- <div class="col-md-3" >
                      <div class="input-group-append bg-blue-secondary btn-search">
                          <button type="button" class="btn m-0  search-btn " @click.prevent="searchUser()">
                            Search
                              <i class="fa fa-search"></i>
                          </button>
                      </div>
                    </div> -->
                </div>
              </div>


            <!-- <div class="row mt-4" v-if="searchType === 'normal'">

                <div class="col-md-9">
                  <div class="container-flex3" >
                    <div class="form-group" :class="{ 'has-error': $v.search.province_id.$error }">
                      <select name="province_id"
                              v-on:change="fetchDistricts(search)" v-model="search.province_id" >
                              <option value="">{{ $t('select_options') }}</option>
                        <option  v-for="data in provinces" :value="data.id" selected>{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.province_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.district_id.$error }">
                      <select name="district_id"
                              v-on:change="fetchMunicipalities(search)" v-model="search.district_id">
                              <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in districts" :value="data.id" selected>{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.district_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.municipality_id.$error }">
                      <select name="municipality_id" v-model="search.municipality_id"
                               v-on:change="fetchVaccinationCentersForCurrentAdd(search)">
                               <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in municipalities" :value="data.id" selected>{{ data.municipality_name }}
                        </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                        class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.municipality_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-3" style="margin-top: 43px;">
                    <form >
                        <div class="form-group mt-0">
                            <input type="search"  class="search form-control  rounded-0" v-model="search.reg_no" >
                                    <label for="reg_no" class="control-label">{{ $t('registration_no') }}</label><i class="bar"></i>

                              <div class="help-block text-danger" v-if="!$v.search.reg_no.required">{{ $t('field_required') }}
                              </div>

                        </div>
                    </form>
                </div>
                <div class="col-md-12" v-if="searchType == 'normal'">

                  <div class="input-group-append bg-blue-secondary btn-search">
                      <button type="button" class="btn m-0  search-btn " @click.prevent="searchUser()">
                        Search
                          <i class="fa fa-search"></i>
                      </button>
                  </div>
                </div>

            </div> -->

            <div class="row">
              <div class="col-md-12 " v-if="searchType === 'advanced'">
                 <div class="container-flex3" >
                    <div class="form-group" :class="{ 'has-error': $v.search.province_id.$error }">
                      <select name="province_id"
                              v-on:change="fetchDistricts(search)" v-model="search.province_id" >
                              <option value="">{{ $t('select_options') }}</option>
                        <option  v-for="data in provinces" :value="data.id" selected>{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.province_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.district_id.$error }">
                      <select name="district_id"
                              v-on:change="fetchMunicipalities(search)" v-model="search.district_id">
                              <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in districts" :value="data.id" selected>{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.district_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.municipality_id.$error }">
                      <select name="municipality_id" v-model="search.municipality_id"
                               v-on:change="fetchVaccinationCentersForCurrentAdd(search)">
                               <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in municipalities" :value="data.id" selected>{{ data.municipality_name }}
                        </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                        class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.municipality_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                  </div>
                <div class="container-flex3" >

                  <!--phone-->
                  <div class="form-group " :class="{ 'has-error': $v.search.phone.$error }">
                    <input type="number"  name="phone" v-model="search.phone"
                          required="required"/>
                    <label for="phone" class="control-label">{{ $t('mobile') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.phone.required">{{ $t('field_required') }}</div>

                  </div>

                  <!--name-->
                  <!-- <div class="form-group "  :class="{ 'has-error': $v.search.name.$error }">
                    <input type="text" class="has-value" id="name" name="name" pattern="/^[A-Za-z]+$/"
                          v-model="search.name" />
                    <label for="first_name" class="control-label">{{ $t('name') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.name.requiredIfNulGender">{{ $t('required_if_gender_null') }}</div>
                     <div class="help-block text-danger" v-if="!$v.search.name.requiredIfNullPhone">{{ $t('required_if_phone_null') }}</div>


                  </div> -->
                  <!--gender-->
                  <div class="form-group" :class="{ 'has-error': $v.search.gender.$error }">
                    <select id="gender" name="gender" v-model="search.gender" >
                      <option value="" >{{ $t('select_options') }}</option>
                      <option value="1">{{ $t('male') }}</option>
                      <option value="2">{{ $t('female') }}</option>
                      <option value="3">{{ $t('third_gender') }}</option>
                    </select>
                    <label for="gender" class="control-label">{{ $t('gender') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.gender.required">{{ $t('field_required') }}
                    </div>
                  </div>
                  <!--ward-->
                  <!-- <div class="form-group " :class="{ 'has-error': $v.search.ward.$error }">
                    <input type="number" id="ward" name="ward"
                          v-model="search.ward"  min="1" max="35"/>
                    <label for="ward" class="control-label">{{ $t('ward') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.ward.required">{{ $t('field_required') }}</div>
                  </div> -->
                </div>
                <!-- <div class="container-flex3" >

                   <div class="form-group " >
                    <input type="number" class="has-value"
                          v-model="search.age_range"  min="0"/>
                    <label for="Age" class="control-label date-label">{{ $t('age') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.age_range.required">{{ $t('field_required') }}</div>
                  </div>
                </div> -->
              </div>

              <div class="col-md-12 " v-if="searchType === 'identity'">
                  <div class="container-flex3" >
                    <div class="form-group" :class="{ 'has-error': $v.search.province_id.$error }">
                      <select name="province_id"
                              v-on:change="fetchDistricts(search)" v-model="search.province_id" >
                              <option value="">{{ $t('select_options') }}</option>
                        <option  v-for="data in provinces" :value="data.id" selected>{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.province_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.district_id.$error }">
                      <select name="district_id"
                              v-on:change="fetchMunicipalities(search)" v-model="search.district_id">
                              <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in districts" :value="data.id" selected>{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.district_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.search.municipality_id.$error }">
                      <select name="municipality_id" v-model="search.municipality_id"
                               v-on:change="fetchVaccinationCentersForCurrentAdd(search)">
                               <option value="">{{ $t('select_options') }}</option>
                        <option v-for="data in municipalities" :value="data.id" selected>{{ data.municipality_name }}
                        </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                        class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.search.municipality_id.required">{{ $t('field_required') }}
                      </div>
                    </div>
                  </div>

                <div class="container-flex3" >
                  <div class="form-group" :class="{ 'has-error': $v.search.identity_type.$error }">
                    <select id="identity_type" name="identity_type" v-model="search.identity_type">
                      <option value="" disabled>{{ $t('select_options') }}</option>
                      <option value="Citizenship">{{ $t('citizenship') }}</option>
                      <option value="National ID">{{ $t('national_id') }}</option>
                      <option value="Driving License">{{ $t('driving_license') }}</option>
                      <option value="Election card">{{ $t('voters_card') }}</option>
                      <option value="Passport">{{ $t('passport') }}</option>
                      <option value="Disability ID Card">{{ $t('disability_id') }}</option>
                      <option value="Others">{{ $t('other') }}</option>
                    </select>
                    <label for="identity_type" class="control-label">{{ $t('identity_type') }}</label><i
                      class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.identity_type.required">{{
                        $t('field_required')
                      }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': $v.search.identity_no.$error }">
                    <input type="text" id="identity_no" name="identity_no"
                          v-model="search.identity_no" required="required"/>
                    <label for="identity_no" class="control-label">{{ $t('identification_no') }} ( e.g. 1234-56)</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.identity_no.required">{{
                        $t('field_required')
                      }}
                    </div>
                  </div>

                  <!-- <div class="form-group" :class="{ 'has-error': $v.search.issue_org.$error }">
                    <input type="text"  id="issue_org" name="issue_org"
                          v-model="search.issue_org" required="required" />
                    <label for="issue_org" class="control-label">{{ $t('issue_org') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.search.issue_org.required">{{ $t('field_required') }}</div>
                  </div> -->

                </div>


              </div>
              <div class="col-md-12" >

                <div class="input-group-append bg-blue-secondary btn-search" v-if="searchmode && searchType != 'registration'">
                    <button type="button" class="btn m-0  search-btn " @click.prevent="searchUser($event)" @keyup.enter="searchUser($event)">
                      {{ $t('search') }}
                        <i class="fa fa-search"></i>
                    </button>
                </div>
              </div>
            </div>
            <h4 v-if="(searchmode) && (users !== null )">{{ $t('filtered_client') }}</h4>
            <div class="card elevation-0 shadow-sm" v-if="(searchmode) && (users !== null )">

              <div class="card-body table-responsive table-bordered p-0">
                <table class="table table-hover ">
                  <tbody>
                    <tr class="bg-white text-sm">
                        <th>{{$t('registration_no')}}</th>
                        <th>{{$t('name')}}</th>
                        <th>{{$t('gender')}}</th>
                        <th>{{$t('age_search')}}</th>
                        <th>{{$t('mobile')}}</th>
                        <th> {{$t('vaccination_status')}}</th>
                        <th>{{$t('action')}}</th>

                    </tr>
                    <tr v-for="(user, index) in users"  :key="user.id">
                        <td>
                          {{user.vaccinated_id}}
                        </td>
                        <td>
                          <p v-if="user.middle_name"> {{user.first_name}} {{user.middle_name}}  {{user.last_name}}</p>
                          <p v-else> {{user.first_name}}  {{user.last_name}}</p>
                        </td>
                        <td>
                           <p v-if="user.gender == 1">{{ $t('male') }}</p>
                           <p v-if="user.gender == 2">{{ $t('female') }}</p>
                           <p v-if="user.gender == 3">{{ $t('third_gender') }}</p>
                        </td>
                        <td>
                          {{user.age}}
                        </td>
                        <td>
                        {{user.phone}}
                        </td>
                        <td >
                          <p v-if="user.vaccination_record.length > 1">{{ $t('vaccinated') }} <br> ({{ $t('first_dose') }} , {{ $t('second_dose') }})</p>
                          <p v-if="user.vaccination_record.length === 1">{{ $t('vaccinated') }} <br> ({{ $t('first_dose') }})</p>
                          <p v-if="user.vaccination_record.length == 0">{{ $t('not_vaccinated') }}</p>
                        </td>


                        <td>
                          <a href="#" @click="editUser(user);" class="btn btn-sm btn-success p-1 m-0"><small>{{ $t('show') }}</small>
                            <i class="fa fa-edit"></i>
                        </a>

                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

        </div>
    </section>
    <hr>
    <div v-if="!searchmode">
      <div class="col-md-12" v-if="!editmode">
        <h4 class="text-center ">{{ $t('new_registration') }}</h4>
      </div>
      <div class="col-md-12" v-if="editmode">
        <h4 class="text-center ">{{ $t('update_detail') }}</h4>
      </div>

      <div class="card  rounded-0" :class="{'collapsed-card' : editmode}">
        <div class="card-header rounded-0 bg-blue p-0" data-card-widget="collapse">
                <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i></button>


          <h4 class="d-inline"><small>{{ $t('basic_form') }}</small></h4>

        </div>
        <div class="card-body p-2">

          <!--name-->
          <div class="container-flex w-100">

            <div class="form-group" :class="{ 'has-error': $v.data.first_name.$error }">
              <input type="text" id="first_name" name="first_name"
                    v-model="data.first_name" required="required"/>
              <label for="first_name" class="control-label">{{ $t('first_name') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.first_name.required">{{ $t('field_required') }}</div>
            </div>
            <div class="form-group">
              <input type="text" id="middle_name" name="middle_name"
                    v-model="data.middle_name" required="required"/>
              <label for="middle_name" class="control-label">{{ $t('middle_name') }}</label><i class="bar"></i>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.data.last_name.$error }">
              <input type="text" id="last_name" name="last_name"
                    v-model="data.last_name" required="required"/>
              <label for="last_name" class="control-label">{{ $t('last_name') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.last_name.required">{{ $t('field_required') }}</div>
            </div>
          </div>

          <!--gender-->
          <div class="form-group" :class="{ 'has-error': $v.data.gender.$error }">
            <select id="gender" name="gender" v-model="data.gender" required="required">
              <option value="">{{ $t('select_options') }}</option>
              <option value="1">{{ $t('male') }}</option>
              <option value="2">{{ $t('female') }}</option>
              <option value="3">{{ $t('third_gender') }}</option>
            </select>
            <label for="gender" class="control-label">{{ $t('gender') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.data.gender.required">{{ $t('field_required') }}
            </div>
          </div>

          <!--date of brith-->
          <div class="container-flex" style="margin-top: -30px;">
            <div class="date-type">
              <label class="control-label">{{ $t('dat_type') }}</label>
              <input class="radio-group" type="radio" id="bs" name="dob" value="1" v-model="data.dob_type"
                    v-on:change="toggleDob()">
              <label for="dob" class="text-sm">{{ $t('bs') }}&nbsp;&nbsp;</label>
              <input class="radio-group" type="radio" id="ad" name="dob" value="2" v-model="data.dob_type"
                    v-on:change="toggleDob()">
              <label for="dob" class="text-sm">{{ $t('ad') }}</label>

            </div>
            <div class="form-group"   :class="{ 'has-error': $v.date_of_birth.$error }">
              <input type="text" autocomplete="off" v-if="data.dob_type == '1'" id="date_of_birth" name="date_of_birth" required="required"  class="has-value"
                    v-mask="'####-##-##'" v-model.trim="date_of_birth" value="" >

                    <v-date-picker v-else v-model="date_of_birth" :max-date='Date.now() - 5*31556952000' :model-config="modelConfig" :masks="masks">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="has-value"
                          v-mask="'####-##-##'"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                <!-- <v-date-picker v-else :model-config="modelConfig"  :masks="masks"
                    >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="has-value"
                      v-model="date_of_birth"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker> -->
              <label for="date_of_birth" class="control-label date-label">{{ $t('dob') }} ( e.g. 2050-02-09 )</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.date_of_birth.required">{{ $t('field_required') }}</div>
              <div class="help-block text-danger" v-if="!$v.date_of_birth.date">{{ $t('dob_format_error') }}</div>



            </div>
            <div class="form-group" :class="{ 'has-error': $v.age.$error }">
              <input type="number" class="has-value" readonly id="Age" name="Age"
                    :value="age" required="required" min="0"/>
              <label for="Age" class="control-label date-label">{{ $t('age') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.age.required">{{ $t('field_required') }}</div>
              <div class="help-block text-danger" v-if="!$v.age.between">{{ $t('invalid_age') }}</div>

            </div>
          </div>
          <!--current address(province, district, municipality)-->
          <p class="vaccine_label" style="margin-top: 30px;"><b>{{ $t('current_address') }}</b></p>
          <div class="container-flex3" style="margin-top: -30px;">
            <div class="form-group" :class="{ 'has-error': $v.data.province_id.$error }">
              <select name="province_id" id="province_id"
                      v-on:change="fetchDistricts(data)" v-model="data.province_id" >
                    <option value="">{{ $t('select_options') }}</option>
                <option  v-for="data in provinces" :value="data.id" selected>{{ data.province_name }} </option>
              </select>
              <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.province_id.required">{{ $t('field_required') }}
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.data.district_id.$error }">
              <select name="district_id" id="district_id"
                      v-on:change="fetchMunicipalities(data)" v-model="data.district_id">
                  <option value="">{{ $t('select_options') }}</option>
                <option v-for="data in districts" :value="data.id" selected>{{ data.district_name }}</option>
              </select>
              <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.district_id.required">{{ $t('field_required') }}
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.data.municipality_id.$error }">
              <select name="municipality_id" v-model="data.municipality_id"
                      id="municipality_id" v-on:change="fetchVaccinationCentersForCurrentAdd(data)">
                <option value="">{{ $t('select_options') }}</option>
                <option v-for="data in municipalities" :value="data.id" selected>{{ data.municipality_name }}
                </option>
              </select>
              <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.municipality_id.required">{{ $t('field_required') }}
              </div>
            </div>
          </div>

          <!--ward-->
          <div class="form-group" :class="{ 'has-error': $v.data.ward.$error }">
            <input type="number" id="ward" name="ward"
                  v-model="data.ward" required="required" min="1" max="35"/>
            <label for="ward" class="control-label">{{ $t('ward') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.data.ward.required">{{ $t('field_required') }}</div>
            <div class="help-block text-danger" v-if="!$v.data.ward.between">{{ $t('invalid_ward') }}</div>
          </div>

          <!--tole-->
          <div class="form-group">
            <input type="text" id="tole" name="tole"
                  v-model="data.tole"
                  required="required"/>
            <label for="tole" class="control-label">{{ $t('tole') }}</label><i class="bar"></i>
          </div>

          <!--phone-->
          <div class="form-group" :class="{ 'has-error': $v.data.phone.$error }">
            <input type="number" id="phone" name="phone" v-model="data.phone"
                  required="required"/>
            <label for="phone" class="control-label">{{ $t('mobile') }}</label><i class="bar"></i>
            <div class="help-block text-danger" v-if="!$v.data.phone.required">{{ $t('field_required') }}</div>
            <div class="help-block text-danger" v-if="!$v.data.phone.maxLength">{{ $t('field_max') }}</div>
            <div class="help-block text-danger" v-if="!$v.data.phone.minLength">{{ $t('field_min') }}</div>


          </div>

          <!--identity type, identity no-->
          <div class="flex-identity">
            <div class="form-group" :class="{ 'has-error': $v.data.identity_type.$error }">
              <select id="identity_type" name="identity_type" v-model="data.identity_type">
                <option value="" disabled>{{ $t('select_options') }}</option>
                <option value="Citizenship">{{ $t('citizenship') }}</option>
                <option value="National ID">{{ $t('national_id') }}</option>
                <option value="Driving License">{{ $t('driving_license') }}</option>
                <option value="Election card">{{ $t('voters_card') }}</option>
                <option value="Passport">{{ $t('passport') }}</option>
                <option value="Disability ID Card">{{ $t('disability_id') }}</option>
                <option value="Others">{{ $t('other') }}</option>
              </select>
              <label for="identity_type" class="control-label">{{ $t('identity_type') }}</label><i
                class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.identity_type.required">{{
                  $t('field_required')
                }}
              </div>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.data.identity_no.$error }">
              <input type="text" id="identity_no" name="identity_no"
                    v-model="data.identity_no" required="required"/>
              <label for="identity_no" class="control-label">{{ $t('identification_no') }} ( e.g. 1234-56)</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.identity_no.required">{{
                  $t('field_required')
                }}
              </div>

            </div>

            <div class="form-group" :class="{ 'has-error': $v.data.issue_org.$error }">
              <input type="text" id="issue_org" name="issue_org"
                    v-model="data.issue_org" required="required"/>
              <label for="issue_org" class="control-label">{{ $t('issue_org') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.issue_org.required">{{ $t('field_required') }}</div>
            </div>
          </div>
          <!--occupation-->
          <div class="container-flex1">
            <div class="form-group">
              <select id="occupation" name="occupation" v-model="data.occupation">
                <option value="">{{ $t('select_options') }}</option>
                <option value="33">{{ $t('foreign_employment') }}</option>
                <option value="1">{{ $t('airlines') }}</option>
                <option value="2">{{ $t('army') }}</option>
                <option value="3">{{ $t('bank') }}</option>
                <option value="4">{{ $t('commercial_facilities') }}</option>
                <option value="5">{{ $t('daily_wage_worker') }}</option>
                <option value="31">{{ $t('diplomat') }}</option>
                <option value="6">{{ $t('farmer') }}</option>
                <option value="7">{{ $t('government_employee') }}</option>
                <option value="8">{{ $t('grocery') }}</option>
                <option value="9">{{ $t('health_care') }}</option>
                <option value="10">{{ $t('health_professional') }}</option>
                <option value="11">{{ $t('it_and_communication') }}</option>
                <option value="12">{{ $t('journalist_media') }}</option>
                <option value="13">{{ $t('manufacturing') }}</option>
                <option value="14">{{ $t('ngo_ingo') }}</option>
                <option value="15">{{ $t('online_business') }}</option>
                <option value="16">{{ $t('police') }}</option>
                <option value="17">{{ $t('Security_person') }}</option>
                <option value="18">{{ $t('social_service') }}</option>
                <option value="19">{{ $t('student') }}</option>
                <option value="20">{{ $t('teacher_professor') }}</option>
                <option value="21">{{ $t('tourist') }}</option>
                <option value="22">{{ $t('transport_sector') }}</option>
                <option value="23">{{ $t('unemployed') }}</option>
                <option value="32">{{ $t('veterinarians') }}</option>
                <option value="0">{{ $t('other') }}</option>
                <!--                    <option v-for="data in occupation_list" :value="data.id">{{ data.name }}</option>-->
              </select>
              <label for="occupation" class="control-label">{{ $t('occupation') }}</label><i class="bar"></i>
            </div>
          </div>
        </div>
      </div>

      <!--vaccine detail-->
      <div class="card rounded-0 collapsed-card ">
          <div class="card-header rounded-0 bg-blue p-0" data-card-widget="collapse">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i></button>

            <h4 class="d-inline"><small>{{ $t('advanced_option') }}</small></h4>

          </div>
          <div class="card-body p-2">
            <!--caste-->
            <div class="form-group" :class="{ 'has-error': $v.data.caste.$error }">
              <select id="caste" name="caste" v-model="data.caste" required="required">
                <option value="">{{ $t('select_options') }}</option>
                <option value="0">{{ $t('dalit') }}</option>
                <option value="1">{{ $t('janajati') }}</option>
                <option value="2">{{ $t('madhesi') }}</option>
                <option value="3">{{ $t('muslim') }}</option>
                <option value="4">{{ $t('brahmn_chettri') }}</option>
                <option value="5">{{ $t('other') }}</option>
              </select>
              <label for="caste" class="control-label">{{ $t('caste') }}</label>
              <i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.caste.required">{{ $t('field_required') }}</div>
            </div>
            <!--nationality-->
            <div class="form-group" :class="{ 'has-error': $v.data.nationality.$error }">
              <country-select id="nationality" name="nationality" v-model="data.nationality"
                              :country="country"
                              :countryName=true
                              :usei18n=false
                              topCountry="Nepal"/>
              <label class="control-label">{{ $t('nationality') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.nationality.required">{{
                  $t('field_required')
                }}
              </div>
            </div>
            <!--email-->
            <div class="form-group">
              <input type="email" id="email_address" name="email_address"
                    v-model="data.email_address"
                    required="required"/>
              <label for="email_address" class="control-label">{{ $t('email') }}</label><i class="bar"></i>
              <div class="help-block text-danger" v-if="!$v.data.email_address.email">{{ $t('invalid_email_format') }}</div>

            </div>

            <!--medical condition-->
            <div class="medical_condition" :class="{ 'has-error': $v.data.medical_condition.$error }">
              <label class="medical_label" style="display: inline-block;"><b>{{ $t('select_medical_condition') }}</b>
              </label>
              <div class="help-block text-danger" v-if="!$v.data.medical_condition.required">{{ $t('field_required') }}</div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="toggleCheckbox(data, true)" v-model="data.medical_condition"
                        value="21"/><i
                    class="helper"></i>{{ $t('normal_health') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="8"/><i
                    class="helper"></i>{{ $t('blood_pressure') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="1"/><i
                    class="helper"></i>{{ $t('cancer') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="2"/><i
                    class="helper"></i>{{ $t('chronic_kidney_disease') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="3"/><i
                    class="helper"></i>{{ $t('chronic_resp_disease') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="4"/><i
                    class="helper"></i>{{ $t('cardiovascular_disease') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="5"/><i
                    class="helper"></i>{{ $t('diabetes') }}
                </label>
              </div>
              <!--            <div class="checkbox">-->
              <!--                <label>-->
              <!--                    <input type="checkbox" v-model="data.medical_condition" value="6"/><i class="helper"></i>Allergies-->
              <!--                </label>-->
              <!--            </div>-->
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="9"/><i
                    class="helper"></i>{{ $t('nerve_disease') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="7"/><i
                    class="helper"></i>{{ $t('pregnant') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="11"/><i
                    class="helper"></i>{{ $t('tb') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="22"/><i
                    class="helper"></i>{{ $t('mental_disease') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="31"/><i
                    class="helper"></i>{{ $t('hiv_aids') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                        value="23"/><i
                    class="helper"></i>{{ $t('covid') }}
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" @change="toggleCheckbox(data, true)" v-model="data.medical_condition"
                        value="10"/><i class="helper"></i>{{ $t('none_of_above') }}
                </label>
              </div>
            </div>
            <div class="date-type">
              <label class="control-label"> {{ $t('disability') }}</label>
              <input class="radio-group" type="radio" value="1" v-model="is_disabled"
              > {{ $t('yes') }} &nbsp;
              <input class="radio-group" type="radio" value="0" v-model="is_disabled"
              > {{  $t('no') }}
            </div>

            <!--disability-->
            <div v-if="is_disabled === '1'" class="container-flex1">
              <div class="form-group">
                <select id="disability" name="disability_type" v-model="data.disability_type">
                  <option value="">{{ $t('select_options') }}</option>
                  <option value="1">{{ $t('blindfolded')}}</option>
                  <option value="2">{{ $t('without_both_hands') }}</option>
                  <option value="3">{{ $t('speech_disability') }}</option>
                  <option value="4">{{ $t('both_ears_are_deaf') }}</option>
                  <option value="5">{{ $t('unable_to_walk')}}</option>
                  <option value="6">{{ $t('other') }}</option>
                </select>
                <label for="disability" class="control-label">{{ $t('disability_type') }}</label><i class="bar"></i>
              </div>
            </div>

          </div>
      </div>
      <!--vaccine detail-->
      <!--vaccine detail-->
      <div class="card rounded-0 dshadow">
          <div class="card-header  rounded-0  p-0" data-card-widget="collapse">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="blue fas fa-plus"></i></button>
            <h4 class="d-inline"><small>{{ $t('vaccination_status') }}</small></h4>
            <hr v-if="editmode">
            <div class="basic-info d-flex justify-content-between container" v-if="editmode">
              <div class="name">
                <p v-if="data.middle_name">{{$t('name')}}: {{data.first_name}} {{data.middle_name}}  {{data.last_name}}</p>
                <p v-if="!data.middle_name">{{$t('name')}}: {{data.first_name}}  {{data.last_name}}</p>
              </div>
              <div class="id">
                <p>{{ $t('registration_no') }} : {{data.vaccinated_id}}</p>
              </div>
              <div class="phone">
                <p>{{ $t('mobile') }} : {{data.phone}}</p>
              </div>
              <div class="age">
                <p>{{ $t('age') }} : {{age}}</p>
              </div>
            </div>
          </div>
          <div class="card-body p-2">
            <!--vaccine list-->
            <div class="d-flex d-flex mt-3 ">
              <label class="font-weight-bold">{{$t('first_vaccine_is_from_foreign')}}  &nbsp; &nbsp;</label>
                  <div class="">
                      <input id="radio-a" type="radio" value="1" name='is_from_foreign'  v-model="data.is_from_foreign" @change="toggleForeigner"/>
                      <label for="radio-a"> Yes&nbsp; &nbsp;</label>
                  </div>
                  <div class="">
                      <input id="radio-b" type="radio" value="0"  name='is_from_foreign' v-model="data.is_from_foreign" @change="toggleForeigner"/>
                      <label  for="radio-b">No &nbsp; &nbsp;</label>
                  </div>
              </div>
              <div class="vaccine_detail" >
                  <div class="row">
                    <div class="vaccinatiin-type mt-4 col-md-2">
                      <input class="radio-group" type="checkbox"  @change="firstDoseDetail($event)"   id="first" name="first" value="1M" :disabled="prevrecord || vaccinationCompleted"
                          v-model="data.first_vaccine_period">
                      <b>{{ $t('first_dose')}}</b>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                          <select v-model="data.first_vaccine_name" id="vaccine_name"  @change="fillVaccineType();filterNoOfDose();" :disabled="!data.first_vaccine_period">
                              <option value="">{{ $t('select_options') }}</option>
                              <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                          </select>
                      <label for="vaccine_name" class="control-label">{{ $t('vaccinte_type') }}</label><i class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.data.first_vaccine_name.required">{{ $t('field_required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                      <input type="text"  id="first-vaccinated"  v-model.trim="data.date_of_first_vaccinated" value="" v-mask="'####-##-##'" class="has-value" :disabled="!data.first_vaccine_period" placeholder="e.g. 2078-02-24">
                        <label class="control-label">{{ $t('vaccinated_date') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_first_vaccinated.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_first_vaccinated.date">{{ $t('Date Format Error') }}</div>

                      </div>
                    </div>
                      <div class="col-md-2" v-if="this.$user.role == 'healthpost' || this.$user.role == 'healthworker' || editmode">
                        <div class="form-group vacc-add" v-show="!previously_vaccinated">
                            <input type="text" name="vaccinated_address" required="required" class="has-value"
                                  v-model="data.first_vaccinated_address"   >
                            <label class="control-label">{{ $t('vaccinated_address')}}</label><i class="bar"></i>
                        </div>
                      </div>
                      <div class="col-md-2"  v-if="(this.$user.role == 'municipality' && !editmode )">
                        <div class="form-group" v-show="!previously_vaccinated">
                            <select name="vaccination_center" v-model="data.vaccination_center_id" @change="filterVaccinationCenter()"
                                    id="vaccination_center">
                                <option value="" selected>{{ $t('select_options') }}</option>
                                <option v-for="data in vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                                <!-- <option value="0">{{ $t('dont_know')}}</option> -->
                            </select>
                            <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                            class="bar"></i>
                            <div class="help-block text-danger" v-if="!$v.data.vaccination_center_id.required">{{ $t('field_required') }}</div>
                        </div>
                      </div>
                        <div class="col-md-2" v-show="!data.first_vaccine_batch_no">
                          <div class="form-group">
                            <select v-model="data.first_vaccine_batch_no" id="batch_no_first" @change="clearBatchNo()" >
                                  <option value="" >{{ $t('select_options') }}</option>
                                  <option v-for="batch_no in batch_numbers" :key="batch_no.id" :value="batch_no.batch_no">{{batch_no.batch_no}}</option>
                                  <option  value="0" >{{ $t('dont_know')}}</option>
                              </select>
                              <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                                <!-- <div class="help-block text-danger" v-if="!$v.data.first_vaccine_batch_no.required">{{ $t('field_required') }}</div> -->
                          </div>
                      </div>
                      <div class="col-md-2" v-show="data.first_vaccine_batch_no">
                        <div class="form-group">
                          <input type="text" v-model="data.first_vaccine_batch_no" id="">
                              <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                        </div>
                      </div>
                    <div class="vaccinatiin-type mt-4 col-md-1"  >
                      <input class="radio-group" type="checkbox" id="first" name="other" @change="firstVaccinationToggle($event)" :disabled="this.data.is_from_foreign==1 || prevrecord || vaccinationCompleted || !data.first_vaccine_period || required_no_of_dose == 1"
                      v-model="previously_vaccinated"><b>{{ $t('other') }}</b>&nbsp; &nbsp;
                    </div>
                  </div>
              </div>
              <div class="container-flex3 m-0" v-show="previously_vaccinated">
                  <div class="form-group" >
                      <select name="vac_province_id" id="vac_province_id"
                              v-on:change="fetchVacDistricts(data)" v-model="data.vac_province_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_provinces" :value="data.id">{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_district_id" id="vac_district_id"
                              v-on:change="fetchVacMunicipalities(data)" v-model="data.vac_district_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_districts" :value="data.id">{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_municipality_id" v-model="data.vac_municipality_id"
                              id="vac_municipality_id" v-on:change="fetchVaccinationCenters(data)">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_municipalities" :value="data.id">{{ data.municipality_name }}
                          </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                      class="bar"></i>
                  </div>
                  <div class="form-group">
                      <select name="vaccination_center" v-model="data.vaccination_center_id" @change="filterVaccinationCenter()"
                              id="vaccination_center">
                          <option value="" selected>{{ $t('select_options') }}</option>
                          <option v-for="data in vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                          <!-- <option value="0">{{ $t('dont_know')}}</option> -->
                      </select>
                      <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                      class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.data.vaccination_center_id.required">{{ $t('field_required') }}</div>
                  </div>
              </div>

              <div class="vaccine_detail" v-show="second_dose_enabler">
                <div class="d-flex d-flex mt-3 ">
                <label class="font-weight-bold">{{$t('second_vaccine_is_from_foreign')}}  &nbsp; &nbsp;</label>
                    <div class="">
                        <input id="radio-a"  type="radio" value="1"   v-model="data.second_is_from_foreign" @change="toggleForeignerSecond"/>
                        <label for="radio-a"> Yes&nbsp; &nbsp;</label>
                    </div>
                    <div class="">
                        <input id="radio-b" type="radio" value="0"    v-model="data.second_is_from_foreign" @change="toggleForeignerSecond"/>
                        <label  for="radio-b">No &nbsp; &nbsp;</label>
                    </div>

                </div>
                <div class="row">
                  <div class="vaccination-type mt-4 col-md-2" >
                    <input class="radio-group" type="checkbox"  @change="secondDoseDetail($event)"  id="second" name="second" value="3M"  :disabled="vaccinationCompleted"
                          v-model="data.second_vaccine_period">
                      <b>{{ $t('second_dose') }}</b>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <select v-model="data.second_vaccine_name" id="vaccine_name" @change="fillSecondVaccineType()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                        </select>
                    <label for="vaccine_name" class="control-label">{{ $t('vaccinte_type') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.data.second_vaccine_name.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group ">
                      <input type="text"  id="date-of-second-vaccinated"  v-model.trim="data.date_of_second_vaccinated" value=""  v-mask="'####-##-##'" :disabled=" !data.second_vaccine_period" class="has-value" placeholder="e.g. 2078-02-24">
                        <label class="control-label">{{ $t('vaccinated_date') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_second_vaccinated.isNotSameAsFirstDate">{{ $t('vaccinated_date_difference') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_second_vaccinated.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_second_vaccinated.date">{{ $t('Date Format Error') }}</div>

                    </div>
                  </div>
                  <div class="col-md-2" v-if="(this.$user.role == 'healthpost' || this.$user.role == 'healthworker' || editmode) && !this.$gate.isMunicipality()">
                    <div class="form-group vacc-add">
                        <input type="text" name="vaccinated_address"  :disabled="vaccinationCompleted" class="has-value"
                              v-model="data.second_vaccinated_address">
                        <label class="control-label">{{ $t('vaccinated_address')}}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.second_vaccination_center_id.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>
                  <div class="col-md-2"  v-if="(this.$user.role == 'municipality' && !editmode)|| (!data.second_vaccinated_address && this.$gate.isMunicipality())">
                        <div class="form-group">
                            <select name="vaccination_center" v-model="data.second_vaccination_center_id" @change="filterSecondVaccinationCenter()"
                                    id="vaccination_center">
                                <option value="" selected>{{ $t('select_options') }}</option>
                                <option v-for="data in second_vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                                <!-- <option value="0">{{ $t('dont_know')}}</option> -->
                            </select>
                            <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                            class="bar"></i>
                            <div class="help-block text-danger" v-if="!$v.data.second_vaccination_center_id.required">{{ $t('field_required') }}</div>
                        </div>
                      </div>
                  <div class="col-md-2" v-show="!data.second_vaccine_batch_no">
                    <div class="form-group">
                      <select v-model="data.second_vaccine_batch_no" id="batch_no_second" @change="clearSecondBatchNo()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="batch_no in second_batch_numbers" :key="batch_no.id" :value="batch_no.batch_no">{{batch_no.batch_no}}</option>
                            <option value="0">{{ $t('dont_know')}}</option>
                        </select>
                        <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                  <div class="col-md-2" v-show="data.second_vaccine_batch_no">
                    <div class="form-group">
                      <input type="text" v-model="data.second_vaccine_batch_no" id="">
                          <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                  <div class="vaccinatiin-type mt-4 col-md-1"  >
                    <input class="radio-group" type="checkbox" id="first" name="other" @change="secondVaccinationToggle($event)" :disabled="vaccinationCompleted || !data.second_vaccine_period || required_no_of_dose == 1"
                    v-model="previously_second_vaccinated"><b>{{ $t('other') }}</b>&nbsp; &nbsp;
                  </div>
                </div>
              </div>
              <div class="container-flex3 m-0" v-show="previously_second_vaccinated">
                  <div class="form-group" >
                      <select name="vac_province_id" id="vac_province_id"
                              v-on:change="fetchVacDistricts(data)" v-model="data.vac_province_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_provinces" :value="data.id">{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_district_id" id="vac_district_id"
                              v-on:change="fetchVacMunicipalities(data)" v-model="data.vac_district_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_districts" :value="data.id">{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_municipality_id" v-model="data.vac_municipality_id"
                              id="vac_municipality_id" v-on:change="fetchSecondVaccinationCenters(data)">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_municipalities" :value="data.id">{{ data.municipality_name }}
                          </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                      class="bar"></i>
                  </div>
                  <div class="form-group">
                      <select name="vaccination_center" v-model="data.second_vaccination_center_id" @change="filterSecondVaccinationCenter()"
                              id="vaccination_center">
                          <option value="" selected>{{ $t('select_options') }}</option>
                          <option v-for="data in second_vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                          <!-- <option value="0">{{ $t('dont_know')}}</option> -->
                      </select>
                      <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                      class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.data.second_vaccination_center_id.required">{{ $t('field_required') }}</div>
                  </div>
              </div>

              <div class="vaccine_detail" v-show="third_dose_enabler">
                <div class="row">
                  <div class="vaccination-type mt-4 col-md-2" >
                    <input class="radio-group" type="checkbox"  @change="thirdDoseDetail($event,data)"  id="third" name="third" value="3"
                          v-model="data.third_vaccine_period">
                      <b>{{ $t('third_dose') }}</b>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <select v-model="data.third_vaccine_name" id="vaccine_name" @change="fillThirdVaccineType()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                        </select>
                    <label for="vaccine_name" class="control-label">{{ $t('vaccinte_type') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.data.third_vaccine_name.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group ">
                      <input type="text"  id="date-of-third-vaccinated" v-mask="'####-##-##'" v-model.trim="data.date_of_third_vaccinated" value=""  :disabled="!data.third_vaccine_period" class="has-value" placeholder="e.g. 2078-02-24">
                        <label class="control-label">{{ $t('vaccinated_date') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_third_vaccinated.isNotSameAsSecondDate">{{ $t('vaccinated_date_difference_for_third_dose') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_third_vaccinated.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_third_vaccinated.date">{{ $t('Date Format Error') }}</div>
                    </div>
                  </div>
                  <div class="col-md-2" v-if="(this.$user.role == 'healthpost' || this.$user.role == 'healthworker' || editmode) && !this.$gate.isMunicipality()">
                    <div class="form-group vacc-add">
                        <input type="text" name="vaccinated_address" readonly  class="has-value"
                              v-model="data.third_vaccinated_address">
                        <label class="control-label">{{ $t('vaccinated_address')}}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.third_vaccination_center_id.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>

                  <div class="col-md-2"  v-if="(this.$user.role == 'municipality' && !editmode)|| (!data.third_vaccinated_address && this.$gate.isMunicipality())">
                        <div class="form-group">
                            <select name="vaccination_center" v-model="data.third_vaccination_center_id" @change="filterThirdVaccinationCenter()"
                                    id="vaccination_center">
                                <option value="" selected>{{ $t('select_options') }}</option>
                                <option v-for="data in third_vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                            </select>
                            <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                            class="bar"></i>
                        </div>
                  </div>
                  <div class="col-md-2" v-show="third_batch_numbers.length">
                    <div class="form-group">
                      <select v-model="data.third_vaccine_batch_no" id="batch_no_third" @change="clearThirdBatchNo()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="batch_no in third_batch_numbers" :key="batch_no.id" :value="batch_no.batch_no">{{batch_no.batch_no}}</option>
                            <option value="0">{{ $t('dont_know')}}</option>
                        </select>
                        <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                  <div class="col-md-2" v-show="!third_batch_numbers.length">
                    <div class="form-group">
                      <input type="text" v-model="data.third_vaccine_batch_no" id="">
                          <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                  <div class="vaccinatiin-type mt-4 col-md-1"  >
                    <input class="radio-group" type="checkbox" id="first" name="other" @change="thirdVaccinationToggle($event)" :disabled="vaccinationCompleted || !data.third_vaccine_period || required_no_of_dose == 1"
                    v-model="previously_third_vaccinated"><b>{{ $t('other') }}</b>&nbsp; &nbsp;
                  </div>
                </div>
              </div>

             <div class="container-flex3 m-0" v-show="previously_third_vaccinated">
                  <div class="form-group" >
                      <select name="vac_province_id" id="vac_province_id"
                              v-on:change="fetchVacDistricts(data)" v-model="data.vac_province_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_provinces" :value="data.id">{{ data.province_name }}</option>
                      </select>
                      <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_district_id" id="vac_district_id"
                              v-on:change="fetchVacMunicipalities(data)" v-model="data.vac_district_id">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_districts" :value="data.id">{{ data.district_name }}</option>
                      </select>
                      <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                  </div>
                  <div class="form-group" >
                      <select name="vac_municipality_id" v-model="data.vac_municipality_id"
                              id="vac_municipality_id" v-on:change="fetchThirdVaccinationCenters(data)">
                          <option value="">{{ $t('select_options') }}</option>
                          <option v-for="data in vac_municipalities" :value="data.id">{{ data.municipality_name }}
                          </option>
                      </select>
                      <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                      class="bar"></i>
                  </div>
                  <div class="form-group">
                      <select name="vaccination_center" v-model="data.third_vaccination_center_id" @change="filterThirdVaccinationCenter()"
                              id="vaccination_center">
                          <option value="" selected>{{ $t('select_options') }}</option>
                          <option v-for="data in third_vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                          <!-- <option value="0">{{ $t('dont_know')}}</option> -->
                      </select>
                      <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                      class="bar"></i>
                      <div class="help-block text-danger" v-if="!$v.data.third_vaccination_center_id.required">{{ $t('field_required') }}</div>
                  </div>
              </div>

            <div class="vaccine_detail" v-show="fourth_dose_enabler">
                <div class="row">
                  <div class="vaccination-type mt-4 col-md-2" >
                    <input class="radio-group" type="checkbox"  @change="fourthDoseDetail($event,data)"  id="fourth" name="fourth" value="4"
                          v-model="data.fourth_vaccine_period">
                      <b>{{ $t('fourth_dose') }}</b>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <select v-model="data.fourth_vaccine_name" id="vaccine_name" @change="fillFourthVaccineType()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.name">{{vaccine.name}}</option>
                        </select>
                    <label for="vaccine_name" class="control-label">{{ $t('vaccinte_type') }}</label><i class="bar"></i>
                    <div class="help-block text-danger" v-if="!$v.data.fourth_vaccine_name.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group ">
                      <input type="text"  id="date-of-third-vaccinated" v-mask="'####-##-##'" v-model.trim="data.date_of_fourth_vaccinated" value=""  :disabled="!data.fourth_vaccine_period" class="has-value" placeholder="e.g. 2078-02-24">
                        <label class="control-label">{{ $t('vaccinated_date') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_fourth_vaccinated.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.data.date_of_fourth_vaccinated.date">{{ $t('Date Format Error') }}</div>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="(this.$user.role == 'healthpost' || this.$user.role == 'healthworker' || editmode) && !this.$gate.isMunicipality()">
                    <div class="form-group vacc-add">
                        <input type="text" name="vaccinated_address" readonly  class="has-value"
                              v-model="data.fourth_vaccinated_address">
                        <label class="control-label">{{ $t('vaccinated_address')}}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.fourth_vaccination_center_id.required">{{ $t('field_required') }}</div>
                    </div>
                  </div>

                  <div class="col-md-3"  v-if="(this.$user.role == 'municipality' && !editmode)|| (!data.fourth_vaccinated_address && this.$gate.isMunicipality())">
                        <div class="form-group">
                            <select name="vaccination_center" v-model="data.fourth_vaccination_center_id" @change="filterFourthVaccinationCenter()"
                                    id="vaccination_center">
                                <option value="" selected>{{ $t('select_options') }}</option>
                                <option v-for="data in fourth_vaccinations" :value="data.id">{{ data.vaccination_center }}</option>
                            </select>
                            <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                            class="bar"></i>
                        </div>
                      </div>
                  <div class="col-md-2" v-show="fourth_batch_numbers.length">
                    <div class="form-group">
                      <select v-model="data.fourth_vaccine_batch_no" id="batch_no_third" @change="clearFourthBatchNo()">
                            <option value="">{{ $t('select_options') }}</option>
                            <option v-for="batch_no in fourth_batch_numbers" :key="batch_no.id" :value="batch_no.batch_no">{{batch_no.batch_no}}</option>
                            <option value="0">{{ $t('dont_know')}}</option>
                        </select>
                        <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                  <div class="col-md-2" v-show="!fourth_batch_numbers.length">
                    <div class="form-group">
                      <input type="text" v-model="data.fourth_vaccine_batch_no" id="">
                          <label class="control-label">{{ $t('batch_no') }}</label><i class="bar"></i>
                    </div>
                  </div>
                </div>
              </div>



          </div>
      </div>

      <div class="card  rounded-0 collapsed-card " :class="{ 'd-none' : !data.first_vaccine_period || !data.first_vaccine_name || !data.first_vaccinated_address }">
          <div class="card-header rounded-0 bg-blue p-0" data-card-widget="collapse">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i></button>
            <h4 class="d-inline"><small>{{ $t('aefi_status') }}</small></h4>

          </div>
          <div class="card-body p-2">
            <!--aefi-->
            <div class="form-group">
                  <select v-model="data.aefi_type" id="aefi_type" @change="getNepaliDateAefi()">
                      <option value="">{{ $t('select_options') }}</option>
                      <option value="minor" selected>Minor</option>
                      <option value="serious" selected>Serious</option>
                  </select>
              <label for="aefi_type" class="control-label">{{ $t('aefi_type') }} </label><i class="bar"></i>
            </div>
            <div class="form-group ">
                <input type="text" id="aefi-date" class="has-value" :value="data.aefi_date" readonly>
                <label class="control-label">{{ $t('date') }} ( e.g. 2078-02-24 )</label><i class="bar"></i>
            </div>
            <div class="form-group">
                <input type="text" name="aefi_remarks" required="required" v-model="data.aefi_remarks">
                <label class="control-label">{{ $t('remarks') }}</label><i class="bar"></i>
            </div>
          </div>
          <div class="card-body table-responsive table-bordered p-3" v-if="editmode && aefis.length > 0" >
            <table class="table table-hover text-center">
              <tbody>
                <tr class="bg-white">
                  <th>S.N</th>
                    <th>{{ $t('type') }}</th>
                    <th>{{ $t('date') }}</th>
                    <th>{{ $t('remarks') }}</th>
                </tr>
                <tr v-for="(aefi, index) in aefis"  :key="aefi.id">
                  <th>{{index+1}}</th>
                    <td>
                      {{aefi.type}}
                    </td>
                    <td>
                      {{aefi.date_np}}
                    </td>

                    <td>
                      {{aefi.remarks}}
                    </td>

                </tr>
              </tbody>
            </table>
          </div>
      </div>
      <div class="button-container">
            <button type="button" v-show="!editmode" class="button btn-block" id="button"
                    @click="submitDetail"><span>{{ $t('submit') }}</span>
            </button>
            <button type="button" v-show="editmode" class="button btn-block" id="button"
                    @click.prevent="updateDetail"><span>{{ $t('update') }}</span>
            </button>
      </div>
    </div>
    <preview-modal
    :modal_data="modal_data"
    :date_of_birth="String(this.date_of_birth)"
    :vaccination_records="vaccination_records"
    :age="String(this.age)"
    :item="data" />



  </div>
</template>
<script type="text/javascript">
import {required, between, minLength, maxLength, not, sameAs,requiredIf,helpers,email} from "vuelidate/lib/validators";
import provinces from '../json/provinces.json'
import districts from '../json/districts.json'
import municipalities from '../json/municipalities.json'
import DataConverter from "ad-bs-converter";
import PublicRegisterConfirmationModal from '../components/public-register-confirmation-modal'
import axios from "axios";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import PreviewModal from './modal/preview-modal.vue';
import Vue from 'vue';

const date = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(value)
}
export default {
  components: {
        VueSlider,
    PreviewModal
    },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      masks: {
      input: 'YYYY-MM-DD'
      },
      has_value:false,
      confirmed:false,
      debounce : null,
      editmode : false,
      clicked: false,
      searchmode:false,
      vaccinationCompleted:false,
      modal_data:{
          modal_size:'modal-lg',
        },
      data: {
        'caste': '5',
        'gender': '',
        'nationality': 'Nepal',
        'occupation': 0,
        'province_id': (this.$user.province_id).toString(),
        'district_id': this.$user.district_id.toString(),
        'municipality_id': (this.$user.municipality_id).toString(),
        'province_name': '',
        'district_name': '',
        'municipality_name': '',
        'identity_type': '',
        'identity_no': '',
        'phone': '',
        'ward':'',
        'occupation': '',
        'issue_org':'',
        'email_address':'',
        'medical_condition': [21],
        'is_from_foreign':0,
        'second_is_from_foreign':0,
        'category': '16',
        'dob_type': '1',
        'vac_province_id': '',
        'vac_district_id': '',
        'vac_municipality_id': '',
        'vaccination_center_id' : '',
        'register_for': '',
        'vaccine_type': '',
        'second_vaccine_type': '',
        third_vaccine_type: '',

        'disability_type': null,
        'first_vaccine_name':'',
        'first_vaccine_period':'',
        'date_of_first_vaccinated': '',
        'first_vaccinated_address':'',
        'first_vaccination_center_id':'',
        'first_vaccine_batch_no':'',
        'second_vaccine_batch_no':'',
        'second_vaccine_name':'',
        'second_vaccine_period':'',
        third_vaccine_period:'',
        third_vaccine_name:'',
        date_of_third_vaccinated: '',
        third_vaccinated_address:'',
        third_vaccination_center_id:'',
        third_vaccine_batch_no:'',

        fourth_vaccine_period:'',
        fourth_vaccine_name:'',
        date_of_fourth_vaccinated: '',
        fourth_vaccinated_address:'',
        fourth_vaccination_center_id:'',
        fourth_vaccine_batch_no:'',
        fourth_vaccine_type : '',

        'date_of_second_vaccinated': '',
        'second_vaccinated_address':'',
        'second_vaccination_center_id':'',
        'aefi_type':'',
        'aefi_date': '',
        'vaccination_center' : this.$user.vaccination_center_id,
        'created_by' : this.$user.token,
      },
      // occupation_list: JSON.parse(JSON.stringify(occupations.essentials)),
      provinces: JSON.parse(JSON.stringify(provinces)),
      vac_provinces: JSON.parse(JSON.stringify(provinces)),
      districts: [],
      municipalities: [],
      vac_districts: [],
      vac_municipalities: [],
      vaccinations: [],
      second_vaccinations: [],
      third_vaccinations: [],
      fourth_vaccinations : [],

      country: 'Nepal',
      date_of_birth: '',
      vaccine_current_address: '1',
      is_disabled: 0,
      users:null,
      aefis:null,
      vaccination_records:null,
      prevrecord:false,
      date_diff:'',
      date_diff_third:'',
    date_diff_fourth:'',
      search:{
        reg_no:'',
        province_id: this.$user.province_id,
        district_id: this.$user.district_id,
        municipality_id:this.$user.municipality_id,
        name: '',
        phone:'',
        ward: '',
        gender: '',
        age_range: '',
        identity_type:null,
        identity_no:null,
        issue_org:null,
      },
      previously_vaccinated:false,
      previously_second_vaccinated:false,
    previously_third_vaccinated:false,
      errors:{
      },
      vaccines:{},
      disabled:false,
      searchType:'',
      required_no_of_dose:'',
      batch_numbers:{},
      second_batch_numbers:{},
      third_batch_numbers:{},
    fourth_batch_numbers:{},

    }
  },
  validations() {
     if(this.searchType === 'registration'){
      return{
        search:{
          province_id:{required:false},
          identity_type:{required:false},
          identity_no:{required:false},
          issue_org:{required:false},
          reg_no:{required},
          district_id:{required:false},
          municipality_id:{required:false},
          name: {required:false},
          gender: {
                required:false
              },
          ward: {
                required:false
              },
          age_range: {
                required:false
              },
          phone: {
                required:false,

              },
        },

        date_of_birth: {
              required:false,
              date:false

          },
          age: {
              required:false,
          },
          data: {
              first_name: {
                  required:false
              },
              last_name: {
                  required:false
              },
              phone:{
                  required:false,
                  minLength: false,
                  maxLength: false
              },
              caste: {
                  required:false
              },
              gender: {
                  required:false
              },
              nationality: {
                  required:false
              },
              province_id: {
                  required:false
              },
              district_id: {
                  required:false
              },
              municipality_id: {
                  required:false
              },
              ward: {
                  required:false,
                  between: false
              },
              category: {
                  required:false
              },
              medical_condition: {
                  required:false
              },
              date_of_first_vaccinated: {
                  required: false
              },
              vaccinated_address: {
                  required: false
              },
              identity_type: {
                  required:false
              },
              identity_no: {
                  required:false,
                  maxLength: false,
              },
              issue_org: {
                  required:false,
                  maxLength: false,
              },
              date_of_second_vaccinated:{
                isNotSameAsFirstDate: false,
                required:false

              },
              email_address:{
                email:false
                },
                first_vaccine_name:{required:false},
                second_vaccine_name:{required:false},
                vaccination_center_id:{required:false},
                second_vaccination_center_id:{required:false},
                third_vaccine_name:{required:false},
                third_vaccination_center_id:{required:false},
                date_of_third_vaccinated:{required:false},
                 fourth_vaccine_name:{required:false},
                fourth_vaccination_center_id:{required:false},
                date_of_fourth_vaccinated:{required:false}


          },
      }
    }
    if(this.searchType === 'normal'){
      return{
        search:{
          reg_no:{required},
          province_id:{required},
          district_id:{required},
          municipality_id:{required},
        },
        date_of_birth: {
              required:false,

          },
          age: {
              required:false,
          },
          data: {
              first_name: {
                  required:false
              },
              last_name: {
                  required:false
              },
              phone:{
                  required:false,
                  minLength: false,
                  maxLength: false
              },
              caste: {
                  required:false
              },
              gender: {
                  required:false
              },
              nationality: {
                  required:false
              },
              province_id: {
                  required:false
              },
              district_id: {
                  required:false
              },
              municipality_id: {
                  required:false
              },
              ward: {
                  required:false,
                  between: false
              },
              category: {
                  required:false
              },
              medical_condition: {
                  required:false
              },
              date_of_first_vaccinated: {
                  required: false,
              },
              vaccinated_address: {
                  required: false
              },
              identity_type: {
                  required:false
              },
              identity_no: {
                  required:false,
                  maxLength: false,
              },
              issue_org: {
                  required:false,
                  maxLength: false,
              },
              date_of_second_vaccinated:{
                isNotSameAsFirstDate: false,
                required:false
              },
              email_address:{
                email:false
                },
                first_vaccine_name:{required:false},
                second_vaccine_name:{required:false},
                vaccination_center_id:{required:false},
                second_vaccination_center_id:{required:false},
                third_vaccine_name:{required:false},
                third_vaccination_center_id:{required:false},
                date_of_third_vaccinated:{required:false},
                 fourth_vaccine_name:{required:false},
                fourth_vaccination_center_id:{required:false},
                date_of_fourth_vaccinated:{required:false}


          },
      }
    }
    if(this.searchType === 'advanced'){
      return{
        search:{
          province_id:{required},
          district_id:{required},
          municipality_id:{required},

          gender: {
                required
              },
          phone: {
                required,

              },

        },
        date_of_birth: {
              required:false,

          },
          age: {
              required:false,
          },
          data: {
              first_name: {
                  required:false
              },
              last_name: {
                  required:false
              },
              phone:{
                  required:false,
                  minLength: false,
                  maxLength: false
              },
              caste: {
                  required:false
              },
              gender: {
                  required:false
              },
              nationality: {
                  required:false
              },
              province_id: {
                  required:false
              },
              district_id: {
                  required:false
              },
              municipality_id: {
                  required:false
              },
              ward: {
                  required:false,
                  between: false
              },
              category: {
                  required:false
              },
              medical_condition: {
                  required:false
              },
              date_of_first_vaccinated: {
                  required: false
              },
              vaccinated_address: {
                  required: false
              },
              identity_type: {
                  required:false
              },
              identity_no: {
                  required:false,
                  maxLength: false,
              },
              issue_org: {
                  required:false,
                  maxLength: false,
              },
              date_of_second_vaccinated:{
                isNotSameAsFirstDate: false,
                required:false
              },
              email_address:{
                email:false
                },
              first_vaccine_name:{required:false},
              second_vaccine_name:{required:false},
              vaccination_center_id:{required:false},
              second_vaccination_center_id:{required:false},
              third_vaccine_name:{required:false},
              third_vaccination_center_id:{required:false},
              date_of_third_vaccinated:{required:false},

               fourth_vaccine_name:{required:false},
                fourth_vaccination_center_id:{required:false},
                date_of_fourth_vaccinated:{required:false}

          },
      }
    }
    if(this.searchType === 'identity'){
      return{
        search:{
          province_id:{required},
          district_id:{required},
          municipality_id:{required},
          identity_type:{required},
          identity_no:{required},
        },
        date_of_birth: {
              required:false,

          },
          age: {
              required:false,
          },
          data: {
              first_name: {
                  required:false
              },
              last_name: {
                  required:false
              },
              phone:{
                  required:false,
                  minLength: false,
                  maxLength: false
              },
              caste: {
                  required:false
              },
              gender: {
                  required:false
              },
              nationality: {
                  required:false
              },
              province_id: {
                  required:false
              },
              district_id: {
                  required:false
              },
              municipality_id: {
                  required:false
              },
              ward: {
                  required:false,
                  between: false
              },
              category: {
                  required:false
              },
              medical_condition: {
                  required:false
              },
              date_of_first_vaccinated: {
                  required: false
              },
              vaccinated_address: {
                  required: false,
              },
              identity_type: {
                  required:false
              },
              identity_no: {
                  required:false,
                  maxLength: false,

              },
              issue_org: {
                  required:false,
                  maxLength: false,

              },
              date_of_second_vaccinated:{
                isNotSameAsFirstDate: false,
                 required:false},
              email_address:{
                email:false
                },
                first_vaccine_name:{required:false},
                second_vaccine_name:{required:false},
                vaccination_center_id:{required:false},
                second_vaccination_center_id:{required:false},
                third_vaccine_name:{required:false},
                third_vaccination_center_id:{required:false},
                date_of_third_vaccinated:{required:false},

                fourth_vaccine_name:{required:false},
                fourth_vaccination_center_id:{required:false},
                date_of_fourth_vaccinated:{required:false}


          },

      }
    }
    else{
      return {
          date_of_birth: {
              required,
              date,
          },
          age: {
              required,
               between:between(5, 120)
          },
          data: {
              first_name: {
                  required
              },
              last_name: {
                  required
              },
              phone:{
                  required,
                  minLength: minLength(10),
                  maxLength: maxLength(14)
              },
              caste: {
                  required
              },
              gender: {
                  required
              },
              nationality: {
                  required
              },
              province_id: {
                  required
              },
              district_id: {
                  required
              },
              municipality_id: {
                  required
              },
              ward: {
                  required,
                  between: between(1, 34)
              },
              category: {
                  required
              },
              medical_condition: {
                  required
              },
              vaccinated_address: {
                  required: false
              },
              identity_type: {
                  required
              },
              identity_no: {
                  required,
                  maxLength: maxLength(64),
              },
              issue_org: {
                  required,
                  maxLength: maxLength(64),
              },
              date_of_second_vaccinated:{
                date,
                isNotSameAsFirstDate: not(sameAs('date_of_first_vaccinated')),
                required: requiredIf(function(){
                  return (this.data.second_vaccine_period)
                      })
              },
              email_address:{
                email
              },

              first_vaccine_name: {
              required: requiredIf(function(){
                return (this.data.first_vaccine_period)
                    })
              },
              second_vaccine_name: {
              required: requiredIf(function(){
                return (this.data.second_vaccine_period)
                    })
              },
              vaccination_center_id: {
                required: requiredIf(function(){
                  return (this.data.first_vaccine_period && !this.data.is_from_foreign)
                      })
              },
              second_vaccination_center_id: {
                required: requiredIf(function(){
                  return (this.data.second_vaccine_period)
                      })
              },
              date_of_first_vaccinated: {
                date,
                required: requiredIf(function(){
                  return (this.data.first_vaccine_period)
                      })
              },

              date_of_third_vaccinated:{
                date,
              isNotSameAsSecondDate: not(sameAs('date_of_second_vaccinated')),
              required: requiredIf(function(){
                return (this.data.third_vaccine_period)
                })
              },
              third_vaccine_name: {
              required: requiredIf(function(){
                return (this.data.third_vaccine_period)
                    })
              },
              third_vaccination_center_id: {
              required: requiredIf(function(){
                return (this.data.third_vaccine_period)
                    })
              },


              date_of_fourth_vaccinated:{
                date,
              required: requiredIf(function(){
                return (this.data.fourth_vaccine_period)
                })
              },
              fourth_vaccine_name: {
              required: requiredIf(function(){
                return (this.data.fourth_vaccine_period)
                    })
              },
              fourth_vaccination_center_id: {
              required: requiredIf(function(){
                return (this.data.fourth_vaccine_period)
                    })
              },


          },

          search:{
            reg_no:{required:false},
            province_id:{required:false},
            district_id:{required:false},
            municipality_id:{required:false},
            name:{required:false},
            phone:{required:false},
            ward:{required:false},
            ageRange:{required:false},
            gender:{required:false},
            identity_no:{required:false},
            identity_type:{required:false},
            issue_org:{required:false},
          }
      }
    }
  },
  methods: {
    regSearch(){
      this.searchType = 'registration',
      this.searchmode = true
    },

    //vatch numbers
    clearBatchNo(){
      if(this.data.first_vaccine_batch_no == 0){
        this.batch_numbers = ''
      }
    },
    clearSecondBatchNo(){
      if(this.data.second_vaccine_batch_no == 0){
        this.second_batch_numbers = ''
      }
    },
    clearThirdBatchNo(){
      if(this.data.third_vaccine_batch_no == 0){
        this.third_batch_numbers = ''
      }
    },
    clearFourthBatchNo(){
      if(this.data.fourth_vaccine_batch_no == 0){
        this.fourth_batch_numbers = ''
      }
    },


    //vaccines
    filteredVaccines(id) {
    return this.vaccines[id];
    },
    filterNoOfDose(){
      this.vaccines.filter(({name,required_no_of_dose})=>{
                name == this.data.first_vaccine_name ? this.required_no_of_dose = required_no_of_dose : ''
              })
    },
    fillVaccineType(){
      this.data.second_vaccine_period = false
      this.data.third_vaccine_period = false
      this.data.second_vaccine_name = ''
      this.data.third_vaccine_name = ''
      this.vaccines.filter(({id,name})=>(
          name == this.data.first_vaccine_name ? this.data.vaccine_type = id : ''
      ))


       if(this.data.vaccine_type == 6){
         this.previously_vaccinated = false
          this.data.second_vaccination_center_id = ''
          this.data.date_of_second_vaccinated = ''
          this.data.second_vaccinated_address = ''
          this.data.second_vaccine_name = ''
      }
    },

    fillSecondVaccineType(){
      this.vaccines.filter(({id,name})=>(
          name == this.data.second_vaccine_name ? this.data.second_vaccine_type = id : ''
      ))
    },
    fillThirdVaccineType(){
      this.vaccines.filter(({id,name})=>(
          name == this.data.third_vaccine_name ? this.data.third_vaccine_type = id : ''
      ))
    },
    fillFourthVaccineType(){
      this.vaccines.filter(({id,name})=>(
          name == this.data.fourth_vaccine_name ? this.data.fourth_vaccine_type = id : ''
      ))
    },

    //first dose from foreign
    toggleForeigner(){
      if(this.data.is_from_foreign == 1){
        this.data.vaccination_center_id = 0
        this.data.first_vaccinated_address =  ''
      }
      if(this.data.is_from_foreign == 0){
        if(this.$user.role == 'municipality'){
          axios.get('/api/vaccination-center?municipality_id=' + this.$user.municipality_id)
          .then((response) => {
            this.vaccinations = response.data
            return this.vaccinations;
          });
        }
        axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
                  .then((response) => {
                      this.data.first_vaccinated_address = response.data.vaccination_center
                      this.data.vaccination_center_id = this.$user.vaccination_center_id
                  });
      }
    },
    toggleForeignerSecond(){
      if(this.data.second_is_from_foreign == 1){
        this.data.second_vaccination_center_id = 0
        this.data.second_vaccinated_address =  ''
      }else{
        if(this.$user.role == 'municipality'){
          axios.get('/api/vaccination-center?municipality_id=' + this.$user.municipality_id)
          .then((response) => {
            this.vaccinations = response.data
            return this.vaccinations;
          });
        }else{
          axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
                    .then((response) => {
                        this.data.second_vaccinated_address = response.data.vaccination_center
                        this.data.second_vaccination_center_id = this.$user.vaccination_center_id
                    });
        }

      }
    },

    newContent(){
      window.location.reload();
    },

    //vaccination center

    filterVaccinationCenter(){
      this.vaccinations.filter(({id,vaccination_center})=>( id == this.data.vaccination_center_id ? this.data.first_vaccinated_address = vaccination_center : ''))
      if(this.$user.role == 'municipality'){
        this.loadBatchNo(this.data.vaccination_center_id)
      }
    },
    filterSecondVaccinationCenter(){
      this.second_vaccinations.filter(({id,vaccination_center})=>( id == this.data.second_vaccination_center_id ? this.data.second_vaccinated_address = vaccination_center : ''))
      if(this.$user.role == 'municipality'){
        this.loadSecondBatchNo(this.data.second_vaccination_center_id)
      }
    },
    filterThirdVaccinationCenter(){
      this.third_vaccinations.filter(({id,vaccination_center})=>( id == this.data.third_vaccination_center_id ? this.data.third_vaccinated_address = vaccination_center : ''))
      if(this.$user.role == 'municipality'){
        this.loadThirdBatchNo(this.data.third_vaccination_center_id)
      }
    },
    filterFourthVaccinationCenter(){
      this.fourth_vaccinations.filter(({id,vaccination_center})=>( id == this.data.fourth_vaccination_center_id ? this.data.fourth_vaccinated_address = vaccination_center : ''))
      if(this.$user.role == 'municipality'){
        this.loadFourthBatchNo(this.data.fourth_vaccination_center_id)
      }
    },

    //dose selector
    firstVaccinationToggle(e){
      if(e.target.checked){
        this.data.vaccination_center_id = ''
        this.data.first_vaccinated_address = ''
        this.data.second_vaccination_center_id = ''
        this.data.date_of_second_vaccinated = ''
        this.data.second_vaccinated_address = ''
        this.data.second_vaccine_name = ''
        this.data.second_vaccine_period = ''
      }
      else{
        if(this.$gate.isMunicipality()){
          this.fetchVaccinationCentersForCurrentAdd();
        }else{
          axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
           .then((response) => {
              this.data.vaccination_center_id = this.$user.vaccination_center_id
             this.data.date_of_first_vaccinated = this.getNepaliDateToday()
             this.data.first_vaccine_name = response.data.vaccine_name;
             this.data.first_vaccinated_address = response.data.vaccination_center
             this.batch_numbers = response.data.batch_no
             if(response.data.batch_no !== undefined){

               if(response.data.batch_no.length < 2){
                 this.data.first_vaccine_batch_no=response.data.batch_no[0].batch_no
               }
             }
           });
        }
      }
    },
    //dose selector
    secondVaccinationToggle(e){
      if(e.target.checked){
        this.data.second_vaccinated_address = ''
      }

    },
    thirdVaccinationToggle(e){
      if(e.target.checked){
        this.data.third_vaccinated_address = ''
      }

    },
    firstDoseDetail(e){
     if(e.target.checked){
      if(this.$user.role != 'municipality'){
        axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
                   .then((response) => {
                     if(this.data.is_from_foreign == 1){
                       this.data.vaccination_center_id = 0
                       this.data.vaccinated_address =  ''
                     }else{
                       this.data.first_vaccinated_address = response.data.vaccination_center
                       this.data.vaccination_center_id = this.$user.vaccination_center_id
                     }
                     this.data.date_of_first_vaccinated = this.getNepaliDateToday()
                     this.data.first_vaccine_name = response.data.vaccine_name;
                     this.fillVaccineType();
                     this.filterNoOfDose();
                     this.batch_numbers = response.data.batch_no
                     if(response.data.batch_no !== undefined){
                       if(response.data.batch_no.length < 2){
                         this.data.first_vaccine_batch_no=response.data.batch_no[0].batch_no
                       }
                     }
                   });
           }
      }
    else{
      this.$fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, clear it!'
            }).then((result) => {
                if (result.value) {
                  this.data.second_vaccine_period= ''
                  this.data.third_vaccine_period= ''
                  this.data.date_of_first_vaccinated = ''
                  this.data.first_vaccine_name = ''
                  this.data.third_vaccine_name = ''
                  this.data.first_vaccinated_address = ''
                  this.previously_vaccinated = false
                  this.data.aefi_date = ''
                  this.data.aefi_type = ''
                  this.data.aefi_remarks = ''
                  this.$fire({
                      text: this.$t('cleared_msg'),
                      type: "success",
                      timer: 1000,
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                    })
                }else{
                  this.data.first_vaccine_period = true
                }
            })
        }
    },
    secondDoseDetail(e){
      if(e.target.checked ){
        if(this.$user.role != 'municipality'){
          axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
            .then((response) => {

              if(this.data.second_is_from_foreign == 1){
                this.data.second_vaccination_center_id = 0
                this.data.second_vaccinated_address =  ''
              }else{
                this.data.second_vaccinated_address = response.data.vaccination_center
                this.data.second_vaccination_center_id = this.$user.vaccination_center_id
              }
              this.data.date_of_second_vaccinated = this.getNepaliDateToday()
              this.data.second_vaccine_name = this.data.first_vaccine_name
              this.second_batch_numbers = response.data.batch_no
              this.fillSecondVaccineType();
              if(response.data.batch_no.length < 2){
                this.data.second_vaccine_batch_no=response.data.batch_no[0].batch_no
              }
            });
        }
        if (this.date_diff < 21) {
                this.data.second_vaccination_center_id = ''
                this.data.date_of_second_vaccinated = ''
                this.data.second_vaccinated_address = ''
                this.data.second_vaccine_name = ''
            }
      }else{
        this.data.second_vaccination_center_id = ''
        this.data.date_of_second_vaccinated = ''
        this.data.second_vaccinated_address = ''
        this.data.second_vaccine_name = ''
        this.data.third_vaccination_center_id = ''
        this.data.date_of_third_vaccinated = ''
        this.data.third_vaccinated_address = ''
        this.data.third_vaccine_name = ''
      }
    },
    thirdDoseDetail(e, data){
        this.$v.$touch();

      if(e.target.checked ){
        if(this.$user.role != 'municipality'){
          axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
            .then((response) => {
              data.third_vaccination_center_id = this.$user.vaccination_center_id
              data.date_of_third_vaccinated = this.getNepaliDateToday()
              data.third_vaccine_name = this.data.first_vaccine_name
              data.third_vaccinated_address = response.data.vaccination_center
              this.third_batch_numbers = response.data.batch_no
              this.fillThirdVaccineType();
              if(response.data.batch_no.length < 2){
                data.third_vaccine_batch_no = response.data.batch_no[0].batch_no
              }

            });
        }
      }else{
        Vue.set(this.data, 'third_vaccinated_address', "");
        Vue.set(this.data, 'third_vaccination_center_id', "");
        Vue.set(this.data, 'third_vaccine_batch_no', "");
        Vue.set(this.data, 'third_vaccine_name', "");
        Vue.set(this.data, 'third_vaccine_period', false);
        Vue.set(this.data, 'third_vaccine_type', "");
      }
      // this.$forceUpdate();
    },

    fourthDoseDetail(e, data){
        this.$v.$touch();

      if(e.target.checked ){
        if(this.$user.role != 'municipality'){
          axios.get('/api/vaccination-center-info?vaccination_center_id=' + this.$user.vaccination_center_id)
            .then((response) => {
              data.fourth_vaccination_center_id = this.$user.vaccination_center_id
              data.date_of_fourth_vaccinated = this.getNepaliDateToday()
              data.fourth_vaccine_name = this.data.first_vaccine_name
              data.fourth_vaccinated_address = response.data.vaccination_center
              this.fourth_batch_numbers = response.data.batch_no
              this.fillFourthVaccineType();
              if(response.data.batch_no.length < 2){
                data.fourth_vaccine_batch_no = response.data.batch_no[0].batch_no
              }

            });
        }
      }else{
        Vue.set(this.data, 'third_vaccinated_address', "");
        Vue.set(this.data, 'third_vaccination_center_id', "");
        Vue.set(this.data, 'third_vaccine_batch_no', "");
        Vue.set(this.data, 'third_vaccine_name', "");
        Vue.set(this.data, 'third_vaccine_period', false);
        Vue.set(this.data, 'third_vaccine_type', "");
      }
      // this.$forceUpdate();
    },

    thirdDoseDateDiff(){
      if(this.data.vaccine_type == 6){
          this.date_diff_third = this.days_between(this.data.date_of_first_vaccinated)
      }else{
          this.date_diff_third = this.days_between(this.data.date_of_second_vaccinated)
      }
      if(this.date_diff_third < 90){
        this.data.third_vaccination_center_id = ''
        this.data.date_of_third_vaccinated = ''
        this.data.third_vaccinated_address = ''
        this.data.third_vaccine_name = ''
        this.data.third_vaccine_period = ''
      }

    },
    fourthDoseDateDiff(){
        this.date_diff_fourth = this.days_between(this.data.date_of_third_vaccinated)
        if(this.date_diff_fourth < 90){
            this.data.fourth_vaccination_center_id = ''
            this.data.date_of_fourth_vaccinated = ''
            this.data.fourth_vaccinated_address = ''
            this.data.fourth_vaccine_name = ''
            this.data.fourth_vaccine_period = ''
        }
    },

    toggleDob() {
        this.date_of_birth = ''
    },
    toggleCheckbox(data) {
      if (data.medical_condition.includes('21')) {
        data.medical_condition = []
        data.medical_condition = ['21']
      }
    },
    idSearch(){
      this.searchType = "registration"
      this.searchmode = true
    },
    toggleSearch(){
      this.searchmode = true
    },
    removeNoneCheckbox(data) {
      if (data.medical_condition.includes('21')) {
        (data.medical_condition).splice((data.medical_condition).indexOf("21"), 1).toFixed()
      }
    },

    fetchDistricts(data) {
      this.districts = [];
      var json = '';
      json = JSON.parse(JSON.stringify(districts));
      var provinceId = data.province_id;
      for (var i = 0; i < json.length; i++) {
        if (json[i].province_id == provinceId) {
          this.districts.push(json[i]);
        }
      }
      this.fetchVaccinationCentersForCurrentAdd(this.data)
      this.authProvience(this.data.province_id);
      this.searchProvience(this.search.province_id);

      return this.districts;
    },

    fetchMunicipalities(data) {
      this.municipalities = [];
      var json = '';
      json = JSON.parse(JSON.stringify(municipalities));
      var districtId = data.district_id;
      for (var i = 0; i < json.length; i++) {
        if (json[i].district_id == districtId) {
          this.municipalities.push(json[i]);
        }
      }
      this.authDistrict(this.data.district_id)
      this.searchDistrict(this.search.district_id);

      return this.municipalities;
    },
    fetchVacDistricts(data) {
      this.vac_districts = [];
      var json = '';
      json = JSON.parse(JSON.stringify(districts));
      var provinceId = data.vac_province_id;
      for (var i = 0; i < json.length; i++) {
        if (json[i].province_id == provinceId) {
          this.vac_districts.push(json[i]);
        }
      }
      data.vac_district_id = ''
      return this.vac_districts;
    },
    fetchVacMunicipalities(data) {
      this.vac_municipalities = [];
      var json = '';
      json = JSON.parse(JSON.stringify(municipalities));
      var districtId = data.vac_district_id;
      for (var i = 0; i < json.length; i++) {
        if (json[i].district_id == districtId) {
          this.vac_municipalities.push(json[i]);
        }
      }
      data.vac_municipality_id = ''
      return this.vac_municipalities;
    },
    fetchVaccinationCenters(data) {
      this.vaccinations = [];
      this.data.vaccination_center_id = ''
      axios.get('/api/vaccination-center?municipality_id=' + data.vac_municipality_id)
          .then((response) => {
            this.vaccinations = response.data
            return this.vaccinations;
          });
    },
    fetchSecondVaccinationCenters(data) {
      this.second_vaccinations = [];
      this.data.second_vaccination_center_id = ''
      axios.get('/api/vaccination-center?municipality_id=' + data.vac_municipality_id)
          .then((response) => {
            this.second_vaccinations = response.data
            return this.second_vaccinations;
          });
    },
    fetchThirdVaccinationCenters(data) {

      this.third_vaccinations = [];
      this.data.third_vaccination_center_id = ''
      axios.get('/api/vaccination-center?municipality_id=' + data.vac_municipality_id)
          .then((response) => {
            this.third_vaccinations = response.data
            return this.third_vaccinations;
          });
    },

    //vaccination center fetch for municipality

    fetchVaccinationCentersForCurrentAdd(data) {
      this.vaccinations = [];
      if (this.vaccine_current_address === '1') {
        axios.get('/api/vaccination-center?municipality_id=' + data.municipality_id)
            .then((response) => {
              this.vaccinations = response.data
              return this.vaccinations;
            });
      }
      this.searchMunicipality(this.search.municipality_id);
      this.authMunicipality(this.data.municipality_id);
    },
    fetchSecondVaccinationCentersForCurrentAdd() {
      this.second_vaccinations = [];
        axios.get('/api/vaccination-center?municipality_id=' + this.$user.municipality_id)
            .then((response) => {
              this.second_vaccinations = response.data
            });
    },
    fetchThirdVaccinationCentersForCurrentAdd() {
      this.third_vaccinations = [];
        axios.get('/api/vaccination-center?municipality_id=' + this.$user.municipality_id)
            .then((response) => {
              this.third_vaccinations = response.data
            });
    },
    checkVaccinationAddress() {
      if (this.vaccine_current_address === '1') {
        this.fetchVaccinationCentersForCurrentAdd(this.data)
        this.data.vac_province_id = '';
        this.data.vac_district_id = '';
        this.data.vac_municipality_id = '';
      }
      else {
        this.vaccinations = []
      }
    },
    getNepaliDateToday(){
      var today =  new Date().toISOString().substr(0, 10);
      var splited_date = today.split("-");
      var ad2bs = DataConverter.ad2bs(splited_date[0] + "/" + splited_date[1] + "/" + splited_date[2]);
      var npdate = ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day;
      var np_d = npdate.split("-");
      var first_month = np_d[1].length === 1 ? '0'+np_d[1] : np_d[1];
      var first_day = np_d[2].length === 1 ? '0'+np_d[2] : np_d[2];
      return np_d[0]+ "-" + first_month + "-" + first_day;
    },

    getNepaliDateAefi(){
      this.data.aefi_date = this.getNepaliDateToday()
    },
    getTodayNpDate() {
      var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
      var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
      var ad2bs = DataConverter.ad2bs(todayDate);

      return ad2bs.en.year + "-" + ad2bs.en.month + "-" + ad2bs.en.day
    },
    getTodayEnDate() {
      var today = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

      return today.getFullYear() + '-' + (today.getMonth() + 1) + "-" + today.getDate()
    },
    authProvience(p_id){
      provinces.filter(({id,province_name})=>( id == p_id ? this.data.province_name= province_name : ''))
    },
    authDistrict(d_id){
      districts.filter(({id,district_name})=>( id == d_id ? this.data.district_name= district_name : ''))
    },
    authMunicipality(m_id){
      municipalities.filter(({id,municipality_name})=>( id == m_id ? this.data.municipality_name= municipality_name : ''))
    },
    searchProvience(p_id){
      provinces.filter(({id,province_name})=>( id == p_id ? this.search.province_name= province_name : ''))
    },
    searchDistrict(d_id){
      districts.filter(({id,district_name})=>( id == d_id ? this.search.district_name= district_name : ''))
    },
    searchMunicipality(m_id){
      municipalities.filter(({id,municipality_name})=>( id == m_id ? this.search.municipality_name= municipality_name : ''))
    },
    days_between(date) {
        const oneDay = 24 * 60 * 60 * 1000;

            let fv_date = date.split('-');
            let now = this.getNepaliDateToday();
            var today = now.split('-');
            const firstDate = new Date(fv_date[0], fv_date[1], fv_date[2]);
            const secondDate = new Date(today[0], today[1], today[2]);

            return Math.round(Math.abs((firstDate - secondDate) / oneDay));;
    },

    //filling data into forms
    searchUser(event) {
      event.preventDefault();
              Fire.$emit('public-form');

              this.$v.$touch()
              if (this.$v.$invalid) {
                this.disabled = false
                this.$fire({
                  text: this.$t('error_msg'),
                  type: "error",
                  timer: 4000,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                })
                return false;
              }
              if(this.searchType == 'registration'){
                var normal = '/api/registered-user-search?registration_no=' + this.search.reg_no +'&search_type='+ this.searchType;
              }
              if(this.searchType == 'normal'){
                var normal = '/api/registered-user-search?registration_no=' + this.search.reg_no + '&municiplaity='+ this.search.municipality_id + '&district='+ this.search.district_id + '&province='+ this.search.province_id + '&search_type='+ this.searchType;
              }
              if(this.searchType == 'identity'){
                var normal = '/api/registered-user-search?municiplaity='+ this.search.municipality_id + '&district='+ this.search.district_id + '&province='+ this.search.province_id+  '&identity_type=' + this.search.identity_type + '&identity_no='+ this.search.identity_no + '&issue_org='+ this.search.issue_org + '&search_type='+ this.searchType;
              }
              if(this.searchType == 'advanced'){
                var normal = '/api/registered-user-search?municiplaity='+ this.search.municipality_id + '&district='+ this.search.district_id + '&province='+ this.search.province_id  + '&name='+ this.search.name  + '&gender='+ this.search.gender  + '&ward='+ this.search.ward  + '&phone='+ this.search.phone + '&age_range='+ this.search.age_range +  '&search_type='+ this.searchType;
              }
            axios.get(normal).then(({data})=>{

               if(data.users.length > 0){
                 this.$fire({
                    text: this.$t('data_found_successfully'),
                    type: "success",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                if(this.searchType == 'registration'){
                    this.searchmode = false;
                    this.previously_vaccinated = false,
                    this.editmode = true;
                    this.searchType = ""
                    this.data.id = data.users[0].id
                    this.data.vaccinated_id = data.users[0].vaccinated_id
                    this.data.dob_type = data.users[0].dob_type
                    this.date_of_birth = data.users[0].date_of_birth
                    this.data.gender = data.users[0].gender
                    this.data.nationality = data.users[0].nationality
                    this.data.occupation = data.users[0].occupation
                    this.data.province_id = data.users[0].province_id
                    this.data.district_id = data.users[0].district_id
                    this.data.municipality_id = data.users[0].municipality_id
                    this.data.ward = data.users[0].ward
                    this.data.tole = data.users[0].tole
                    this.data.phone = data.users[0].phone
                    this.data.email_address = data.users[0].email_address
                    this.data.first_name = data.users[0].first_name
                    this.data.middle_name = data.users[0].middle_name
                    this.data.last_name = data.users[0].last_name
                    this.data.identity_no = data.users[0].identity_no
                    this.data.identity_type = data.users[0].identity_type
                    this.data.issue_org = data.users[0].issue_org
                    this.data.medical_condition = data.users[0].medical_condition
                    this.aefis = data.users[0].aefi_record
                    this.vaccination_records = data.users[0].vaccination_record
                    data.users[0].vaccination_record.forEach(({is_from_foreign,vaccine_period,vaccine_name,vaccinated_date_np,batch_no,vaccine_id,vaccination_center_id,vaccination_center,vaccinated_address})=>{
                      var period_vaccine = vaccine_period.trim();
                      var name_vaccine =  vaccine_name.trim();
                      var is_from_foreign;
                       if(is_from_foreign == null || is_from_foreign == 0){
                        is_from_foreign = 0;
                       }else{
                        is_from_foreign = 1;
                       }
                        if(period_vaccine == '1M'){

                        this.data.first_vaccine_period = vaccine_period ? true : false
                        this.data.first_vaccine_name =  name_vaccine
                        this.data.vaccine_type =  vaccine_id
                        this.data.first_vaccine_batch_no = batch_no
                        this.data.is_from_foreign = vaccination_center_id ? is_from_foreign : 1
                        this.data.date_of_first_vaccinated =  vaccinated_date_np
                        this.data.vaccination_center_id = vaccination_center_id
                        this.data.first_vaccinated_address =  vaccination_center ? vaccination_center.vaccination_center  : vaccinated_address
                        this.date_diff = this.days_between(vaccinated_date_np)
                        this.vaccines.filter(({name,required_no_of_dose})=>{
                          name == vaccine_name ? this.required_no_of_dose = required_no_of_dose : ''
                        })
                        this.vaccinationCompleted = this.required_no_of_dose == '1' ? true : false

                      }
                      else if(period_vaccine == '3M'){
                        this.data.second_vaccinated_address =  vaccination_center ? vaccination_center.vaccination_center  : vaccinated_address
                        this.data.date_of_second_vaccinated =  vaccinated_date_np
                        this.data.second_vaccine_type =  vaccine_id
                        this.data.second_vaccine_name =  name_vaccine
                        this.data.second_vaccine_period = vaccine_period ? true : false
                        this.data.second_vaccine_batch_no = batch_no
                        this.data.second_is_from_foreign = vaccination_center_id ? is_from_foreign : 1
                        this.data.second_vaccination_center_id = vaccination_center_id
                        this.date_diff_third = this.days_between(vaccinated_date_np)
                      }
                      else if(period_vaccine == '9M'){
                        this.data.third_vaccinated_address =  vaccination_center.vaccination_center
                        this.data.date_of_third_vaccinated =  vaccinated_date_np
                        this.data.third_vaccine_type =  vaccine_id
                        this.data.third_vaccine_name =  name_vaccine
                        this.data.third_vaccine_period = vaccine_period ? true : false
                        this.data.third_vaccine_batch_no = batch_no
                        this.data.third_vaccination_center_id = vaccination_center_id
                      }
                      else if(period_vaccine == '12M'){
                        this.vaccinationCompleted = vaccinated_date_np ? true : false
                        this.data.fourth_vaccinated_address =  vaccination_center.vaccination_center
                        this.data.date_of_fourth_vaccinated =  vaccinated_date_np
                        this.data.fourth_vaccine_type =  vaccine_id
                        this.data.fourth_vaccine_name =  name_vaccine
                        this.data.fourth_vaccine_period = vaccine_period ? true : false
                        this.data.fourth_vaccine_batch_no = batch_no
                        this.data.fourth_vaccination_center_id = vaccination_center_id
                      }

                    })
                    if(data.users[0].vaccination_record.length == 0){
                      this.data.first_vaccine_period =  false
                      this.data.first_vaccine_name =  ''
                      this.data.date_of_first_vaccinated =  ''
                      this.data.second_vaccine_name =  ''
                      this.data.date_of_second_vaccinated =  ''
                      this.data.second_vaccine_period =  false
                      this.data.third_vaccine_name =  ''
                      this.data.third_of_second_vaccinated =  ''
                      this.data.third_vaccine_period =  false
                    }else if(data.users[0].vaccination_record.length == 1){
                      this.data.second_vaccine_name =  ''
                      this.data.date_of_second_vaccinated =  ''
                      this.data.second_vaccine_period =  false

                    }


                    this.data.province_id = data.users[0].province_id.toString()
                    this.data.district_id = data.users[0].district_id.toString()
                    this.data.municipality_id = data.users[0].municipality_id.toString()

                    this.data.created_by = this.$user.token
                    this.data.vaccine_type = data.users[0].vaccine_type
                    if(!data.users[0].dob_type){
                      this.data.dob_type = 1
                    }
                    this.fetchDistricts(this.data);
                    this.fetchMunicipalities(this.data);

                    this.data.medical_condition = data.users[0].medical_condition
                    if(data.users[0].disability_type){
                      this.data.disability_type = data.users[0].disability_type
                      this.is_disabled = 1
                    }else{
                        this.is_disabled = 0
                    }
                    this.data.category = '16'
                    // this.fetchDistricts(this.data);
                    // this.fetchMunicipalities(this.data);
                    //search clear after update
                    this.search.reg_no = null,
                    //cleared previously searched user
                    this.users = null;

                  }else{
                    this.users = data.users
                  }
               }else{
                 this.$fire({
                    text: this.$t('date_not_found'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                this.users = null;
               }
              })
              .catch((res)=>{
                this.$fire({
                    text: this.$t('error_msg'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                return false;
              })
    },
    editUser(user){
      Fire.$emit('public-form');
      this.previously_vaccinated = false,
        this.searchmode = false;
        this.editmode = true;
        this.searchType = ""
        this.data.id = user.id
        this.data.vaccinated_id = user.vaccinated_id
        this.data.dob_type = user.dob_type
        this.date_of_birth = user.date_of_birth
        this.data.gender = user.gender
        this.data.nationality = user.nationality
        this.data.occupation = user.occupation
        this.data.province_id = user.province_id
        this.data.district_id = user.district_id
        this.data.municipality_id = user.municipality_id
        this.data.ward =user.ward
        this.data.tole = user.tole
        this.data.phone = user.phone
        this.data.email_address = user.email_address
        this.data.first_name = user.first_name
        this.data.middle_name = user.middle_name
        this.data.last_name = user.last_name
        this.data.identity_no = user.identity_no
        this.data.identity_type = user.identity_type
        this.data.issue_org = user.issue_org
        this.data.medical_condition = user.medical_condition
        this.aefis = user.aefi_record
        this.vaccination_records = user.vaccination_record
        user.vaccination_record.forEach(({is_from_foreign,vaccine_period,vaccine_name,vaccinated_date_np,batch_no,vaccine_id,vaccination_center_id,vaccination_center})=>{
          var period_vaccine = vaccine_period.trim();
          var name_vaccine =  vaccine_name.trim();

          if(period_vaccine == '1M'){
              this.vaccines.filter(({name,required_no_of_dose})=>{
                name == vaccine_name ? this.required_no_of_dose = required_no_of_dose : ''
              })
              this.prevrecord = vaccinated_date_np ? true :  false
              this.data.first_vaccinated_address =  vaccination_center ? vaccination_center.vaccination_center  : vaccinated_address
              this.data.date_of_first_vaccinated =  vaccinated_date_np
              this.data.first_vaccine_name =  name_vaccine
              this.data.vaccine_type =  vaccine_id
              this.data.is_from_foreign = vaccination_center_id ? is_from_foreign : 1
              this.data.first_vaccine_period = vaccination_center_id ? true : false
              this.data.first_vaccine_batch_no = batch_no
              this.data.vaccination_center_id = vaccination_center_id
              this.date_diff = this.days_between(vaccinated_date_np)
            }
            else if(period_vaccine == '3M'){
                        this.data.second_vaccinated_address =  vaccination_center ? vaccination_center.vaccination_center  : vaccinated_address
              this.data.date_of_second_vaccinated =  vaccinated_date_np
              this.data.second_vaccine_name =  name_vaccine
              this.data.second_is_from_foreign = vaccination_center_id ? is_from_foreign : 1
              this.data.second_vaccine_type =  vaccine_id
              this.data.second_vaccine_period = vaccination_center_id ? true : false
              this.data.second_vaccine_batch_no = batch_no
              this.data.second_vaccination_center_id = vaccination_center_id
              this.date_diff_third = this.days_between(vaccinated_date_np)
            }
            else if(period_vaccine == '9M'){
                this.data.third_vaccinated_address =  vaccination_center.vaccination_center
                this.data.date_of_third_vaccinated =  vaccinated_date_np
                this.data.third_vaccine_type =  vaccine_id
                this.data.third_vaccine_name =  name_vaccine
                this.data.third_vaccine_period = vaccination_center_id ? true : false
                this.data.third_vaccine_batch_no = batch_no
                this.data.third_vaccination_center_id = vaccination_center_id
            }
            else if(period_vaccine == '12M'){
                this.vaccinationCompleted = vaccinated_date_np ? true : false
                this.data.fourth_vaccinated_address =  vaccination_center.vaccination_center
                this.data.date_of_fourth_vaccinated =  vaccinated_date_np
                this.data.fourth_vaccine_type =  vaccine_id
                this.data.fourth_vaccine_name =  name_vaccine
                this.data.fourth_vaccine_period = vaccination_center_id ? true : false
                this.data.fourth_vaccine_batch_no = batch_no
                this.data.fourth_vaccination_center_id = vaccination_center_id
            }
        });
        if(user.vaccination_record.length == 0){
          this.data.first_vaccine_name =  ''
          this.data.date_of_first_vaccinated =  ''
          this.data.second_vaccine_name =  ''
          this.data.date_of_second_vaccinated =  ''
        }
        this.data.dob_type = user.dob_type
        if(!this.data.dob_type){
          this.data.dob_type = '1'
        }
        this.data.created_by = this.$user.token
        this.data.province_id = user.province_id.toString()
        this.data.district_id = user.district_id.toString()
        this.data.municipality_id = user.municipality_id.toString()
        this.data.dob_type = '1'
        this.data.medical_condition = user.medical_condition,
        this.data.vaccine_type = user.vaccine_type
        if(user.disability_type){
          this.data.disability_type = user.disability_type
          this.is_disabled = 1
        }else{
            this.is_disabled = 0
        }
        this.date_of_birth = user.date_of_birth
        this.data.category = '16'
        this.fetchDistricts(this.data);
        this.fetchMunicipalities(this.data);
        this.data.is_from_foreign = 0;

        //search clear after update
        this.search.reg_no = null,

        //cleared previously searched user
        this.users = null;

    },

    //create edit  with validation
    submitDetail() {
      this.confirmed = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.disabled = false
          this.$fire({
            text: this.$t('error_msg'),
            type: "error",
            timer: 4000,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
          })
          return false;
        }
        $('#addNewContent').modal('show');
        Event.$emit('creating', this.editmode);
    },
    updateDetail() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.disabled = false
        this.$fire({
          text: this.$t('error_msg'),
          type: "error",
          timer: 4000,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
        })
        return false;
      }
      $('#addNewContent').modal('show');
      Event.$emit('updating', this.editmode);
    },
    //nepali date picker
    renderDatePickerNp(){
      var self = this;
      var today = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
      var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
      var ad2bs = DataConverter.ad2bs(todayDate);
      var disableAfter = ad2bs.en.year + "-" + ('0' + ad2bs.en.month).slice(-2) + "-" + ad2bs.en.day
      if($('#date_of_birth').length){
        $('#date_of_birth').nepaliDatePicker({
        language: 'english',
        ndpYear: true,
        ndpMonth: true,
        disableAfter: disableAfter,
        onChange: function() {
            self.date_of_birth = $('#date_of_birth').val()
        }
      });

      }
      if($('#aefi-date').length){
        $('#aefi-date').nepaliDatePicker({
        language: 'english',
        disableBefore: self.data.date_of_first_vaccinated,
        disableAfter: this.getNepaliDateToday(),
        onChange: function() {
            self.data.aefi_date = $('#aefi-date').val()
        }
      });

      }
      if($('#first-vaccinated').length){
      $('#first-vaccinated').nepaliDatePicker({
        language: 'english',
        disableAfter: this.getNepaliDateToday(),
        onChange: function() {
           self.data.date_of_first_vaccinated = $('#first-vaccinated').val();
          self.date_diff = self.days_between(self.data.date_of_first_vaccinated)

        }
      });
      }
      if($('#date-of-second-vaccinated').length){
        $('#date-of-second-vaccinated').nepaliDatePicker({
        language: 'english',
        disableBefore: self.data.date_of_first_vaccinated,
        disableAfter: this.getNepaliDateToday(),
        onChange: function() {
         self.data.date_of_second_vaccinated = $('#date-of-second-vaccinated').val()
        }
      });
      }
      if($('#date-of-third-vaccinated').length){
        $('#date-of-third-vaccinated').nepaliDatePicker({
        language: 'english',
        disableBefore: self.data.date_of_second_vaccinated,
        disableAfter: this.getNepaliDateToday(),
        onChange: function() {
         self.data.date_of_third_vaccinated = $('#date-of-third-vaccinated').val()
        }
      });
      }
    },

    //batch number loading for different dose
    loadBatchNo(center_id){
      var center = center_id ? center_id : this.$user.vaccination_center_id
      axios.get('/api/vaccination-center-info?vaccination_center_id=' + center)
      .then((response) => {
        this.batch_numbers = response.data.batch_no
        if(this.batch_numbers && this.$user.role =='municipality'){
          if(this.batch_numbers.length < 2 && this.data.first_vaccine_period){
            this.data.first_vaccine_batch_no = this.batch_numbers[0].batch_no
          }
        }
      });
    },
    loadSecondBatchNo(center_id){
      var center = center_id ? center_id : this.$user.vaccination_center_id
      axios.get('/api/vaccination-center-info?vaccination_center_id=' + center)
      .then((response) => {
        this.second_batch_numbers = response.data.batch_no
        if(this.second_batch_numbers && this.$user.role =='municipality'){
          if(this.second_batch_numbers.length < 2 && this.data.second_vaccine_period){
              this.data.second_vaccine_batch_no = this.second_batch_numbers[0].batch_no
          }
        }
      });
    },
    loadThirdBatchNo(center_id){
      var center = center_id ? center_id : this.$user.vaccination_center_id
      axios.get('/api/vaccination-center-info?vaccination_center_id=' + center)
      .then((response) => {
        this.third_batch_numbers = response.data.batch_no
        if(this.third_batch_numbers && this.$user.role =='municipality'){
          if(this.third_batch_numbers.length < 2 && this.data.third_vaccine_period){
              this.data.third_vaccine_batch_no = this.third_batch_numbers[0].batch_no
          }
        }
      });
    },
    nepaliDateConvertor(date){
        var first_date_np = date.split("-");
        var first_ad2bs = DataConverter.bs2ad(first_date_np[0] + "/" + first_date_np[1] + "/" + first_date_np[2]);
        var date_of_vaccinated_np = first_ad2bs.year + "-" + first_ad2bs.month + "-" + first_ad2bs.day;
        var first_np_d = date_of_vaccinated_np.split("-");
        var first_month = first_np_d[1].length === 1 ? '0'+first_np_d[1] : first_np_d[1];
        var first_day = first_np_d[2].length === 1 ? '0'+first_np_d[2] : first_np_d[2];
        return first_np_d[0]+ "-" + first_month + "-" + first_day
    },

    loadVaccine(){
      axios.get('/api/vaccines').then(({data})=>{
        this.vaccines = data.vaccines
      })
    }

  },
  computed: {

    second_dose_enabler: function(){
    let chkVacAddress = !!this.data.vaccination_center_id;
    let chkVacName = !!this.data.first_vaccine_name;
    let chkVacDate = !!this.data.date_of_first_vaccinated;
    if( (chkVacAddress || this.data.vaccination_center_id == 0)  && chkVacName && chkVacDate && this.date_diff >= 21 && this.data.vaccine_type != 6){
      return true
    }
    else{
        return false
    }
    },
    third_dose_enabler: function(){
    if(this.data.first_vaccine_period  && this.date_diff_third > 90){
      return true
    }
    else{
        return false
    }
    },
    fourth_dose_enabler: function(){
    if(this.data.first_vaccine_period  && this.date_diff_fourth > 90){
      return true
    }
    else{
        return false
    }
    },
    showHide() {
          return this.searchmode ? "d-block" : "d-none";
      },
    isCurrentAddress: function () {
      if (this.vaccine_current_address === '1') {
        // this.vaccinations = []
        // this.data.vaccination_center = ''
        return false;
      }
      return true;
    },
    isSecondVaccine: function () {
      if (this.data.vaccine_type === '1') {
        return false;
      }
      return true;
    },

    age() {
      // today english date
            var today = new Date();
            var todayDate = today.getFullYear() + '/' + (today.getMonth() + 1) + "/" + today.getDate();
            var date = this.date_of_birth;
            if (this.data.dob_type == '1' && date) {
                // today english date to nepali
                var ad2bs = DataConverter.ad2bs(todayDate);

                var dob = date.split('-');

                // calculate age
                age = ad2bs.en.year - dob[0];
                var m = ad2bs.en.month - dob[1];
                if (m < 0 || (m === 0 && ad2bs.en.day < dob[2])) {
                    age--;
                }
                return age;

            } else if (this.data.dob_type == '2') {
                var dob = this.date_of_birth;
                var today = new Date();
                var birthDate = new Date(dob);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
                {
                    age--;
                }
                return age;
            }
    }

  },
  created(){
    this.loadVaccine();
    this.loadBatchNo();
    this.fetchSecondVaccinationCentersForCurrentAdd();
    this.fetchThirdVaccinationCentersForCurrentAdd();

    Event.$on('update-data',(data)=>{
            this.disabled = true
           Event.$emit('disabled', this.disabled);
            // convert ad to bs if dob is ad
            data.date_of_birth = this.date_of_birth
            if(this.data.date_of_first_vaccinated){
                data.date_of_first_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_first_vaccinated);
            }
            else{
              data.date_of_first_vaccinated_en = null
            }
            if(this.data.date_of_second_vaccinated){
                data.date_of_second_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_second_vaccinated);
            }
            else{
              data.date_of_second_vaccinated_en = null
            }
            if(this.data.date_of_third_vaccinated){
                data.date_of_third_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_third_vaccinated);
            }
            else{
              data.date_of_third_vaccinated_en = null
            }
            if(this.data.date_of_fourth_vaccinated){
                data.date_of_fourth_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_fourth_vaccinated);
            }
            else{
              data.date_of_fourth_vaccinated_en = null
            }
            if(this.data.aefi_date){
                data.aefi_date_en = this.nepaliDateConvertor(this.data.aefi_date);
            }
            else{
              data.aefi_date_en = null
            }
                data.age = this.age
                axios.put('/client-create/' + data.id, data)
                .then((response) => {
                    if(response.data.message == 'Data Successfully Updated') {
                      console.log('here')
                      Fire.$emit('public-form');
                        this.$fire({
                          type: "success",
                          title:  response.data.id,
                          text: this.$t('success_msg_org'),
                        })
                      $('#addNewContent').modal('hide');
                      this.disabled = false
                      Event.$emit('disabled', this.disabled);
                      this.editmode = false
                      this.prevrecord = false
                      this.vaccinationCompleted = false
                      this.aefis = null,
                      this.users = null,
                      this.fetchDistricts(this.data);
                      this.fetchMunicipalities(this.data);
                    }
                    else {
                        this.$fire({
                            text: response.data.message.errorInfo,
                            type: "error",
                            timer: 4000,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                        }).then(r => {
                            console.log(r.value);
                        });
                      this.disabled = false
                      Event.$emit('disabled', this.disabled);
                    }
                })
      })

    Event.$on('create-data',(data)=>{
            this.disabled = true
            Event.$emit('disabled', this.disabled);
            // convert ad to bs if dob is ad
            data.date_of_birth = this.date_of_birth

            if(this.data.date_of_first_vaccinated){
                data.date_of_first_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_first_vaccinated);
            }
            else{
              data.date_of_first_vaccinated_en = null
            }
            if(this.data.date_of_second_vaccinated){
                data.date_of_second_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_second_vaccinated);
            }
            else{
              data.date_of_second_vaccinated_en = null
            }
            if(this.data.date_of_third_vaccinated){
                data.date_of_third_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_third_vaccinated);
            }
            else{
              data.date_of_third_vaccinated_en = null
            }
            if(this.data.date_of_fourth_vaccinated){
                data.date_of_fourth_vaccinated_en = this.nepaliDateConvertor(this.data.date_of_fourth_vaccinated);
            }
            else{
              data.date_of_fourth_vaccinated_en = null
            }

            if(this.data.aefi_date){
                data.aefi_date_en = this.nepaliDateConvertor(this.data.aefi_date);
            }
            else{
              data.aefi_date_en = null
            }
            data.age = this.age
            axios.post('/client-create', data)
                .then((response) => {

                    if (response.data.message === 'Data Successfully Sync') {
                        Fire.$emit('public-form');
                        this.$fire({
                          type: "success",
                          title: response.data.id,
                          text: this.$t('success_msg_org'),
                        })
                        $('#addNewContent').modal('hide');
                        this.previously_vaccinated = false
                        this.disabled = false
                          Event.$emit('disabled', this.disabled);

                    } else {
                        this.$dlg.closeAll();
                        this.$fire({
                            text: response.data.message.errorInfo,
                            type: "error",
                            timer: 4000,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,

                        })
                        .then(r => {
                            console.log(r.value);
                            this.disabled = false
                            Event.$emit('disabled', this.disabled);
                        });
                        this.disabled = false
                        Event.$emit('disabled', this.disabled);

                    }
                }).catch((res)=>{
                  this.disabled = false
                  Event.$emit('disabled', this.disabled);
                })
     })
    if(!this.editmode){
      this.fetchDistricts(this.data);
      this.fetchMunicipalities(this.data);
    }
    Fire.$on('public-form', () => {
        this.data.caste = '5'
        this.data.aefi_record = {}
        this.data.vaccination_record = {}
        this.aefis = {}
        this.vaccination_records = {}
        this.data.gender = ''
        this.data.nationality = 'Nepal'
        this.data.occupation = 0
        this.data.province_id = this.$user.province_id.toString()
        this.data.district_id = this.$user.district_id.toString()
        this.data.municipality_id = this.$user.municipality_id.toString()
        this.data.ward = ''
        this.data.tole = ''
        this.data.phone = ''
        this.data.email_address = ''
        this.data.organization_name = ''
        this.data.first_name = ''
        this.data.middle_name = ''
        this.data.last_name = ''
        this.data.identity_no = ''
        this.data.category = ''
        this.data.identity_type = ''
        this.data.issue_org = ''
        this.data.medical_condition = [21]
        this.data.vac_province_id = ''
        this.data.vac_district_id = ''
        this.data.vac_municipality_id = ''
        this.data.checked_by = this.$user.token,
        this.data.vaccination_center = this.$user.vaccination_center_id,

        this.data.register_for = ''
        this.data.vaccine_type = ''
        this.data.disability_type = null
        this.is_disabled = null
        // this.occupation_list = []
        // this.occupation_list = JSON.parse(JSON.stringify(occupations.essentials))
        this.provinces = JSON.parse(JSON.stringify(provinces))
        this.vac_provinces = JSON.parse(JSON.stringify(provinces))
        this.districts = []
        this.municipalities = []
        this.vaccinations = []
        this.vac_districts = []
        this.vac_municipalities = []
        // this.country = 'NP'
        this.date_of_birth = ''
        this.vaccine_current_address = '1'
        this.data.category = '16'
        this.data.first_vaccine_name ='',

        this.data.first_vaccine_period ='',
        this.data.date_of_first_vaccinated = '',
        this.data.first_vaccinated_address ='',
        this.data.first_vaccination_center_id ='',
        this.data.first_vaccine_batch_no = '',
        this.data.second_vaccine_batch_no = '',
        this.data.second_vaccine_name ='',
        this.data.second_vaccine_period ='',
        this.data.date_of_second_vaccinated = '',
        this.data.second_vaccinated_address ='',
        this.data.second_vaccination_center_id ='',

        this.data.third_vaccine_batch_no = '',
        this.data.third_vaccine_name ='',
        this.data.third_vaccine_period ='',
        this.data.date_of_third_vaccinated = '',
        this.data.third_vaccinated_address ='',
        this.data.third_vaccination_center_id ='',


        this.data.fourth_vaccine_batch_no = '',
        this.data.fourth_vaccine_name ='',
        this.data.fourth_vaccine_period ='',
        this.data.date_of_fourth_vaccinated = '',
        this.data.fourth_vaccinated_address ='',
        this.data.fourth_vaccination_center_id ='',


        this.data.aefi_type ='',
        this.data.aefi_remarks = '',
        this.data.aefi_date =  ''
        this.fetchDistricts(this.data);
        this.fetchMunicipalities(this.data);

      })
   },

   beforeUpdate(){
      this.renderDatePickerNp();
   },
   updated(){
      this.renderDatePickerNp();
      this.thirdDoseDateDiff();
      this.fourthDoseDateDiff();
   }
  ,



}
</script>
<style scoped>
.gray{
  color:gray;
  font-size:13px;
}
.btn:hover{
  box-shadow: unset;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.search-btn{
  padding: 7px 20px;

}
.date {
  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  box-shadow: 0 1px 5px -2px rgba(0,0,0,.2);

}
input::-webkit-calendar-picker-indicator{
	display: none;
}
input[type="date"]::-webkit-input-placeholder{
	visibility: hidden !important;
}
</style>
