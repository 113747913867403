<template>
    <div>
        <!-- <div class="row"  v-show="roleOrg || roleMuni">
            <div class="col-md-6" v-show="isShowAge60And61">
                <div class="panel panel-default">
                    <div class="panel-body">  <button type="button" @click="downloadButton('1')" class="btn btn-warning btn-block"><i class="fa fa-download" aria-hidden="true"></i>
                        Download all Data <br>
                        <strong>Age group 60,61</strong>
                    </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-body">  <button type="button" @click="downloadButton('2')" class="btn btn-warning btn-block"><i class="fa fa-download" aria-hidden="true"></i>
                        Download all Data <br>
                        <strong>Age group 62,63,64</strong>
                    </button>
                    </div>

                </div>
            </div>
        </div>
        <hr>
        <div class="text-center"> <strong>-OR-</strong></div>
        <hr> -->
        <div class="card">
<!--            <div class="card-header">{{ $t('choose_municipality_download') }}</div>-->
            <div class="card-body">
                <div class="container-flex3" style="margin-top: -30px;" >
                    <div class="form-group" :class="{ 'has-error': $v.data.from.$error }">
                        <input type="date" id="from" name="from" required="required" v-model="data.from">
                        <label for="from" class="control-label">From</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.from.required">{{ $t('field_required') }}
                        </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.data.to.$error }">
                        <input type="date" id="to" name="to" required="required" v-model="data.to">
                        <label for="to" class="control-label">To</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.to.required">{{ $t('field_required') }}
                        </div>
                    </div>
                </div>
                <div class="container-flex3">
                    <div class="form-group" :class="{ 'has-error': $v.data.province_id.$error }" v-show="roleMain || roleProv">
                        <select name="province_id" id="province_id"
                                v-on:change="fetchDistricts(data)" v-model="data.province_id">
                            <option value="">{{ $t('select_province') }}</option>
                            <option v-for="data in provinces" :value="data.id">{{ data.province_name }}</option>
                        </select>
                        <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.province_id.required">{{ $t('field_required') }}
                        </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.data.district_id.$error }" v-show="roleMain || roleProv">
                        <select name="district_id" id="district_id"
                                v-on:change="fetchMunicipalities(data)" v-model="data.district_id">
                            <option value="">{{ $t('select_district') }}</option>
                            <option v-for="data in districts" :value="data.id">{{ data.district_name }}</option>
                        </select>
                        <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.district_id.required">{{ $t('field_required') }}
                        </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.data.municipality_id.$error }" v-show="roleMain || roleProv">
                        <select name="municipality_id" v-model="data.municipality_id" v-on:change="fetchVaccinationCenters()"
                                id="municipality_id">
                            <option value="">{{ $t('select_municipality') }}</option>
                            <option v-for="data in municipalities" :value="data.id">{{ data.municipality_name }}
                            </option>
                        </select>
                        <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                        class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.municipality_id.required">{{ $t('field_required') }}
                        </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': $v.data.ward.$error }" v-show="roleMain || roleProv || roleMuni">
                        <select name="ward" v-model="data.ward"
                                id="ward">
                            <option value="">{{ $t('ward') }}</option>
                            <option v-for="data in 34" :value="data">{{ data }}
                            </option>
                        </select>
                        <label for="ward" class="control-label">{{ $t('ward') }}</label><i
                        class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.data.ward.required">{{ $t('field_required') }}
                        </div>
                    </div>
                </div>
                <div class="container-flex3" v-show="roleMain || roleProv || roleMuni">
                    <div class="form-group">
                        <select name="vaccination_center" id="vaccination_center" v-model="data.vaccination_center">
                            <option value="">{{  $t('select_options') }}</option>
                            <option v-for="data in vaccination_centers" :value="data.id">{{ data.vaccination_center }}
                            </option>
                        </select>
                        <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i class="bar"></i>
                    </div>
                </div>
                 <div class="container-flex3" v-show="roleMain || roleProv  || roleDist">
                    <div class="form-group">
                        <select name="municipality_name" id="municipality_name" v-model="data.municipality_id">
                            <option value="">{{  $t('select_options') }}</option>
                            <option v-for="data in municipalities" :value="data.id">{{ data.municipality_name }}
                            </option>
                        </select>
                        <label for="vaccination_center" class="control-label">{{ $t('municipality') }}</label><i class="bar"></i>
                    </div>
                </div>
                 <!--occupation-->
                <div class="container-flex1 mt-3">
                    <div class="form-group">
                    <select id="occupation" name="occupation" v-model="data.occupation">
                        <option value="">{{ $t('select_options') }}</option>
                        <option value="1">{{ $t('airlines') }}</option>
                        <option value="2">{{ $t('army') }}</option>
                        <option value="3">{{ $t('bank') }}</option>
                        <option value="4">{{ $t('commercial_facilities') }}</option>
                        <option value="5">{{ $t('daily_wage_worker') }}</option>
                        <option value="31">{{ $t('diplomat') }}</option>
                        <option value="6">{{ $t('farmer') }}</option>
                        <option value="7">{{ $t('government_employee') }}</option>
                        <option value="8">{{ $t('grocery') }}</option>
                        <option value="9">{{ $t('health_care') }}</option>
                        <option value="10">{{ $t('health_professional') }}</option>
                        <option value="11">{{ $t('it_and_communication') }}</option>
                        <option value="12">{{ $t('journalist_media') }}</option>
                        <option value="13">{{ $t('manufacturing') }}</option>
                        <option value="14">{{ $t('ngo_ingo') }}</option>
                        <option value="15">{{ $t('online_business') }}</option>
                        <option value="16">{{ $t('police') }}</option>
                        <option value="17">{{ $t('Security_person') }}</option>
                        <option value="18">{{ $t('social_service') }}</option>
                        <option value="19">{{ $t('student') }}</option>
                        <option value="20">{{ $t('teacher_professor') }}</option>
                        <option value="21">{{ $t('tourist') }}</option>
                        <option value="22">{{ $t('transport_sector') }}</option>
                        <option value="23">{{ $t('unemployed') }}</option>
                        <option value="32">{{ $t('veterinarians') }}</option>
                        <option value="0">{{ $t('other') }}</option>
                        <!--                    <option v-for="data in occupation_list" :value="data.id">{{ data.name }}</option>-->
                    </select>
                    <label for="occupation" class="control-label">{{ $t('occupation') }}</label><i class="bar"></i>
                    </div>
                </div>
                <div class="container-flex3">
                    <div class="form-group " v-show="!roleDist">
                        <input type="number" class="has-value" 
                                v-model="ageRange_min"  min="0"/>
                        <label for="Age" class="control-label date-label">{{ $t('age_min') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.ageRange_min.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.ageRange_min.between">{{ $t('age_range_validation') }}</div>


                    </div>
                    <div class="form-group " v-show="!roleDist">
                        <input type="number" class="has-value" 
                                v-model="ageRange_max"  min="0"/>
                        <label for="Age" class="control-label date-label">{{ $t('age_max') }}</label><i class="bar"></i>
                        <div class="help-block text-danger" v-if="!$v.ageRange_max.required">{{ $t('field_required') }}</div>
                        <div class="help-block text-danger" v-if="!$v.ageRange_max.between">{{ $t('age_range_validation') }}</div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="button-container">
                            <button type="button" class="button btn-block" id="button" @click="normalData"
                                    v-on:click="downloadData"><span>{{ $t('download_data') }}</span>
                            </button>
                        </div>
                    </div>
                   <div class="col-md-4">
                        <div class="button-container">
                            <button type="button" class="button btn-block" id="button" @click="planningData"
                                    v-on:click="downloadData"><span>{{ $t('planning_data') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="button-container">
                            <button type="button" class="button btn-block" id="button" @click="vaccinatedData"
                                    v-on:click="downloadData"><span>{{ $t('vaccinated_data') }}</span>
                            </button>
                        </div>
                    </div>

                </div>
                
                
            </div>
        </div>
    </div>
</template>
<script>
import districts from "../json/districts.json";
import municipalities from "../json/municipalities.json";
import provinces from "../json/provinces.json";
import {required,between,requiredIf} from "vuelidate/lib/validators";
import axios from "axios";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    components: {
        VueSlider
    },
    data() {
        return {
            data: {
                'province_id': '',
                'district_id': '',
                'municipality_id': '',
                'vaccination_center':'',
                'occupation':'',
                'ward': '',
                'from': new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10),
                'to': new Date().toISOString().substr(0, 10)
            },
            provinces: [],
            vac_provinces: JSON.parse(JSON.stringify(provinces)),
            districts: [],
            municipalities: [],
            role: '',
            roleMain: false,
            roleMuni: false,
            roleProv: false,
            roleDist: false,
            roleOrg: false,
            isShowAge60And61 : false,
            vaccination_centers: [],
            municipality_id: '',
            province_id: '',
            district_id: '',
            vaccination_id: '',
            ageRange_min: '',
            ageRange_max: '',
            download_type:'',

        }
    },
    created() {
        axios.get('/user').then((response) => {
            this.role = response.data.role
            this.municipality_id = response.data.municipality_id
            this.province_id = response.data.province_id
            this.district_id = response.data.district_id
            this.vaccination_id = response.data.vaccination_center_id
            var checkIsShowAge60And61DistrictID = ['25','26','27'];
            if (checkIsShowAge60And61DistrictID.includes(response.data.district_id)){
                this.isShowAge60And61 = true;
            }
            this.fetchMunicipalities();
            
            if (this.role === 'main') {
                this.roleMain = true
            } else if (this.role === 'healthpost') {
                this.roleOrg =  true
            } else if (this.role === 'municipality') {
                this.roleMuni = true
                this.fetchVaccinationCenters()
            } else if (this.role === 'dho') {
                this.roleDist = true
            } else if (this.role === 'province') {
                this.roleProv = true
            }
        })
    },
    mounted() {
        this.fetchProvinces()
    },
    validations() {
        if (this.role === 'main' || this.role === 'province') {
            return {
                data: {
                    province_id: {
                        required
                    },
                    district_id: {
                        required
                    },
                    municipality_id: {
                        required
                    },
                    ward:{
                        required: false
                    },
                    from: {
                        required
                    },
                    to: {
                        required
                    },
                }
            }
        } else {
            return {
                ageRange_min: {
                    between: between(5, 120),
                    required: requiredIf('ageRange_max')
                },
                ageRange_max: {
                    between: between(5, 120),
                    required: requiredIf('ageRange_min')
                },
                data: {
                    province_id: {
                        required: false
                    },
                    district_id: {
                        required: false
                    },
                    municipality_id: {
                        required: false
                    },
                    ward:{
                        required: false
                    },
                    from: {
                        required
                    },
                    to: {
                        required
                    },
                    
                }
            }
        }
    },
    methods: {
        downloadButton(type){
            var title = (type == 1) ? 'Do you want to download all the records of age 60 and 61 !' : 'Do you want to download the records of age 62,63 & 64 !';
            this.$fire({
                title: 'Are you sure?',
                text: title,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Download it!'
            }).then((result) => {
                if (result.value) {
                    var municipalityId = this.roleMain || this.roleProv || this.roleDist ? this.data.municipality_id : this.municipality_id;
                    var url = '/download-data?municipality_id=' + municipalityId +'&ageType='+type;
                    window.open(url);
                    this.$fire({
                        icon: 'success',
                        title: 'Download Completed !',
                        text: 'If download not started, please wait for a minute!'
                    })
                }
            })

        },
        fetchVaccinationCenters() {
            var municipalityId =  this.$user.role=== 'municipality' ? this.$user.municipality_id : "";
            var districtId =  this.$user.role=== 'dho'  ? this.$user.district_id : "";
            var url = '/api/vaccination-center?district_id=' + districtId +
            '&municipality_id=' + municipalityId;
            axios.get(url).then((response) => {
                this.vaccination_centers = response.data
                this.data.vaccination_center = ''
            })
        },
        fetchProvinces() {
            this.provinces = [];
            this.provinces = JSON.parse(JSON.stringify(provinces));
            return this.provinces;
        },
        fetchDistricts(data) {
            this.districts = [];
            var json = '';
            json = JSON.parse(JSON.stringify(districts));
            var provinceId = data.province_id;
            for (var i = 0; i < json.length; i++) {
                if (json[i].province_id === provinceId) {
                    this.districts.push(json[i]);
                }
            }
            data.district_id = ''
            return this.districts;
        },
        fetchMunicipalities() {
            this.municipalities = [];
            var json = '';
            json = JSON.parse(JSON.stringify(municipalities));
            var districtId = this.$user.district_id.toString();
            console.log(districtId);
            for (var i = 0; i < json.length; i++) {
                if (json[i].district_id === districtId) {
                    this.municipalities.push(json[i]);
                }
            }
            return this.municipalities;
        },
        planningData(){
            this.download_type = 'planning'
        },
        vaccinatedData(){
            this.download_type = 'vaccinated'
        },
        normalData(){
            this.download_type = ''
        },
        downloadData() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$fire({
                    text: this.$t('error_msg'),
                    type: "error",
                    timer: 4000,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                })
                return false;
            }

            this.$fire({
                type: 'warning',
                title: 'Are you sure?',
                text: "Do you want to download selected records !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Download it!'
            }).then((result) => {
                if (result.value) {
                    window.open('/download-data?district_id=' +this.data.district_id+
                        '&municipality_id=' + this.data.municipality_id +
                        '&ward=' + this.data.ward +
                        '&vaccination_center=' +  this.data.vaccination_center +
                        '&occupation=' + this.data.occupation +
                        '&age_from=' +  this.ageRange_min +
                        '&age_to=' +  this.ageRange_max +
                        '&from=' +  this.data.from +
                        '&to=' + this.data.to +
                        '&type=' + this.download_type)
                    this.$fire({
                        type: 'success',
                        title: 'Download Completed !',
                        text: 'If download not started, please wait for a minute!'
                    })
                }
            })

            // axios.get('/download-data?municipality_id='+ this.data.municipality_id)
            //     .then((response)=>{
            //         console.log(response.status)
            //     })
        }
    }
}
</script>
