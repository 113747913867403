<template>
    <div ref="form_public">
        <h1 class="h1">{{ $t('registration_form') }}</h1>
        <!--register for-->
        <div class="register_for register">
            <!--            <label class="control-label">Who are you registering for?</label>-->
            <input class="radio-group" type="radio" id="self" name="self" value="1" v-model="data.register_for"
            ><b>{{ $t('self_registration') }}</b>&nbsp; &nbsp; &nbsp;
            <input class="radio-group" type="radio" id="other" name="other" value="2" v-model="data.register_for"
            ><b>{{ $t('registration_for_other') }}</b>
        </div>

        <!--registration type-->
        <div class="registration_type register" hidden>
            <!--            <label class="control-label">Who are you registering for?</label>-->
            <input class="radio-group" type="radio" id="new" name="new" value="1" v-model="data.registration_type"
            ><b>New Registration</b>&nbsp; &nbsp; &nbsp;
            <input class="radio-group" type="radio" id="previous" name="previous" value="2"
                   v-model="data.registration_type"
            ><b>Previous Registration Record</b>
        </div>

        <!--name-->
        <div class="container-flex">
            <div class="form-group">
                <input type="text" id="first_name" name="first_name"
                       v-model="data.first_name" required="required"/>
                <label for="first_name" class="control-label">{{ $t('first_name') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.data.first_name.required">{{ $t('field_required') }}</div>-->
            </div>
            <div class="form-group">
                <input type="text" id="middle_name" name="middle_name"
                       v-model="data.middle_name" required="required"/>
                <label for="middle_name" class="control-label">{{ $t('middle_name') }}</label><i class="bar"></i>
            </div>
            <div class="form-group">
                <input type="text" id="last_name" name="last_name"
                       v-model="data.last_name" required="required"/>
                <label for="last_name" class="control-label">{{ $t('last_name') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.data.last_name.required">{{ $t('field_required') }}</div>-->
            </div>
        </div>

        <!--caste-->
        <div class="form-group">
            <select id="caste" name="caste" v-model="data.caste" required="required">
                <option value="6">{{ $t('dont_mention') }}</option>
                <option value="0">{{ $t('dalit') }}</option>
                <option value="1">{{ $t('janajati') }}</option>
                <option value="2">{{ $t('madhesi') }}</option>
                <option value="3">{{ $t('muslim') }}</option>
                <option value="4">{{ $t('brahmn_chettri') }}</option>
                <option value="5">{{ $t('other') }}</option>
            </select>
            <label for="caste" class="control-label">{{ $t('caste') }}</label>
            <i class="bar"></i>
            <!--            <div class="help-block text-danger" v-if="!$v.data.caste.required">{{ $t('field_required') }}</div>-->
        </div>

        <!--gender-->
        <div class="form-group">
            <select id="gender" name="gender" v-model="data.gender" required="required">
                <option value="">{{ $t('select_gender') }}</option>
                <option value="1">{{ $t('male') }}</option>
                <option value="2">{{ $t('female') }}</option>
                <option value="3">{{ $t('other') }}</option>
            </select>
            <label for="gender" class="control-label">{{ $t('gender') }}</label><i class="bar"></i>
            <!--            <div class="help-block text-danger" v-if="!$v.data.gender.required">{{ $t('field_required') }}-->
            <!--            </div>-->
        </div>

        <!--date of brith-->
        <div class="container-flex" style="margin-top: -30px;">
            <div class="form-group">
                <input type="text" id="date_of_birth" name="date_of_birth" required="required"
                       v-mask="'####-##-##'" v-model="date_of_birth">
                <label for="date_of_birth" class="control-label date-label">{{ $t('dob') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.date_of_birth.required">{{ $t('field_required') }}</div>-->
            </div>

            <div class="date-type">
                <label class="control-label">{{ $t('dat_type') }}</label>
                <input class="radio-group" type="radio" id="bs" name="bs" value="1" v-model="data.dob_type"
                       v-on:change="toggleDob(data)"> {{ $t('bs') }}&nbsp;&nbsp;
                <input class="radio-group" type="radio" id="ad" name="ad" value="2" v-model="data.dob_type"
                       v-on:change="toggleDob(data)"> {{ $t('ad') }}
            </div>

            <div class="form-group">
                <input type="number" id="Age" name="Age"
                       :value="age" required="required" min="0"/>
                <label for="Age" class="control-label">{{ $t('age') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.age.required">{{ $t('field_required') }}</div>-->
            </div>
        </div>

        <!--current address(province, district, municipality)-->
        <div class="container-flex3">
            <div class="form-group">
                <select name="province_id" id="province_id"
                        v-on:change="fetchDistricts(data)" v-model="data.province_id">
                    <option value="">{{ $t('select_province') }}</option>
                    <option v-for="data in provinces" :value="data.id">{{ data.province_name }}</option>
                </select>
                <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.data.province_id.required">{{-->
                <!--                        $t('field_required')-->
                <!--                    }}-->
                <!--                </div>-->
            </div>
            <div class="form-group">
                <select name="district_id" id="district_id"
                        v-on:change="fetchMunicipalities(data)" v-model="data.district_id">
                    <option value="">{{ $t('select_district') }}</option>
                    <option v-for="data in districts" :value="data.id">{{ data.district_name }}</option>
                </select>
                <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.data.district_id.required">{{-->
                <!--                        $t('field_required')-->
                <!--                    }}-->
                <!--                </div>-->
            </div>
            <div class="form-group">
                <select name="municipality_id" v-model="data.municipality_id"
                        id="municipality_id">
                    <option value="">{{ $t('select_municipality') }}</option>
                    <option v-for="data in municipalities" :value="data.id">{{ data.municipality_name }}
                    </option>
                </select>
                <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i class="bar"></i>
                <!--                <div class="help-block text-danger" v-if="!$v.data.municipality_id.required">{{-->
                <!--                        $t('field_required')-->
                <!--                    }}-->
                <!--                </div>-->
            </div>
        </div>

        <!--ward-->
        <div class="form-group">
            <input type="number" id="ward" name="ward"
                   v-model="data.ward" required="required" min="1" max="35"/>
            <label for="ward" class="control-label">{{ $t('ward') }}</label><i class="bar"></i>
            <!--            <div class="help-block text-danger" v-if="!$v.data.ward.required">{{ $t('field_required') }}</div>-->
            <!--            <div class="help-block text-danger" v-if="!$v.data.ward.between">Invalid ward no.</div>-->
        </div>

        <!--tole-->
        <div class="form-group">
            <input type="text" id="tole" name="tole"
                   v-model="data.tole"
                   required="required"/>
            <label for="tole" class="control-label">{{ $t('tole') }}</label><i class="bar"></i>
        </div>

        <!--email-->
        <div class="form-group">
            <input type="email" id="email_address" name="email_address"
                   v-model="data.email_address"
                   required="required"/>
            <label for="email_address" class="control-label">{{ $t('email') }}</label><i class="bar"></i>
        </div>

        <!--phone-->
        <div class="form-group">
            <input type="tel" id="phone" name="phone" v-model="data.phone"
                   required="required"/>
            <label for="phone" class="control-label">{{ $t('mobile') }}</label><i class="bar"></i>
        </div>

        <!--nationality-->
        <div class="form-group">
            <country-select id="nationality" name="nationality" v-model="data.nationality"
                            :country="country" topCountry="Nepal" countryName="true"/>
            <label class="control-label">{{ $t('nationality') }}</label><i class="bar"></i>
            <!--            <div class="help-block text-danger" v-if="!$v.data.nationality.required">{{ $t('field_required') }}</div>-->
        </div>

        <!--identity type, identity no-->
        <div class="flex-identity">
            <div class="form-group">
                <select id="identity_type" name="identity_type" v-model="data.identity_type">
                    <option value="">{{ $t('select_identity_type') }}</option>
                    <option value="Citizenship">Citizenship</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Election card">Election card</option>
                    <option value="Passport">Passport</option>
                </select>
                <label for="identity_type" class="control-label">{{ $t('identity_type') }}</label><i class="bar"></i>
            </div>

            <div class="form-group">
                <input type="text" id="identity_no" name="identity_no"
                       v-model="data.identity_no" required="required"/>
                <label for="identity_no" class="control-label">{{ data.identity_type }} ( e.g. 1234-56 / DAO Kathmandu
                    )</label><i class="bar"></i>
            </div>
        </div>

        <!--occupation-->
        <div class="container-flex1">
            <div class="form-group">
                <select id="occupation" name="occupation" v-model="data.occupation">
                    <option value="">{{ $t('select_occupation') }}</option>
                    <option value="1">Airlines/GSA(General Sales Agent)</option>
                    <option value="2">Army</option>
                    <option value="3">Bank/Finance</option>
                    <option value="4">Commercial facilities(Retail, Business, Entertainment)</option>
                    <option value="5">Daily wage worker</option>
                    <option value="6">Farmer/Food and Agriculture</option>
                    <option value="7">Government Employee</option>
                    <option value="8">Grocery Store, Kirana Shop</option>
                    <option value="9">Health Care Provider/Medical/Pharmacy</option>
                    <option value="10">Health Professional</option>
                    <option value="11">IT and Communication</option>
                    <option value="12">Journalist/Media</option>
                    <option value="13">Manufacturing</option>
                    <option value="14">NGO/INGO Employee</option>
                    <option value="15">Online Business/Delivery</option>
                    <option value="16">Police</option>
                    <option value="17">Security person(Private)</option>
                    <option value="18">Social Service</option>
                    <option value="19">Student</option>
                    <option value="20">Teacher/Professor</option>
                    <option value="21">Tourism Industry</option>
                    <option value="22">Transport Sector/Logistic</option>
                    <option value="23">Unemployed</option>
                    <option value="0">Other</option>
                    <!--                    <option v-for="data in occupation_list" :value="data.id">{{ data.name }}</option>-->
                </select>
                <label for="occupation" class="control-label">{{ $t('occupation') }}</label><i class="bar"></i>
            </div>
        </div>

        <!--medical condition-->
        <div class="medical_condition">
            <label class="medical_label" style="display: inline-block;"><b>{{ $t('select_medical_condition') }}</b>
                <!--                <div class="help-block text-danger" v-if="!$v.data.medical_condition.required" style="float: right;">*-->
                <!--                </div>-->
            </label>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="8"/><i
                    class="helper"></i>{{ $t('blood_pressure') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="1"/><i
                    class="helper"></i>{{ $t('cancer') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="2"/><i
                    class="helper"></i>{{ $t('chronic_kidney_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="3"/><i
                    class="helper"></i>{{ $t('chronic_resp_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="4"/><i
                    class="helper"></i>{{ $t('cardiovascular_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="5"/><i
                    class="helper"></i>{{ $t('diabetes') }}
                </label>
            </div>
            <!--            <div class="checkbox">-->
            <!--                <label>-->
            <!--                    <input type="checkbox" v-model="data.medical_condition" value="6"/><i class="helper"></i>Allergies-->
            <!--                </label>-->
            <!--            </div>-->
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="9"/><i
                    class="helper"></i>{{ $t('nerve_disease') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="7"/><i
                    class="helper"></i>{{ $t('pregnant') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="removeNoneCheckbox(data)" v-model="data.medical_condition"
                           value="11"/><i
                    class="helper"></i>{{ $t('tb') }}
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" @change="toggleCheckbox(data, true)" v-model="data.medical_condition"
                           value="10"/><i class="helper"></i>{{ $t('none_of_above') }}
                </label>
            </div>
        </div>

        <!--category-->
        <div class="form-group" hidden>
            <select id="category" name="category" v-model="data.category">
                <option value="">Select your category</option>
                <option value="1">Health Worker</option>
                <option value="2">Other workers working at Health Organization</option>
                <option value="3">Female Community Health Volunteer (FCHV)</option>
                <option value="4">Cleaners</option>
                <option value="5">Ambulance and Hearse Driver</option>
                <option value="6">Dead Body Management related Workers and others Security Guards</option>
                <option value="7">People at old age home and their caretakers</option>
                <option value="8">Prisoners and Security Guards at Prison</option>
                <option value="9">Workers at International Entry Point</option>
                <option value="10">Senior Citizen exceeding 75 years</option>
                <option value="11">55 to 74 years old citizen</option>
                <option value="12">40 to 54 years old citizen having chronic disease (Other person having listed chronic
                    disease)
                </option>
                <option value="13">Foreign-employed Nepali Workers and Refugee residing in Nepal</option>
                <option value="14">40 to 39 years old citizen</option>
                <option value="15">15 to 39 years old citizen</option>
                <option value="16">Others</option>
            </select>
            <label for="occupation" class="control-label">Select the category that best describes you</label><i
            class="bar"></i>
            <!--            <div class="help-block text-danger" v-if="!$v.data.category.required">{{ $t('field_required') }}</div>-->
        </div>

        <!--vaccine detail-->
        <label class="vaccine_label"><b>{{ $t('vaccination_detail') }}</b></label>

        <div class="vaccine_detail">
            <input class="radio-group" type="radio" id="first" name="first" value="1" v-model="data.vaccine_type"
            ><b>{{ $t('first') }}</b>&nbsp; &nbsp;
            <input class="radio-group" type="radio" id="second" name="second" value="2" v-model="data.vaccine_type"
            ><b>{{ $t('second') }}</b>
            <label>{{ $t('first_or_second_vaccine') }}</label>

            <div class="container-flex" v-show="isSecondVaccine">
                <div class="form-group">
                    <input type="text" id="date_of_first_vaccinated" name="date_of_first_vaccinated" required="required"
                           v-mask="'####-##-##'" v-model="data.date_of_first_vaccinated">
                    <label for="date_of_first_vaccinated" class="control-label">{{
                            $t('date_first_vaccinated')
                        }}</label><i class="bar"></i>
                    <!--                    <div class="help-block text-danger" v-if="!$v.data.date_of_first_vaccinated.required">-->
                    <!--                        {{ $t('field_required') }}-->
                    <!--                    </div>-->
                </div>
                <div class="form-group">
                    <input type="text" id="vaccinated_address" name="vaccinated_address"
                           v-model="data.vaccinated_address"
                           required="required"/>
                    <label for="vaccinated_address" class="control-label">{{ $t('vaccinated_address') }}</label><i
                    class="bar"></i>
                    <!--                    <div class="help-block text-danger" v-if="!$v.data.vaccinated_address.required">-->
                    <!--                        {{ $t('field_required') }}-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>

        <!--vaccination center(province, district, municipality, vaccination center)-->

        <div>
            <!--            <label class="vaccine_label"><b>Choose where you want to be vaccinated</b></label>-->
            <div>
                <!--                <label class="vaccine_label">Choose where you want to be vaccinated</label>-->
                <input class="radio-group" type="radio" id="yes" name="yes" value="1" v-model="vaccine_current_address"><b>
                {{ $t('vaccinated_current_address') }}</b> &nbsp; &nbsp;
                <input class="radio-group" type="radio" id="no" name="no" value="0" v-model="vaccine_current_address">
                <b>{{ $t('vaccinated_other') }}</b>
            </div>
            <div class="container-flex3" v-show="isCurrentAddress">
                <div class="form-group">
                    <select name="vac_province_id" id="vac_province_id"
                            v-on:change="fetchVacDistricts(data)" v-model="data.vac_province_id">
                        <option value="">{{ $t('select_province') }}</option>
                        <option v-for="data in vac_provinces" :value="data.id">{{ data.province_name }}</option>
                    </select>
                    <label for="province_id" class="control-label">{{ $t('province') }}</label><i class="bar"></i>
                </div>
                <div class="form-group">
                    <select name="vac_district_id" id="vac_district_id"
                            v-on:change="fetchVacMunicipalities(data)" v-model="data.vac_district_id">
                        <option value="">{{ $t('select_district') }}</option>
                        <option v-for="data in vac_districts" :value="data.id">{{ data.district_name }}</option>
                    </select>
                    <label for="district_id" class="control-label">{{ $t('district') }}</label><i class="bar"></i>
                </div>
                <div class="form-group">
                    <select name="vac_municipality_id" v-model="data.vac_municipality_id"
                            id="vac_municipality_id" v-on:change="fetchVaccinationCenters(data)">
                        <option value="">{{ $t('select_municipality') }}</option>
                        <option v-for="data in vac_municipalities" :value="data.id">{{ data.municipality_name }}
                        </option>
                    </select>
                    <label for="municipality_id" class="control-label">{{ $t('municipality') }}</label><i
                    class="bar"></i>
                </div>
                <div class="form-group">
                    <select name="vaccination_center" v-model="data.vaccination_center"
                            id="vaccination_center">
                        <option value="0">Default</option>
                        <option v-for="data in vaccinations" :value="data.id">{{ data.vaccination_center }}
                        </option>
                    </select>
                    <label for="vaccination_center" class="control-label">{{ $t('vaccination_center') }}</label><i
                    class="bar"></i>
                </div>
            </div>
            <div v-show="isCurrentAddress">
                <label class="vaccine_label">{{ $t('choose_vaccine_center') }}</label>
            </div>
        </div>
        <hr>
        <div class="checkbox">
            <label class="text-danger">
                <input type="checkbox" id="detail" v-model='terms'/><i
                class="helper"></i>{{ $t('mentioned_detail_correct') }}
            </label>
        </div>

        <div class="button-container">
            <button type="button" class="button btn-block" id="button" :disabled="isDisabled"
                    v-on:click="showConfirmationModal(data)"><span>{{ $t('submit') }}</span>
            </button>
        </div>
    </div>

</template>
<script>
import axios from "axios";
import {EventBus} from "../event-bus";

export default {
    data() {
        return {
            'id': '',
            data: {}
        }
    },
    // events: {
    //     'id': function (data) {
    //         this.$broadcast('id', data);
    //     }
    // },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            EventBus.$once('id-selected', reg_no => {
                this.id = reg_no
            });
            console.log(this.id)
            axios.get('/show?id=' + this.id)
                .then((response) => {
                    this.data = response.data
                    console.log(this.data)
                });
        }
    },
}
</script>
