<template>
    <div class="card">
        <div class="card-header">
            Update
        </div>
        <div class="card-body">
            <div class="form-group" :class="{ 'has-error': $v.data.administrator_name.$error }">
                <input type="text" name="administrator_name" required="required" v-model.trim="data.administrator_name">
                <label class="control-label">Administrator Name</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.data.administrator_name.required">Field is required.</div>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.data.position.$error }">
                <input type="text" name="position" required="required" v-model="data.position">
                <label class="control-label">Position</label><i class="bar"></i>
                <div class="help-block text-danger" v-if="!$v.data.position.required">Field is required.</div>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.data.signature_image.$error }">
                <label class="medical_label" style="display: inline-block;"><b>Signature Image</b></label>

                <div class="custom-file text-left">
                    <input type="file" name="signature_image" class="custom-file-input" v-on:change="onFileChange"
                           id="signature_image" accept="image/x-png,image/jpeg">
                    <label class="custom-file-label" for="signature_image">Choose Image</label>
                </div>
                <div class="help-block text-danger" v-if="!$v.data.signature_image.required">Field is required.</div>

            </div>
        </div>
        <button class="btn btn-primary btn-sm btn-block"
                @click.prevent="updateSignature">
            Save
        </button>
    </div>
</template>
<script>
import axios from 'axios'
import {required} from "vuelidate/lib/validators";

export default {
    data() {
        return {
            data: {}
        }
    },
    validations: {
        data: {
            'administrator_name': {
                required
            },
            'position': {
                required
            },
            'signature_image': {
                required
            },
        }
    },
    methods: {
        onFileChange(e) {
            this.data.signature_image = e.target.files[0];
        },
        updateSignature() {
            this.$v.$touch()
            console.log(this.data)

            const datas = {
                'administrator_name': this.data.administrator_name,
                'position': this.data.position,
                'signature_image': this.data.signature_image,
            }

            axios.post('/signature-store', datas)
                .then((response) => {
                    console.log(response)
                })

            // if (this.$v.$invalid) {
            //     this.$fire({
            //         text: this.$t('error_msg'),
            //         type: "error",
            //         timer: 4000,
            //         toast: true,
            //         position: 'top-end',
            //         showConfirmButton: false,
            //     })
            //     return false;
            // }

        }
    }
}
</script>
