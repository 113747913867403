<template>
<div class="row">


<div class="container">
    <div class="row justify-content-between">

        <div class="form-group col-md-4 pl-0"   >
                    <v-date-picker v-model="range" is-range :model-config="modelConfig" :masks="masks" @input="isRange()">
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="d-flex justify-center items-center mt-2">
                            <input
                                :value="inputValue.start"
                                v-on="inputEvents.start"
                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                            />
                            <div class="pr-4"></div>

                            <input
                                :value="inputValue.end"
                                v-on="inputEvents.end"
                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                            />
                            </div>
                        </template>
                    </v-date-picker>
                    <label  class="control-label date-label pr-3 ">Filter Records by Date Range</label>
        </div>
        <div class="form-group col-md-4  pl-0"   >
            <v-date-picker v-model="range.report_date" :attributes='attrs'  :model-config="modelConfig" :masks="masks" @input="isDate()">
                    <template v-slot="{ inputValue, inputEvents }">
                    <input
                        class="has-value"
                        v-mask="'####-##-##'"
                        :value="inputValue"
                        v-on="inputEvents"
                    />
                    </template>
                </v-date-picker>
            

                <label  class="control-label date-label pr-3">Filter Records by Specific Date</label><i class="bar"></i>
        </div>
    </div>
            
</div>

<table class="table table-hover table-bordered text-center">
        <thead>
        <tr>
            <th  class="w-25"> <h5> <span v-if="range.report_date == '' && !range.start">Today</span>Registered <span v-if="range.report_date == '' && !range.start" class="right badge badge-danger table-tag">New</span></h5>  </th>
            <th class="w-25" colspan="2">{{data.new_record}}</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <th>Male</th>
                <th>Female</th>
                <th>Other</th>
                
            </tr>
            <tr>
                <th>{{data.new_record_male}}</th>
                <th>{{data.new_record_female}}</th>
                <th>{{data.new_record_other}}</th>
               
            </tr>
        </tbody>
    </table>
    <table class="table table-hover table-bordered text-center">
        <thead>
        <tr>
            <th  colspan="10"> <h5> <span v-if="range.report_date == '' && !range.start">Today</span> Vaccinated</h5>  </th>
        </tr>
        </thead>
        <tbody>
           
            <tr>
                <th rowspan="2" > <h5> <span>Vaccine</span> Name</h5>  </th>
                <th colspan="3">Male</th>
                <th colspan="3">Female</th>
                <th colspan="3">Other</th>
            </tr>
            <tr>
                <th>First </th>
                <th>Second</th>
                <th>Additional</th>
                <th>First </th>
                <th>Second</th>
                <th>Additional</th>
                <th>First </th>
                <th>Second</th>
                <th>Additional</th>
            </tr>
            

            
            <tr  v-for="(record,index) in data.vaccine_data"  :key="record.id">
                <th>{{index }} </th>
                <th>{{ record.first_dose_male[0] }} </th>
                <th>{{ record.second_dose_male[0]}} </th>
                <th>{{ record.third_dose_male[0] }} </th>
                <th>{{ record.first_dose_female[0] }} </th>
                <th>{{ record.second_dose_female[0]}} </th>
                <th>{{ record.third_dose_female[0] }} </th>
                
                <th>{{ record.first_dose_other[0] }} </th>
                <th>{{ record.second_dose_other[0]}} </th>
                <th>{{ record.third_dose_other[0] }} </th>
            </tr>
            <!-- <tr  >
                <th> Total</th>
                <th>{{ record.reduce((currentTotal,item) =>{ return parseInt(item.first_dose_male[0]) + currentTotal },0)  }} </th>
                <th>{{ record.reduce((currentTotal,item) =>{ return parseInt(item.second_dose_male[0]) + currentTotal },0)  }} </th>
                <th>{{ record.reduce((currentTotal,item) =>{ return parseInt(item.third_dose_male[0]) + currentTotal },0)  }} </th>
            </tr> -->
            
        </tbody>
    </table>
    

    <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"></loading>
</div>
    
</template>
<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components:{
        Loading
    },
    data(){
        return{
            
            data:{
                new_records:'',
                new_records_female:'',
                new_records_male:'',
                new_record_other:'',
                vaccines:'',
                today_vaccinated:'',
                today_vaccinated_male:'',
                today_vaccinated_female:'',
                today_vaccinated_other:''
            },
            isLoading: false,
            fullPage: true,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD'
            },
            masks: {
            input: 'YYYY-MM-DD'
            },
            attrs: [
                {
                highlight: true,
                dates: new Date(),
                },
            ],
            range: {
                start: '',
                end: '',
                report_date:'',
            },
            is_range:false,
            records:[]

            
        }
    },
    methods:{
        isRange(){
            this.range.report_date = ''
            this.loadData();
        },
        isDate(){
            this.range = {
                start: '',
                end: '',
                report_date: this.range.report_date,
            }
            this.loadData();
        },
        loadData(){
            this.isLoading = true
            if(this.range.report_date || this.range.start){
                axios.get('/vaccination?date='+this.range.report_date + '&start='+ this.range.start + '&end='+ this.range.end).then(({data})=>{
                this.data  = data
                this.isLoading = false
                })
            }else{
                this.isLoading = true
                axios.get('/vaccination').then(({data})=>{
                this.data  = data

                this.isLoading = false

                })
            }
            },
            // calcData(){
            // const uniqueKeys = this.data.vaccines.map(item => item.name);
            // const zipObjectArray = 
            // [
            // {
            // "key": "female",
            // "value": this.data.today_vaccinated_female
            // },
            // {
            // "key": "male",
            // "value": this.data.today_vaccinated_male
            // },
            // {
            // "key": "other",
            // "value": this.data.today_vaccinated_other
            // }
            // ]

            // const zip = (identifier = [], zipObj = []) => {
            // return identifier.map(id => {
            // const obj = {};
            // zipObj.forEach(zip => {
            //     const matchedData = zip.value.find(item => item.name == id);
            //     obj["name"] = id;
            //     obj[zip.key] = matchedData && matchedData.total ? matchedData.total : 0 ;
            // })
            // return obj;
            // })
            // }

            // this.records =  zip(uniqueKeys,zipObjectArray);
            // },
            

    },
    
    created(){
        this.loadData();
        

    }
}
</script>
<style scoped>
.report{
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}
</style>