<template>
    <div>
        <table class="table table-striped" id="detailTable">
            <thead>
            <tr class="text-center">
                <td colspan="2"><h4>{{ $t('personal_detail') }}</h4></td>
            </tr>
            </thead>
            <tbody>

            <tr>
                <td class="w-35">{{ $t('name') }}</td>
                <td>
                    {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                </td>
            </tr>
            <tr>
                <td>{{ $t('caste') }}</td>
                <td>{{ caste }}</td>
            </tr>
            <tr>
                <td>{{ $t('nationality') }}</td>
                <td>{{ item.nationality }}</td>
            </tr>
            <tr>
                <td>{{ $t('gender') }}</td>
                <td>{{ gender }}</td>
            </tr>
            <tr>
                <td>{{ $t('dob') }} ({{ dob_type }})</td>
                <td>{{ date_of_birth }}</td>
            </tr>
            <tr>
                <td>{{ $t('age') }}</td>
                <td>{{ age }}</td>
            </tr>
            <tr>
                <td>{{ $t('mobile') }}</td>
                <td>{{ item.phone }}</td>
            </tr>
            <tr>
                <td>{{ $t('occupation') }}</td>
                <td>{{ occupation }}</td>
            </tr>
            <tr>
                <td>{{ $t('identity_type') }}</td>
                <td>{{ item.identity_type }}</td>
            </tr>
            <tr>
                <td>{{ $t('identification_no') }}</td>
                <td>{{ item.identity_no }}</td>
            </tr>
            <tr>
                <td>{{ $t('current_address') }}</td>
                <td>
                    {{$t('privince') }} {{ province_name }} <br>
                    {{$t('district') }} {{ district_name }} <br>
                    {{$t('municipality') }} {{ municipality_name }} <br>
                    {{$t('ward') }} {{ item.ward }} <br>
                    {{$t('tole') }} {{ item.tole }}
                </td>
            </tr>
            <tr>
                <td>{{ $t('medical_condition') }}</td>
                <td>{{ medical_condition }}</td>
            </tr>
            <tr>
                <td>{{ $t('vaccination_center') }}</td>
                <td>{{ vaccination_center_name }}</td>
            </tr>
            </tbody>
        </table>
        <div>
            <button class="btn btn-edit"
                    @click.prevent="editData()">
                {{ $t('edit') }}
            </button>
            <button class="btn btn-save float-right"
                    v-on:click="submitData(item)" :disabled="disableBtn">
                {{ $t('save') }}
            </button>
        </div>
    </div>
</template>
<script type="text/javascript">
import axios from "axios";
import provinces from '../json/provinces.json'
import municipalities from '../json/municipalities.json'
import DataConverter from "ad-bs-converter";

export default {
    props: {
        item: Object,
        date_of_birth: String,
        vaccination_center_name: String,
        age: String,
        $t: Function,
        confirmed: Boolean
    },
    data() {
        return {
            caste: '',
            gender: '',
            occupation: '',
            category: '',
            province_name: '',
            district_name: '',
            municipality_name: '',
            medical_condition: '',
            register_for: '',
            vaccine_type: '',
            vaccineSecond: false,
            disableBtn: false
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            // register for
            if (this.item.register_for === '1')
                this.register_for = this.$t('self')
            if (this.item.register_for === '3')
                this.register_for = this.$t('registration_for_organization_member')
            else
                this.register_for = this.$t('other')

            // date of birth type
            if (this.item.dob_type === '1')
                this.dob_type = this.$t('bs')
            else if (this.item.dob_type === '2')
                this.dob_type = this.$t('ad')

            // caste
            switch (this.item.caste) {
                case "0":
                    this.caste = this.$t('dalit');
                    break;
                case "1":
                    this.caste = this.$t('janajati');
                    break;
                case "2":
                    this.caste = this.$t('madhesi');
                    break;
                case "3":
                    this.caste = this.$t('muslim');
                    break;
                case "4":
                    this.caste = this.$t('brahmn_chettri');
                    break;
                case "5":
                    this.caste = this.$t('other');
                    break;
                case "6":
                    this.caste = this.$t('dont_mention');
                    break;
            }

            // gender
            if (this.item.gender === '1')
                this.gender = this.$t('male')
            else if (this.item.gender === '2')
                this.gender = this.$t('female')
            else
                this.gender = this.$t('third_gender')

            switch (this.item.occupation) {
                case "0":
                    this.occupation = this.$t('other');
                    break;
                case "1":
                    this.occupation = this.$t('airlines');
                    break;
                case "2":
                    this.occupation = this.$t('army');
                    break;
                case "3":
                    this.occupation = this.$t('bank');
                    break;
                case "4":
                    this.occupation = this.$t('commercial_facilities');
                    break;
                case "5":
                    this.occupation = this.$t('daily_wage_worker');
                    break;
                case "6":
                    this.occupation = this.$t('farmer');
                    break;
                case "7":
                    this.occupation = this.$t('government_employee');
                    break;
                case "8":
                    this.occupation = this.$t('grocery');
                    break;
                case "9":
                    this.occupation = this.$t('health_care');
                    break;
                case "10":
                    this.occupation = this.$t('health_professional');
                    break;
                case "11":
                    this.occupation = this.$t('it_and_communication');
                    break;
                case "12":
                    this.occupation = this.$t('journalist_media');
                    break;
                case "13":
                    this.occupation = this.$t('manufacturing');
                    break;
                case "14":
                    this.occupation = this.$t('ngo_ingo');
                    break;
                case "15":
                    this.occupation = this.$t('online_business');
                    break;
                case "16":
                    this.occupation = this.$t('police'); // police
                    break;
                case "17":
                    this.occupation = this.$t('Security_person');
                    break;
                case "18":
                    this.occupation = this.$t('social_service');
                    break;
                case "19":
                    this.occupation = this.$t('student');
                    break;
                case "20":
                    this.occupation = this.$t('teacher_professor');
                    break;
                case "21":
                    this.occupation = this.$t('tourist');
                    break;
                case "22":
                    this.occupation = this.$t('transport_sector');
                    break;
                case "23":
                    this.occupation = this.$t('unemployed');
                    break;
                case "31":
                    this.occupation = this.$t('diplomat');
                    break;
                case "32":
                    this.occupation = this.$t('veterinarians');
                    break;
                case "33":
                    this.occupation = this.$t('foreign_employment');
                    break;
            }

            // province
            var json = JSON.parse(JSON.stringify(provinces));
            for (var i = 0; i < json.length; i++) {
                if (json[i].id === this.item.province_id) {
                    this.province_name = json[i].province_name
                }
            }

            // district municpality
            var jsonMuni = JSON.parse(JSON.stringify(municipalities));
            for (var j = 0; j < jsonMuni.length; j++) {
                if (jsonMuni[j].id === this.item.municipality_id) {
                    this.district_name = jsonMuni[j].district_name
                    this.municipality_name = jsonMuni[j].municipality_name
                }
            }

            // medical condition
            for (var k = 0; k < this.item.medical_condition.length; k++) {
                switch (this.item.medical_condition[k]) {
                    case "1":
                        this.medical_condition = this.medical_condition + this.$t('cancer');
                        break;
                    case "2":
                        this.medical_condition = this.medical_condition + this.$t('chronic_kidney_disease');
                        break;
                    case "3":
                        this.medical_condition = this.medical_condition + this.$t('chronic_resp_disease');
                        break;
                    case "4":
                        this.medical_condition = this.medical_condition + this.$t('cardiovascular_disease');
                        break;
                    case "5":
                        this.medical_condition = this.medical_condition + this.$t('diabetes');
                        break;
                    case "7":
                        this.medical_condition = this.medical_condition + this.$t('pregnant');
                        break;
                    case "8":
                        this.medical_condition = this.medical_condition + this.$t('blood_pressure');
                        break;
                    case "9":
                        this.medical_condition = this.medical_condition + this.$t('nerve_disease');
                        break;
                    case "10":
                        this.medical_condition = this.medical_condition + this.$t('none_of_above');
                        break;
                    case "11":
                        this.medical_condition = this.medical_condition + this.$t('tb');
                        break;
                    case "21":
                        this.medical_condition = this.medical_condition + this.$t('normal_health');
                        break;
                    case "22":
                        this.medical_condition = this.medical_condition + this.$t('mental_disease');
                        break;
                    case "23":
                        this.medical_condition = this.medical_condition + this.$t('covid');
                        break;
                    case "31":
                        this.medical_condition = this.medical_condition + this.$t('hiv_aids');
                        break;
                }
            }
        },
        editData() {
            Event.$emit('editing');
        },
        submitData(data) {
            this.disableBtn = true;
            data.date_of_birth = this.date_of_birth
            data.age = this.age
            data.vaccination_center_id = data.vaccination_center
            axios.post('/api/organization-data', data)
                .then((response) => {
                    if (response.data.message === 'Data Successfully Sync') {
                        window.location = '/report?id='+response.data.encrypt_id
                    } else {
                        this.$fire({
                            text: response.data.message.errorInfo,
                            type: "error",
                            timer: 4000,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                        }).then(r => {
                            console.log(r.value);
                        });
                    this.disableBtn = false;

                    }
                });
            this.disableBtn = true;

        },
    }
}
</script>
